import React, { createContext, useState } from 'react';
import { useContext } from 'react';
import { DeviceType, Manufacturer } from '../api/api-device-metadata';
import { ReactChildren } from '../common/types/helper-types';
import { fetchManufacturers, fetchModelsByManufacturerId } from '../features/site/devices/site-device-form-helpers';

interface State {
  manufacturers: Record<DeviceType, Manufacturer[]>;
  modelsByManufacturerId: Record<DeviceType, Record<string, Manufacturer[]>>; // Mapped by device type, then model ID
  isManufacturerLoaded: boolean;
  isFetchingNewModels: boolean;
}

type ContextProps = {
  fetchManufacturersList: (deviceType: DeviceType) => void;
  fetchModels: (manufacturerId: string, deviceType: DeviceType) => void;
} & State;

const INITIAL_STATE = {
  manufacturers: {
    INVERTER: [],
    SOLAR_MODULE: [],
    BATTERY_PACK: [],
    METER: [],
    EV_CHARGER: [],
  },
  modelsByManufacturerId: {
    INVERTER: {},
    SOLAR_MODULE: {},
    BATTERY_PACK: {},
    METER: {},
    EV_CHARGER: {},
  },
  isManufacturerLoaded: false,
  isFetchingNewModels: false,
};

export const SiteDeviceContext = createContext({});

/* Passing the preconfigured hook to components*/
export const useSiteDeviceContext = () => useContext(SiteDeviceContext) as ContextProps;

const SiteDeviceContextProvider = ({ children }: ReactChildren) => {
  const [state, setState] = useState<State>(INITIAL_STATE);

  const fetchManufacturersList = async (deviceType: DeviceType) => {
    if (!state.manufacturers?.[deviceType].length) {
      const manufacturerList = await fetchManufacturers(deviceType);
      setState((prevState) => ({
        ...prevState,
        manufacturers: { ...prevState.manufacturers, [deviceType]: manufacturerList },
        isManufacturerLoaded: true,
      }));
    }
  };

  const fetchModels = async (manufacturerId: string, deviceType: DeviceType) => {
    if (!state.modelsByManufacturerId?.[deviceType]?.[manufacturerId]?.length) {
      setState((prevState) => ({
        ...prevState,
        isFetchingNewModels: true,
      }));
      const modelList = await fetchModelsByManufacturerId(manufacturerId, deviceType);
      setState((prevState) => ({
        ...prevState,
        modelsByManufacturerId: {
          ...prevState.modelsByManufacturerId,
          [deviceType]: {
            ...prevState.modelsByManufacturerId[deviceType],
            [manufacturerId.toString()]: modelList,
          },
        },
        isFetchingNewModels: false,
      }));
    }
  };

  const value = {
    ...state,
    fetchManufacturersList,
    fetchModels,
  };
  return <SiteDeviceContext.Provider value={value}>{children}</SiteDeviceContext.Provider>;
};

export default SiteDeviceContextProvider;
