import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { formatMonth } from '../../../common/date-formatting';

export default function NestedTieredTariffTable({ rates }) {
  return rates.map((rate, rateIndex) => {
    return (
      <div key={`rate-${rateIndex}`} style={{ width: '100%' }}>
        <div>
          <FontAwesomeIcon icon={faCalendarAlt} /> &nbsp;
          {formatMonth(rate.start_month)} ({rate.start_month}) - {formatMonth(rate.end_month)} ({rate.end_month})
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Max. Energy (kWh)</th>
                <th>Import</th>
                <th>Export</th>
              </tr>
            </thead>
            <tbody>
              {rate.tiers.map((tier, tierIndex) => {
                return (
                  <tr key={`rate-tier-${tierIndex}`}>
                    <td>{tier.tier_max_energy_in_kwh}</td>
                    <td>{tier.import_rate}</td>
                    <td>{tier.export_rate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  });
}
