import React from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { createCustomCellularIcon, createCustomWifiIcon } from '../styles/custom-icons';

interface MeterCommsStatusProps {
  signalQualityDbm: number;
  commsType: string;
  cellMode: CellMode;
  lastHeardInMinutes: number | null;
}

export type CellMode = '3G' | '4G';

const SIGNAL_COLOR = '#000000';

const SIGNAL_LEVEL_TO_VALUE: Record<number, string> = {
  4: 'Excellent',
  3: 'Good',
  2: 'Low',
  1: 'Poor',
  0: 'Offline',
};

const WIFI_SIGNAL_RANGES = [-40, -55, -70];
const CELL_SIGNAL_RANGES: Record<string, number[]> = {
  '3G': [-89, -95, -101],
  '4G': [-65, -75, -95],
};

// Looks like cell mode should have been '3g or '4g'
const signalQualityToSignalLevel = (
  signalQualityDbm: number,
  isWifi: boolean,
  cellMode: CellMode,
  lastHeardInMinutes: number | null
): number => {
  const hasTimedOut = !lastHeardInMinutes || lastHeardInMinutes > 5;
  const hasInvalidConnectionType = !isWifi && !cellMode;
  const hasNoSignal = signalQualityDbm !== 0 && !signalQualityDbm;
  if (hasTimedOut || hasInvalidConnectionType || hasNoSignal) return 0;

  const rangeArray = isWifi ? WIFI_SIGNAL_RANGES : CELL_SIGNAL_RANGES[cellMode || '4G'];
  for (let index = 0; index < rangeArray.length; index++) {
    const currentValue = rangeArray[index];
    const signalLevel = rangeArray.length - index;

    // if last index reached, it means lowest signal
    if (index === rangeArray.length - 1) return signalLevel;

    // if first index and signal quality greater than first value means best signal
    if (index === 0 && signalQualityDbm > currentValue) return signalLevel + 1;

    // otherwise check if its between next and current value
    const nextValue = rangeArray[index + 1];
    if (signalQualityDbm < currentValue && signalQualityDbm >= nextValue) return signalLevel;
  }

  return 0;
};

const MeterCommsStatus = ({ signalQualityDbm, commsType, cellMode, lastHeardInMinutes }: MeterCommsStatusProps) => {
  const isWifi = commsType === 'wifi';
  const signalLevel = signalQualityToSignalLevel(signalQualityDbm, isWifi, cellMode, lastHeardInMinutes);
  const signalQualityValue = SIGNAL_LEVEL_TO_VALUE[signalLevel];
  const SignalIcon = isWifi
    ? createCustomWifiIcon(signalLevel, SIGNAL_COLOR)
    : createCustomCellularIcon(signalLevel, SIGNAL_COLOR);

  return (
    <td title={signalQualityValue} style={{ paddingTop: '5px' }}>
      <Flex direction="column" align="center" justify="center">
        <Flex align="center" justify="center">
          <SignalIcon w={10} h={10} mr={1} />
          {!isWifi && (
            <Text fontWeight={600} color={SIGNAL_COLOR}>
              {cellMode}
            </Text>
          )}
        </Flex>
        <Text fontWeight={600} color={SIGNAL_COLOR}>{`${signalQualityValue} (${signalQualityDbm || 'N/A'} dBm)`}</Text>
      </Flex>
    </td>
  );
};

export default MeterCommsStatus;
