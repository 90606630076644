import React, { useEffect } from 'react';
interface IAutoCompleteProps {
  address?: string;
  placeholder?: string;
  onPlaceChanged?: (val) => void;
}

const Autocomplete = (props: IAutoCompleteProps) => {
  const autocompleteInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (props.address) {
      autocompleteInput.current.value = props.address;
    }
    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput.current, {
      types: [],
      componentRestrictions: { country: ['AU', 'US'] },
    });
    autocomplete.addListener('place_changed', () => props.onPlaceChanged(autocomplete.getPlace()));
  }, [props]);

  return <input ref={autocompleteInput} id="autocomplete" placeholder={props.placeholder} type="text" />;
};

export default Autocomplete;
