import { Flex, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { Badge as BootstrapBadge } from 'react-bootstrap';

const STATUS_TO_BADGE_CONFIG = {
  CONFIRMED: { label: 'Confirmed', variant: 'success', color: 'white' },
  FORCE_CHANGE_PASSWORD: { label: 'Force Change Password', variant: 'warning', color: 'black' },
  RESET_REQUIRED: { label: 'Reset Required', variant: 'warning', color: 'black' },
  UNKNOWN: { label: 'Not in Cognito', variant: 'danger', color: 'white' },
  UNCONFIRMED: { label: 'Not Confirmed', variant: 'info', color: 'white' },
  EXTERNAL_PROVIDER: { label: 'External Provider', variant: 'dark', color: 'white' },
  ARCHIVED: { label: 'Archived', variant: 'secondary', color: 'white' },
};

export default function NestedUserTable({ row, sections, expandData, isFetchingExpandData }) {
  function Badge({ statusType }) {
    const status = expandData[statusType];
    const badgeConfig = STATUS_TO_BADGE_CONFIG[status in STATUS_TO_BADGE_CONFIG ? status : 'UNKNOWN'];
    const { variant, label, color } = badgeConfig;

    if (isFetchingExpandData) return <Skeleton height="16px" width="64px" rounded={20} ml={2} />;

    return (
      <BootstrapBadge pill variant={variant} style={{ color, height: '16px', marginLeft: '4px' }}>
        {label}
      </BootstrapBadge>
    );
  }

  return (
    <div className="row tab-data-more p-5 m-0">
      <div className="col-md-4 border-right text-left">
        <h3>Other Info</h3>
        <div style={{ marginBottom: '1rem' }}>
          <Flex align="center">
            Cortex cognito status: <Badge style={{ width: '50px', height: '10px' }} statusType={'user_status'} />
          </Flex>
          <Flex align="center">
            DERMS cognito status: <Badge style={{ width: '50px', height: '10px' }} statusType={'derms_user_status'} />
          </Flex>
        </div>
        <ul>
          {sections.map((item, i) => {
            return (
              <li key={`user-section-${i}`}>
                {item.label}:<span> {row[item.value]}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
