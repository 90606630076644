import { Formik } from 'formik';
import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import LoaderButton from '../../components/LoaderButton';
import { useAuthContext } from '../../context/AuthContext';
import { displayAPIErrorMessage } from '../../common/utils-helper';

/* Basic Information Form validation Schema */
const basicSchema = yup.object().shape({
  email: yup.string().required('*Email is required'),
  password: yup.string().required('*Password is required'),
});

const INITIAL_STATE = {
  email: '',
  password: '',
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { login } = useAuthContext();

  const handleSubmit = async (values) => {
    try {
      await login(values.email, values.password);
      toast.success('👍 You have logged in successfully', {
        autoClose: 5000,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row login-row">
        <div className="login-bg col-xs-4 col-sm-7 col-md-6 col-lg-12 ">
          <div className="login-wrapper col-lg-3">
            <div className="login-logo text-center logo-img-pos"></div>
            <div className="login-content">
              <h3 className="text-center">Clipsal Solar Portal Login</h3>
              <p className="text-center">Welcome to the Clipsal Solar Distributed Energy Resource Management System.</p>
              <Formik
                validateOnChange
                enableReinitialize
                validationSchema={basicSchema}
                onSubmit={async (values) => {
                  setLoading(true);
                  await handleSubmit(values);
                }}
                initialValues={INITIAL_STATE}
              >
                {({ handleSubmit, values, touched, errors, handleBlur, handleChange }) => {
                  return (
                    <Form noValidate onSubmit={handleSubmit} id="login-form">
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="email">
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            isInvalid={touched.email && !!errors.email}
                            isValid={touched.email && !errors.email}
                            name="email"
                            disabled={loading}
                            placeholder="Enter Username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            isInvalid={touched.password && !!errors.password}
                            isValid={touched.password && !errors.password}
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            disabled={loading}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <div className="form-group mt-3">
                        <LoaderButton
                          className="btn btn-lg btn-block btn-big btn-blue"
                          block
                          disabled={loading && !errors.email && !errors.password}
                          type="submit"
                          isLoading={loading && !errors.email && !errors.password}
                          text="Login"
                          loadingText="Logging in…"
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div className="text-center">
                Forgot password?{' '}
                <a href="#/forgotPassword">
                  <b>Click here</b>
                </a>{' '}
                to reset password.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
