import 'clipsal-cortex-utils/src/build/polyfills';
import './index.css';
import './assets/sass/main.scss';
import { Amplify, API } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './App';
import AuthContextProvider from './context/AuthContext';
import * as Sentry from '@sentry/react';
import { Center, Heading } from '@chakra-ui/react';

// @TODO: remove unnecessary duplicates, establish standard for structure
const ENDPOINTS = [
  'assignments',
  'user_roles',
  'circuits',
  'appliances',
  'sites',
  'site',
  'users',
  'user',
  'devices',
  'associate-device',
  'associate-user',
  'tariffs',
  'site-device',
  'site-devices',
  'tariffs',
  'tariff_sources',
  'aggregation_period',
  'seasons',
  'tiers',
  'rates',
  'rate',
  'tariff_rates',
  'tariff',
  'change_season_tariff',
  'get_more_details',
  'site_distributor',
  'data_correction',
  'investments',
  'save-discounts',
  'building_types',
  'retailers',
  'discounts',
  'bill_view',
  'bills',
  'bill',
  'site_tariffs',
  'distributors',
  'invite_to_pulse',
  'user_cognito_status',
  'inverter_sub_arrays',
  'solar_modules',
  'sub_array',
  'sub_arrays',
  'usage',
  'costs',
  'savings',
  'battery_data',
  'ww_meter_categories',
  'ww_meter_configuration',
  'ww_meter_data',
  'ww_logs',
  'long_energy',
  'short_energy',
  'refresh_circuits',
  'refresh_images',
  'articles',
  'article',
  'publisher',
  'publishers',
  'tenants',
  'defaulttariffs',
  'defaulttariff',
  'manufacturers',
];

/**
 * Configures the AWS Amplify end-point setup for this repo.
 *
 * Note that we need to separately configure the `API` class, as for some reason in this monorepo architecture it does
 * not correctly initialize itself.
 *
 * see: https://github.com/aws-amplify/amplify-js/issues/5756
 */
function setupAPI() {
  const apiConfig = {
    endpoints: ENDPOINTS.map((endPointName) => ({
      name: endPointName,
      endpoint: import.meta.env['VITE_API_URL'],
      region: 'ap-southeast-2',
    })),
  };

  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: 'ap-southeast-2',
      userPoolId: import.meta.env['VITE_USER_POOL_ID'],
      identityPoolId: import.meta.env['VITE_IDENTITY_POOL_ID'],
      userPoolWebClientId: import.meta.env['VITE_APP_CLIENT_ID'],
    },
    API: apiConfig,
  });

  API.configure(apiConfig);
}

setupAPI();

const ENDPOINT = import.meta.env['VITE_PROD_ENDPOINT'] as string;
// Only send events to sentry to in staging and production environments
if (import.meta.env['MODE'] !== 'development') setupSentry();

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <Center h={'100vh'} w={'100%'}>
          <Heading textAlign={'center'}>
            An error occurred. Please refresh the page to continue. If this error persists, try closing and re-opening
            the app.
          </Heading>
        </Center>
      }
    >
      <HashRouter>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </HashRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

/**
 * Configures the Sentry setup for this repo.
 */
function setupSentry() {
  Sentry.init({
    release: 'derms@' + import.meta.env.VITE_SENTRY_RELEASE,
    dsn: import.meta.env['VITE_SENTRY_DSN'] as string,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: [
          ENDPOINT,
          'http://derms-web-app-staging.s3-website-ap-southeast-2.amazonaws.com/',
          'http://derms-staging.clipsalcortex.com',
          'http://derms.clipsalcortex.com',
        ],
        beforeNavigate: (context) => {
          return {
            ...context,
            name: window.location.pathname.replace(/\d+/g, ':id').replace(/[a-f0-9]{32}/g, '<hash>'),
          };
        },
      }),
    ],
    environment: import.meta.env['MODE'],

    // Setting sample rate to 5% for finer control
    tracesSampleRate: 0.05,
  });
}
