import React, { useEffect } from 'react';
import Loader from 'react-loader';
import { Switch, useHistory } from 'react-router-dom';
import AppliedRoute from './components/AppliedRoute';
import PageNotFound from './components/PageNotFound';
import { useAuthContext } from './context/AuthContext';
import Site from './features/site/Site';
import Sites from './features/site/Sites';
import Login from './features/auth/Login';
import ChangePassword from './features/auth/ChangePassword';
import CreateAccount from './features/auth/CreateAccount';
import ForgotPassword from './features/auth/ForgotPassword';
import ResetPassword from './features/auth/ResetPassword';
import CreateArticle from './features/administration/articles/CreateArticle';
import EditArticle from './features/administration/articles/EditArticle';
import DataCorrection from './features/administration/data-correction/DataCorrection';
import CreateDefaultTariff from './features/administration/default-tariff/CreateDefaultTariff';
import DefaultTariff from './features/administration/default-tariff/DefaultTariffs';
import EditDefaultTariff from './features/administration/default-tariff/EditDefaultTariff';
import MeterConfiguration from './features/administration/meter-configuration/MeterConfiguration';
import Tariff from './features/tariff/Tariff';
import Tariffs from './features/tariff/Tariffs';
import EditorialContentTable from './features/administration/articles/Articles';
import Tenants from './features/administration/tenant-configuration/Tenants';
import TenantForm from './features/administration/tenant-configuration/TenantForm';

export type CommonRouteProps = {
  isAuthenticated: boolean;
  userHasAuthenticated: (val: boolean) => void;
};

interface Props {
  childProps: CommonRouteProps;
}

const Routes = ({ childProps }: Props) => {
  const { isAuthenticated, isLoadingAuth } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    // Redirect users to login if they try to navigate to any route which isn't login or account creation, while not logged in.
    if (!isAuthenticated && !['/login', '/create_account'].includes(history.location.pathname)) {
      history.push('/login');
    }
  }, [isAuthenticated]);

  const Routes = () => {
    return (
      <Switch>
        {/* Sites routes */}
        <AppliedRoute path="/" exact component={Sites} props={childProps} />
        <AppliedRoute path="/sites" exact component={Sites} props={childProps} />
        <AppliedRoute path="/sites/add" exact component={Site} props={childProps} />
        <AppliedRoute path="/sites/:id/edit" exact component={Site} props={childProps} />
        <AppliedRoute path="/sites/:id/edit/:type" exact component={Site} props={childProps} />
        <AppliedRoute path="/sites/:id/delete" exact component={Site} props={childProps} />

        {/* Users routes */}
        <AppliedRoute path="/resetpassword/:username/:type" exact component={ResetPassword} props={childProps} />
        <AppliedRoute path="/changepassword" exact component={ChangePassword} props={childProps} />
        <AppliedRoute path="/forgotPassword" exact component={ForgotPassword} props={childProps} />

        {/* Tariffs routes */}
        <AppliedRoute path="/tariffs" exact component={Tariffs} props={childProps} />
        <AppliedRoute path="/tariffs/add" exact component={Tariff} props={childProps} />
        <AppliedRoute path="/tariffs/:id/edit" exact component={Tariff} props={childProps} />
        <AppliedRoute path="/defaulttariffs" exact component={DefaultTariff} props={childProps} />
        <AppliedRoute path="/defaulttariffs/create" exact component={CreateDefaultTariff} props={childProps} />
        <AppliedRoute
          path="/defaulttariffs/defaulttariff/:id/edit"
          exact
          component={EditDefaultTariff}
          props={childProps}
        />

        {/* Administration Routes */}
        <AppliedRoute path="/data_correction" exact component={DataCorrection} props={childProps} />
        <AppliedRoute
          path="/wattwatchers_meter_configuration"
          exact
          component={MeterConfiguration}
          props={childProps}
        />
        <AppliedRoute
          path="/wattwatchers_meter_configuration/:id"
          exact
          component={MeterConfiguration}
          props={childProps}
        />
        <AppliedRoute path="/create_account" exact component={CreateAccount} props={childProps} />
        <AppliedRoute path="/articles" exact component={EditorialContentTable} props={childProps} />
        <AppliedRoute path="/articles/create" exact component={CreateArticle} props={childProps} />
        <AppliedRoute path="/articles/article/:id/edit" exact component={EditArticle} props={childProps} />
        <AppliedRoute path="/tenants" exact component={Tenants} props={childProps} />
        <AppliedRoute path="/tenants/create" exact component={TenantForm} props={childProps} />
        <AppliedRoute path="/tenants/tenant/:id/edit" exact component={TenantForm} props={childProps} />

        {/* Login route */}
        <AppliedRoute path="/login" exact component={Login} props={childProps} />

        {/* 404 Route */}
        <AppliedRoute path="*" component={PageNotFound} props={childProps} />
      </Switch>
    );
  };

  return <>{isLoadingAuth ? <Loader loaded /> : <Routes />}</>;
};

export default Routes;
