import React from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/date-picker.css';
import { Box } from '@chakra-ui/layout';

interface DatePickerProps {
  fieldName: string;
  control: any;
  isClearable?: boolean;
  showPopperArrow?: boolean;
  isInvalid: boolean;
  onCheckDateValidity?: (val: Date) => boolean;
  maxDate: Date;
}

const DatePickerFormControl = ({
  control,
  fieldName,
  isClearable = false,
  showPopperArrow = false,
  isInvalid = false,
  onCheckDateValidity,
  maxDate,
}: DatePickerProps) => {
  return (
    <Box className={isInvalid && 'invalid-react-datepicker'}>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { value, onChange: onChangeDate } }) => (
          <DatePicker
            maxDate={maxDate}
            placeholderText="Select date"
            selected={(value && new Date(value)) || undefined}
            isClearable={isClearable}
            showPopperArrow={showPopperArrow}
            onChange={(val: Date) => {
              const isDateValid = onCheckDateValidity ? onCheckDateValidity(val) : true;
              if (!isDateValid) return;
              onChangeDate(val.toISOString());
            }}
            dateFormat={'yyyy-MM-dd'}
          />
        )}
      />
    </Box>
  );
};

export default DatePickerFormControl;
