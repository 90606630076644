import React, { useEffect } from 'react';
import '../styles/chakra-override.css';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { AUSTRALIAN_STATES, US_STATES } from 'clipsal-cortex-utils/src/constants/timezone-states';
import { ALLOWED_COUNTRIES } from '../utils/timezone';

const ALLOWED_COUNTRIES_SELECT_OPTIONS = ALLOWED_COUNTRIES.map((c) => ({ label: c, value: c }));
const US_STATES_SELECT_OPTIONS = US_STATES.map((c) => ({ label: c, value: c }));
const AUSTRALIAN_STATES_SELECT_OPTIONS = AUSTRALIAN_STATES.map((c) => ({ label: c, value: c }));

const schema = yup.object().shape({
  country: yup.string().not(['0'], 'This field is required').required('This field is required'),
  streetAddress: yup.string().required('This field is required'),
  suburb: yup
    .string()
    .required('This field is required')
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for this field'),
  state: yup.string().not(['0'], 'This field is required').required('This field is required'),
  postcode: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .when('country', {
      is: 'Australia',
      then: yup.string().min(4, 'Must be exactly 4 digits').max(4, 'Must be exactly 4 digits'),
    })
    .when('country', {
      is: 'United States',
      then: yup.string().min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
    })
    .required('This field is required'),
});

export type ManualAddressFormData = {
  country: string;
  streetAddress: string;
  unit: string;
  suburb: string;
  state: string;
  postcode: string;
};

export type CurrentValuesData = {
  country: string;
  address: string;
  postCode: string;
  suburb: string;
  state: string;
};

type Props = {
  onSubmitManualAddress: (formValues: ManualAddressFormData) => void;
  currentValues: CurrentValuesData;
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ManualAddressDialog({ onSubmitManualAddress, onClose, currentValues }: Props & ModalProps) {
  return (
    <Modal isCentered onClose={onClose} isOpen size="6xl">
      <ModalOverlay />
      <ModalContent
        overflow={'hidden'}
        rounded={10}
        padding={6}
        data-testid="pushNotificationsModal"
        className="override-parent"
      >
        <ModalCloseButton />
        <ModalHeader>Enter Address Manually</ModalHeader>
        <ModalBody>
          <ManualAddressForm currentValues={currentValues} onSubmitManualAddress={onSubmitManualAddress} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const COUNTRY_TO_STATE_OPTIONS: Record<string, { label: string; value: string }[]> = {
  'United States': US_STATES_SELECT_OPTIONS,
  Australia: AUSTRALIAN_STATES_SELECT_OPTIONS,
};

function ManualAddressForm({ onSubmitManualAddress, currentValues }: Props) {
  const {
    register,
    watch,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ManualAddressFormData>({
    resolver: yupResolver(schema),
  });
  const countryRef = register('country');
  const stateRef = register('state');
  const [streetAddressValue, countryValue] = watch(['streetAddress', 'country']);

  useEffect(() => {
    const values = {
      ...getValues(),
      country: currentValues.country,
      streetAddress: currentValues.address,
      suburb: currentValues.suburb,
      state: currentValues.state,
      postcode: currentValues.postCode,
    };

    // They might be modifying an existing manual address
    if (currentValues.address && currentValues.address.split(', ').length === 3) {
      const [streetAddress] = currentValues.address.split(', ');

      if (streetAddress.split('/').length === 2) {
        const [address, unit] = streetAddress.split('/');
        values.streetAddress = address;
        values.unit = unit;
      } else {
        values.streetAddress = streetAddress;
      }

      values.suburb = currentValues.suburb;
      values.state = currentValues.state;
      values.postcode = currentValues.postCode;
    }

    reset(values);
  }, [currentValues, reset, getValues]);

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmitManualAddress)} mt={4}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} placeItems="center">
        <FormControl isInvalid={!!errors.country} id={'country'}>
          <FormLabel>Country</FormLabel>
          <Select
            data-private
            data-testid="site-country"
            name={countryRef.name}
            onBlur={countryRef.onBlur}
            ref={countryRef.ref}
            onChange={countryRef.onChange}
            defaultValue="Australia"
            id="country"
          >
            <option value={0}>Select a country...</option>
            {ALLOWED_COUNTRIES_SELECT_OPTIONS.map(({ label, value }, i) => (
              <option key={`country-option-${i}`} value={value}>
                {label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors?.country?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.streetAddress} id="streetAddress">
          <FormLabel>Street Address</FormLabel>
          <Input
            data-private
            data-testid="site-street-address"
            placeholder="12 George Street"
            {...register('streetAddress')}
            type="text"
            autoComplete="off"
          />
          <FormErrorMessage>{errors?.streetAddress?.message}</FormErrorMessage>
        </FormControl>
      </Grid>
      <Collapse in={!!countryValue && countryValue !== '0' && !!streetAddressValue}>
        <Grid templateColumns="repeat(2, 1fr)" gap={6} placeItems="center">
          <FormControl id="name" mt={8}>
            <FormLabel>Unit (Optional)</FormLabel>
            <Input data-private data-testid="site-unit" placeholder="11" {...register('unit')} type="text" />
          </FormControl>

          <FormControl isInvalid={!!errors.suburb} id="streetAddress" mt={8}>
            <FormLabel>Suburb</FormLabel>
            <Input data-private data-testid="site-suburb" placeholder="Haymarket" {...register('suburb')} type="text" />
            <FormErrorMessage>{errors?.suburb?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.state} id={'state'}>
            <FormLabel>State</FormLabel>
            <Select
              data-private
              data-testid="site-state"
              name={stateRef.name}
              onBlur={stateRef.onBlur}
              ref={stateRef.ref}
              onChange={stateRef.onChange}
              id="state"
            >
              <option value={0}>Select a state...</option>
              {COUNTRY_TO_STATE_OPTIONS?.[countryValue]?.map(({ label, value }, i) => (
                <option key={`state-option-${i}`} value={value}>
                  {label}
                </option>
              )) ?? []}
            </Select>
            <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.postcode} id="postcode">
            <FormLabel>{countryValue === 'Australia' ? 'Postcode' : 'Zip code'}</FormLabel>
            <Input
              data-private
              data-testid="site-postcode"
              placeholder="2000"
              {...register('postcode')}
              type="number"
            />
            <FormErrorMessage>{errors?.postcode?.message}</FormErrorMessage>
          </FormControl>
        </Grid>
      </Collapse>

      <Button isLoading={isSubmitting} type="submit" mt={8} colorScheme="clipsalGreen">
        Submit
      </Button>
    </Box>
  );
}
