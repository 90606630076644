import { Auth } from 'aws-amplify';
import React, { createContext, useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { get } from '../common/api-utils';
import { encryptCodes } from '../common/utils-helper';
import { ReactChildren } from '../common/types/helper-types';

type ContextProps = {
  isAuthenticated: boolean;
  isLoadingAuth: boolean;
  authenticateUser: () => unknown;
  authenticatedUserDetails: unknown;
  changeIsAuthenticated: (val: boolean) => void;
  login: (email: string, password: string) => void;
  logout: () => void;
};

export const AuthContext = createContext({});

/* Passing the preconfigured hook to components*/
export const useAuthContext = () => useContext(AuthContext) as ContextProps;

/* Check if user had previously logged in and not logged out yet*/
const isUserRoleInLocalStorage = !!localStorage.getItem('role');

const AuthContextProvider = ({ children }: ReactChildren) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isUserRoleInLocalStorage);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [authenticatedUserDetails, setAuthenticatedUserDetails] = useState({});
  const history = useHistory();

  const authenticateUser = async () => {
    try {
      const authenticatedResponse = await Auth.currentSession();
      if (!isUserRoleInLocalStorage) {
        storeUserRoleandAWSDetailsInLocalStorage(
          authenticatedResponse.getIdToken().getJwtToken(),
          authenticatedResponse.getIdToken().payload.email
        );
      } else {
        const userDetails = await get('users', '/user/current_user', authenticatedResponse.getIdToken().getJwtToken());
        // Only send events to sentry in staging and production environments
        if (import.meta.env['MODE'] !== 'development') {
          Sentry.setUser({ id: userDetails.user_id.toString() });
        }
      }
      setAuthenticatedUserDetails({ ...authenticatedResponse });
      setIsLoadingAuth(false);
      return authenticatedResponse;
    } catch (error) {
      setIsAuthenticated(false);
      setIsLoadingAuth(false);
      return error;
    }
  };

  const storeUserRoleandAWSDetailsInLocalStorage = async (jwtToken: string, email: string) => {
    const userDetails = await get('users', '/user/current_user', jwtToken);
    // Only send events to sentry in staging and production environments
    if (import.meta.env['MODE'] !== 'development') {
      Sentry.setUser({ id: userDetails.user_id.toString() });
    }
    /* For legacy code support. Can be updated after integrating all components to global auth */
    localStorage.setItem(
      'awssession',
      encryptCodes(
        JSON.stringify({
          jwtToken: jwtToken,
          email: email,
        })
      )
    );
    localStorage.setItem('role', encryptCodes(userDetails.role));
    history.push('/sites');
    setIsAuthenticated(true);
  };

  /* For manual control of auth on component level. For legacy code support */
  const changeIsAuthenticated = (val) => {
    setIsAuthenticated(val);
  };

  const resetLocalStorage = () => {
    localStorage.removeItem('awssession');
    localStorage.removeItem('metabase_session_id');
    localStorage.removeItem('role');
    localStorage.removeItem('tableinfo');
  };

  const login = async (email, password) => {
    resetLocalStorage();
    const user = await Auth.signIn(email, password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      history.push('/resetpassword/' + email + '/resetpassword');
    } else {
      storeUserRoleandAWSDetailsInLocalStorage(
        user.signInUserSession.idToken.jwtToken,
        user.signInUserSession.idToken.payload.email
      );
    }
  };

  const logout = async () => {
    resetLocalStorage();
    await Auth.signOut();
    history.push('/login');
    setIsAuthenticated(false);
  };

  const value = {
    isAuthenticated,
    isLoadingAuth,
    authenticateUser,
    authenticatedUserDetails,
    changeIsAuthenticated,
    login,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
