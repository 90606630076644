import { isPlainObject } from 'lodash';

/**
 * Iteratively traverses a specified `.` delimited path and returns the final node.
 *
 * @param obj The object to traverse.
 * @param path The path to traverse on the given object.
 * @returns {*}
 */
export function getFromPath(obj, path) {
  if (!isPlainObject(obj)) {
    throw new Error('Passed a non-object to `getFromPath`: ' + obj?.toString() ?? '(INVALID_OBJ)');
  }

  let node = obj;

  for (const property of path.split('.')) {
    if (!node && node !== 0 && node !== '') {
      throw new Error('Encountered a null-ish node while traversing.');
    }

    if (node.hasOwnProperty(property)) {
      node = node[property];
    } else {
      node = 'Invalid path';
      break;
    }
  }

  return node;
}
