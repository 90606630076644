import { get, getTokenAndEmailFromSession } from '../../../common/api-utils';
import { SortOrder } from '../../../components/table/Table';
import { EndPoint } from './Articles';

export const fetchEditorialContent = async <T>(
  offset: number,
  sizePerPage: number,
  orderBy: string,
  sortOrder: SortOrder,
  endPoint: EndPoint,
  activeOnly = false
) => {
  const { jwtToken } = await getTokenAndEmailFromSession();
  if (sortOrder === 'desc') orderBy = `-${orderBy}`;
  let uri = `/common/${endPoint}?limit=${sizePerPage}&offset=${offset}`;
  if (endPoint === 'articles' && activeOnly) uri += `&is_active=1`;
  if (orderBy) uri += `&ordering=${orderBy}`;
  return get<T>(endPoint, uri, jwtToken);
};
