import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useMemo } from 'react';
import Select from 'react-select';

interface Option {
  label: string | number;
  value: string | number;
}

interface SelectProps {
  options: Option[];
  onChange: (newValue: string | number) => void;
  value: string | number;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  styleProps?: any;
}

const SearchableDropdown = ({
  options,
  onChange,
  value,
  isDisabled,
  isInvalid,
  placeholder,
  styleProps,
}: SelectProps) => {
  const sortedOptions = useMemo(
    () =>
      [...options].sort((a, b) => {
        const labelOne = a.label.toString().toUpperCase();
        const labelTwo = b.label.toString().toUpperCase();
        return labelOne < labelTwo ? -1 : labelOne > labelTwo ? 1 : 0;
      }),
    [options]
  );

  //react-select requires object as value
  const selectedValueObject = useMemo(() => [...options].find((option) => option.value == value), [options, value]);

  // custom styling to match chakra-ui form styles
  const customStyles = useMemo(
    () => ({
      control: (provided: Record<any, any>, state: Record<any, any>) => ({
        ...provided,
        padding: 2,
        borderRadius: 6,
        boxShadow: isInvalid
          ? state.isFocused
            ? '0 0 0 1px #3182ce'
            : '0 0 0 1px #E53E3E'
          : state.isFocused
          ? '0 0 0 1px #3182ce'
          : 'none',
        borderColor: isInvalid ? (state.isFocused ? '#3182ce' : '#E53E3E') : state.isFocused ? '#3182ce' : 'gray.200',
        '&:hover': {
          borderColor: isInvalid ? (state.isFocused ? '#3182ce' : '#E53E3E') : state.isFocused ? '#3182ce' : 'gray.200',
        },
      }),
    }),
    [isInvalid]
  );
  return (
    <Select
      id="generic-react-select"
      styles={styleProps || customStyles}
      isDisabled={isDisabled}
      value={selectedValueObject || null}
      options={sortedOptions}
      placeholder={placeholder || 'Select...'}
      onChange={(selectedValueObj) => {
        selectedValueObj && onChange(selectedValueObj.value);
      }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => (
          <ChevronDownIcon w={8} h={8} mr={3} color={!!selectedValueObject ? 'black' : 'gray'} />
        ),
      }}
      // Elements can get obfuscated by overflow hidden or other CSS styles
      // This is yuck but only this solution worked for me and recommended in GH issue
      // https://github.com/JedWatson/react-select/issues/3263#issuecomment-445809701
      menuPortalTarget={document.querySelector('body')}
    />
  );
};

export default SearchableDropdown;
