import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { get, getTokenAndEmailFromSession, patch } from '../../../common/api-utils';
import { mapArrayToSelectFormat } from '../../../common/array-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import ContentWrapper from '../../../components/ContentWrapper';
import DefaultTariffForm from './DefaultTariffForm';

const INITIAL_STATE = {
  isLoaded: false,
  defaultTariff: null,
  distributorOptions: [],
};

export default function EditDefaultTariff({ history, match }) {
  const [state, setState] = useState(INITIAL_STATE);
  const defaultTariffId = match.params.id;

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { jwtToken } = await getTokenAndEmailFromSession();
        const [defaultTariff, distributors] = await Promise.all([
          get('tariff', `/tariff/default_tariffs/${defaultTariffId}`, jwtToken),
          get('distributors', '/tariff/distributors', jwtToken),
        ]);
        const distributorOptions = distributors.map(mapArrayToSelectFormat('distributor_name', 'id'));
        setState({
          isLoaded: true,
          distributorOptions,
          defaultTariff: {
            ...defaultTariff,
            state: defaultTariff.state,
            distributor: distributorOptions.find((opt) => opt.value === defaultTariff.distributor_id),
            year: defaultTariff.year,
            tariff_id: defaultTariff.tariff_id,
          },
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }

    if (!state.isLoaded) {
      fetchAPI();
    }
  }, [state.isLoaded, defaultTariffId]);

  async function handleEditDefaultTariff(values, actions) {
    try {
      actions.setSubmitting(true);
      const { jwtToken, email } = await getTokenAndEmailFromSession();
      const defaultTariffToSave = {
        row_id: defaultTariffId,
        state: values.state,
        distributor: values.distributor.label,
        distributor_id: values.distributor.value,
        year: values.year,
        tariff_id: values.tariff_id,
        updated_by: email,
      };

      await patch('tariff', `/tariff/default_tariffs/${defaultTariffId}`, defaultTariffToSave, jwtToken);
      toast.success('Successfully edited default tariff');
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      displayAPIErrorMessage(e);
    }
  }

  return (
    <ContentWrapper
      breadcrumbs={[{ title: 'Default Tariff', url: '#/defaulttariffs' }, { title: 'Edit Default Tariff' }]}
      title={'Edit Default Tariff'}
    >
      {state.isLoaded ? (
        <DefaultTariffForm
          initialValues={state.defaultTariff}
          distributorOptions={state.distributorOptions}
          history={history}
          onSubmit={handleEditDefaultTariff}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
          <Spinner style={{ width: '50px', height: '50px' }} animation="border" />
        </div>
      )}
    </ContentWrapper>
  );
}
