import { FieldArrayWithId, useWatch } from 'react-hook-form';
import React, { useEffect } from 'react';
import { Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import SearchableDropdownFormControl from '../../../components/SearchableDropdownFormControl';
import { CommonFieldListProps, SiteDevicesFormData } from './site-devices-types';
import { DeviceType } from '../../../api/api-device-metadata';
import { useSiteDeviceContext } from '../../../context/SiteDeviceContext';

type FieldArrayFormKeys = 'meters' | 'inverters' | 'batteries' | 'evChargers';

// Pick properties for arrays off the main form type
type FieldArrayProperties = Pick<SiteDevicesFormData, FieldArrayFormKeys>;

type ModelManufacturerSiteIdentifierFields = {
  index: number;
  field: FieldArrayWithId<SiteDevicesFormData, FieldArrayFormKeys>;
  deviceType: DeviceType;
  fieldKey: keyof FieldArrayProperties;
} & CommonFieldListProps;

export default function ModelManufacturerSiteIdentifierFields({
  errors,
  index,
  field,
  getValues,
  setValue,
  control,
  deviceType,
  fieldKey,
  register,
}: ModelManufacturerSiteIdentifierFields) {
  const {
    manufacturers,
    modelsByManufacturerId,
    isManufacturerLoaded,
    isFetchingNewModels,
    fetchModels,
    fetchManufacturersList,
  } = useSiteDeviceContext();
  const { [fieldKey]: items } = useWatch({ control });
  const selectedManufacturer = items?.[index].manufacturer;
  const isModelSelectDisabled =
    selectedManufacturer && !modelsByManufacturerId?.[deviceType]?.[selectedManufacturer.toString()]?.length;
  const errorsAtIndex = errors?.[fieldKey]?.[index];
  const valuesAtIndex = getValues()?.[fieldKey]?.[index];

  function getModelSelectText() {
    if (!selectedManufacturer) return 'Select a manufacturer to view models.';
    else if (isFetchingNewModels) return 'Loading...';
    else if (modelsByManufacturerId?.[deviceType]?.[selectedManufacturer.toString()]?.length === 0)
      return 'No models for this manufacturer.';

    return 'Select a model...';
  }

  useEffect(() => {
    fetchManufacturersList(deviceType);
  }, [deviceType]);

  useEffect(() => {
    if (selectedManufacturer && isManufacturerLoaded) {
      fetchModels(selectedManufacturer, deviceType);
    }
  }, [selectedManufacturer, deviceType, isManufacturerLoaded]);

  return (
    <Flex direction="column">
      <FormControl isInvalid={!!errorsAtIndex?.manufacturer} mt={3} id={`${field.id}_manufacturer`} mr={5}>
        <FormLabel>Manufacturer</FormLabel>
        <SearchableDropdownFormControl
          data-testid={`select-${deviceType}-manufacturer-${index}`}
          defaultValue={valuesAtIndex.manufacturer && Number(valuesAtIndex.manufacturer)}
          control={control}
          options={manufacturers[deviceType]}
          valueLabelOptions={['manufacturer_id', 'manufacturer_name']}
          fieldName={`${fieldKey}.${index}.manufacturer`}
          placeholder={manufacturers[deviceType].length ? 'Select a manufacturer...' : 'Loading...'}
          onDependentFieldAndStateUpdate={() => setValue(`${fieldKey}.${index}.model` as any, '')}
          isInvalid={!!errorsAtIndex?.manufacturer}
          isDisabled={isFetchingNewModels || !isManufacturerLoaded}
        />

        <FormErrorMessage>{errorsAtIndex?.manufacturer?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errorsAtIndex?.model} mt={3} id={`${field.id}_model`}>
        <FormLabel>Model</FormLabel>
        <SearchableDropdownFormControl
          data-testid={`select-${deviceType}-model-${index}`}
          isDisabled={!selectedManufacturer || isModelSelectDisabled || !isManufacturerLoaded}
          defaultValue={valuesAtIndex.model && Number(valuesAtIndex.model)}
          control={control}
          options={modelsByManufacturerId[deviceType]?.[selectedManufacturer] || []}
          valueLabelOptions={['manufacturer_id', 'manufacturer_name']}
          fieldName={`${fieldKey}.${index}.model`}
          placeholder={getModelSelectText()}
          isInvalid={!!errorsAtIndex?.model}
        />
        <FormErrorMessage>{errorsAtIndex?.model?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errorsAtIndex?.siteIdentifier} my={3}>
        <FormLabel>Site Identifier</FormLabel>
        <Input
          data-testid={`${deviceType}-site-identifier-${index}`}
          {...register(`${fieldKey}.${index}.siteIdentifier` as const)}
          py={'20px'}
          placeholder="Enter Site Identifier"
        />
        <FormErrorMessage>{errorsAtIndex?.siteIdentifier?.message}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
}
