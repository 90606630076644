import React from 'react';
import { Center, CircularProgress, Text } from '@chakra-ui/react';

type Props = {
  isFullHeight?: boolean;
};

export default function CenteredLoader({ isFullHeight }: Props) {
  return (
    <Center flexDirection="column" height={isFullHeight ? '100vh' : '100%'}>
      <Text fontSize="24px" mb={2}>
        Loading
      </Text>
      <CircularProgress isIndeterminate color="clipsalGreen.500" size="100px" thickness="4px" />
    </Center>
  );
}
