import React, { useState, useEffect } from 'react';
import { Box, Button, Center, Spinner } from '@chakra-ui/react';
import { del, fetchPaginatedAPIContent, getTokenAndEmailFromSession } from '../../../common/api-utils';
import Table, { HandleTableChangeProps, SortOrder } from '../../../components/table/Table';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import { useHistory } from 'react-router-dom';
import ContentWrapper from '../../../components/ContentWrapper';
import { Tenant } from '../../../api/api-user';
import { toast } from 'react-toastify';

type TenantsTableState = typeof INITIAL_STATE;

const INITIAL_STATE = {
  collection: 'common',
  endPoint: 'tenants' as const,
  offset: 0,
  sizePerPage: 10,
  page: 1,
  totalSize: 0,
  orderBy: 'tenant_id',
  sortOrder: 'asc' as SortOrder,
  searchTerm: '',
  data: [] as Tenant[],
  isLoaded: false,
  apiName: 'tenants',
};

const Tenants = () => {
  const history = useHistory();
  const [tenants, setTenants] = useState<TenantsTableState>(INITIAL_STATE);
  const { sortOrder, data, page, sizePerPage, totalSize } = tenants;

  const handleChangePageSize = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const sizePerPage = Number(e.currentTarget.value);
    setTenants({
      ...tenants,
      sizePerPage,
      isLoaded: false,
    });
  };

  const handleDeleteTenant = async (tenant: Tenant) => {
    try {
      const { tenant_id, tenant_name } = tenant;
      const { jwtToken } = await getTokenAndEmailFromSession();
      await del('tenants', `/common/tenants/${tenant_id}`, jwtToken);
      toast.success(`Successfully deleted tenant ${tenant_name}`);
      setTenants({
        ...tenants,
        data: tenants.data.filter((tenant) => tenant.tenant_id !== tenant_id),
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  function handleTableChange({ page, sizePerPage, sortField, sortOrder }: HandleTableChangeProps) {
    const offset = (page - 1) * sizePerPage;
    const { collection, searchTerm, endPoint, apiName } = tenants;
    async function fetchAPI() {
      try {
        const updatedTenants = await fetchPaginatedAPIContent<Tenant>({
          apiName,
          collection,
          endPoint,
          offset,
          sizePerPage,
          orderBy: sortField,
          sortOrder,
          searchTerm,
        });
        setTenants({
          ...tenants,
          data: updatedTenants.data,
          page,
          sortOrder,
          orderBy: sortField,
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }
    fetchAPI();
  }

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { collection, endPoint, offset, sizePerPage, orderBy, sortOrder, searchTerm, apiName } = tenants;
        const { data, item_count } = await fetchPaginatedAPIContent<Tenant>({
          apiName,
          collection,
          endPoint,
          offset,
          sizePerPage,
          orderBy,
          sortOrder,
          searchTerm,
        });
        setTenants({ ...tenants, data: data, isLoaded: true, totalSize: item_count, orderBy });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }
    if (!tenants.isLoaded) fetchAPI();
  }, [tenants.isLoaded]);

  return (
    <ContentWrapper title="Tenants">
      <Box m={10}>
        <Button
          data-testid={`create-tenant-btn`}
          mb={4}
          size={'lg'}
          height={'40px'}
          fontSize={'x-lg'}
          onClick={() => history.push('tenants/create')}
          colorScheme={'clipsalGreen'}
        >
          Create Tenant
        </Button>
        {tenants.isLoaded ? (
          <Table
            remote
            isRowExpandable={false}
            sortOrder={sortOrder}
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            tableConfig={TENANT_TABLE_CONFIG}
            onChangePageSize={(e: React.ChangeEvent<HTMLInputElement>) => handleChangePageSize(e)}
            onTableChange={handleTableChange}
            onHandleAction={handleDeleteTenant}
          />
        ) : (
          <Center height={'100%'}>
            <Spinner size={'xl'} />
          </Center>
        )}
      </Box>
    </ContentWrapper>
  );
};

export default Tenants;

const TENANT_TABLE_CONFIG = {
  keyField: 'tenant_id',
  columns: [
    { dataField: 'tenant_id', text: 'ID', sort: true, data_type: 'string' },
    { dataField: 'tenant_name', text: 'Tenant', sort: true, data_type: 'string' },
    {
      dataField: 'action',
      text: 'Action',
      formatterType: 'action',
      formatterDetails: [
        { name: 'Edit', action: '#/tenants/tenant/:id/edit', params: [{ key: 'tenant', value: 'tenant_id' }] },
        {
          name: 'Delete',
          value: 'delete_tenant',
          action: 'functioncall',
          displayValue: 'tenant_name',
          messageNeeded: true,
        },
      ],
    },
  ],
};
