import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { FormControl, FormGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

import LoaderButton from '../../components/LoaderButton';
import { displayAPIErrorMessage } from '../../common/utils-helper';

interface IForgotPasswordState {
  isLoading?: boolean;
  password?: string;
  confirmPassword?: string;
  email?: string;
  code?: string;
  codeRecieved?: boolean;
}

export default class ForgotPassword extends Component<RouteComponentProps, IForgotPasswordState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      password: '',
      confirmPassword: '',
      email: '',
      code: '',
      codeRecieved: false,
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.confirmPassword.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }
  validateUserForm() {
    return this.state.email.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      if (this.state.codeRecieved) {
        await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.confirmPassword)
          .then(() => {
            toast.success('New password reset successfully!', {
              autoClose: 5000,
            });
            this.props.history.push('/');
          })
          .catch((e) => {
            displayAPIErrorMessage(e);
            this.setState({ isLoading: false });
          });
      } else {
        await Auth.forgotPassword(this.state.email)
          .then((data) => {
            toast.success(
              'Password Reset code has been sent to your registered ' +
                data.CodeDeliveryDetails.AttributeName +
                '. Please provide code to reset password!',
              {
                autoClose: 5000,
              }
            );
            this.setState({ codeRecieved: true });
            this.setState({ isLoading: false });
          })
          .catch((e) => {
            displayAPIErrorMessage(e);
            this.setState({ isLoading: false });
          });
      }

      // await Auth.signIn(this.state.email, this.state.password);
    } catch (e) {
      displayAPIErrorMessage(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row login-row">
          <div className="login-bg col-xs-4 col-sm-7 col-md-6 col-lg-12 ">
            <div className="login-wrapper col-lg-3">
              <div className="login-logo text-center logo-img-pos"></div>
              <div className="login-content">
                <h3 className="text-center">Clipsal Solar Portal Forgot Password</h3>
                <p className="text-center">
                  Welcome to the Clipsal Solar Distributed Energy Resource Management System.
                </p>
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="mb-4">
                    <FormGroup controlId="email">
                      <label>Username</label>
                      <FormControl autoFocus type="text" value={this.state.email} onChange={this.handleChange} />
                    </FormGroup>
                  </div>
                  {this.state.codeRecieved && (
                    <div>
                      <div className="mb-4">
                        <FormGroup controlId="code">
                          <label>Code</label>
                          <FormControl type="password" value={this.state.code} onChange={this.handleChange} />
                        </FormGroup>
                      </div>
                      <div className="mb-4">
                        <FormGroup controlId="password">
                          <label>Password</label>
                          <FormControl type="password" value={this.state.password} onChange={this.handleChange} />
                        </FormGroup>
                      </div>
                      <div className="mb-5">
                        <FormGroup controlId="confirmPassword">
                          <label>Confirm Password</label>
                          <FormControl
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                            type="password"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  )}

                  {this.state.codeRecieved && (
                    <div className="form-group mt-5">
                      <LoaderButton
                        className="btn btn-lg btn-block btn-big btn-blue"
                        block
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Set New Password"
                        loadingText="Setting New Password…"
                      />
                    </div>
                  )}
                  {!this.state.codeRecieved && (
                    <div className="form-group mt-5">
                      <LoaderButton
                        className="btn btn-lg btn-block btn-big btn-blue"
                        block
                        disabled={!this.validateUserForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Forgot Password"
                        loadingText="Submitting Forgot Password…"
                      />
                    </div>
                  )}
                </form>
                <div className="text-center">
                  <a href="#/">
                    <b>Click here</b>
                  </a>{' '}
                  to go back to login page.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
