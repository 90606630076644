import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { InstalledDevice } from '../../../api/api-device';
import { del, getTokenAndEmailFromSession } from '../../../common/api-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import ModelManufacturerSiteIdentifierFields from './ModelManufacturerSiteIdentifierFields';
import { BatteryFormData, CommonFieldListProps } from './site-devices-types';

export const EMPTY_BATTERY_TEMPLATE: BatteryFormData = {
  batteryId: null,
  manufacturer: '',
  model: '',
  siteIdentifier: '',
};

type BatteryFormProps = CommonFieldListProps;

const BatteryForm = (props: BatteryFormProps) => {
  const { control, siteId } = props;
  const { fields, append, remove } = useFieldArray({
    name: `batteries`,
    control,
  });
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  useEffect(() => {
    setExpandedIndex(fields.length - 1);
  }, [fields.length]);

  async function handleDeleteBattery(batteryId: number, index: number) {
    if (window.confirm('Are you sure you want to delete this battery?')) {
      if (batteryId) {
        try {
          const { jwtToken } = await getTokenAndEmailFromSession();
          await del<InstalledDevice>('site-devices', `/site/sites/${siteId}/devices/${batteryId}`, jwtToken);
        } catch (e) {
          displayAPIErrorMessage(e);
        }
      }
      remove(index);
    }
  }

  return (
    <Box>
      <Accordion
        defaultIndex={0}
        allowToggle
        index={expandedIndex}
        onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}
      >
        {fields.map((field, index) => (
          <AccordionItem key={`${field.id}-${index}`} index={index}>
            <AccordionButton role={'button'} as={Box} py={4}>
              <Flex w={'100%'} justify={'space-between'} align={'center'}>
                <Heading>Battery {index + 1}</Heading>
                <Flex align={'center'}>
                  <Button
                    style={{ fontSize: '12px' }}
                    type="button"
                    variant={'ghost'}
                    onClick={() => handleDeleteBattery(field.batteryId, index)}
                    size={'xs'}
                    mr={2}
                    colorScheme="red"
                    aria-label="Delete battery"
                  >
                    Remove
                  </Button>
                  <AccordionIcon w={8} h={8} mr={3} />
                </Flex>
              </Flex>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <ModelManufacturerSiteIdentifierFields
                {...{ ...props, index, field, deviceType: 'BATTERY_PACK', fieldKey: 'batteries' }}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      {!fields.length && (
        <Alert status="info" my={4} rounded={4}>
          <AlertIcon w={8} h={8} />
          <AlertDescription py={4}>No Battery! Please click &quot;Add battery&quot; to add one.</AlertDescription>
        </Alert>
      )}
      <Button py={8} my={7} onClick={() => append(EMPTY_BATTERY_TEMPLATE)} type="button">
        Add Battery
      </Button>
    </Box>
  );
};

export default BatteryForm;