import { InstalledDevice, InstalledDeviceToSave } from '../../../api/api-device';
import { InverterSubarray, InverterSubarrayToSave, InverterWithSubArrays } from '../../../api/api-subarray';
import { get, getTokenAndEmailFromSession } from '../../../common/api-utils';
import {
  BatteryFormData,
  EvChargerFormData,
  InverterFormData,
  InverterSubarrayData,
  MeterFormData,
} from './site-devices-types';
export function mapMetersToForm(meters: InstalledDevice[]): MeterFormData[] {
  return meters.map<MeterFormData>((meter) => {
    return {
      meterId: meter.row_id ?? null,
      manufacturer: meter.meta_data.manufacturer_id.toString(),
      model: meter.meta_data?.device_metadata_id.toString(),
      siteIdentifier: meter.site_identifier,
      monitoringStartDate: meter.monitoring_start,
    };
  });
}

export function mapInvertersToForm(
  inverters: (InstalledDevice & { subArrays: InverterSubarray[] })[]
): InverterFormData[] {
  return inverters.map<InverterFormData>((inverter) => {
    return {
      inverterId: inverter.row_id ?? null,
      manufacturer: inverter.meta_data.manufacturer_id.toString(),
      model: inverter.meta_data.device_metadata_id.toString(),
      siteIdentifier: inverter.site_identifier,
      hasBattery: inverter.is_hybrid_inverter,
      apiKey: inverter.api_key,
      serialNumber: inverter.serial_number,
      subArrays: inverter.subArrays.map((subArray) => ({
        subArrayId: subArray.subarray_id,
        manufacturer: subArray?.solar_module?.device_metadata.manufacturer_id.toString(),
        model: subArray?.solar_module?.device_metadata.device_metadata_id.toString(),
        modulesPerString: subArray.panels_per_string,
        numOfStrings: subArray.num_strings,
        orientationDegrees: subArray.orientation,
        tiltDegrees: subArray.tilt,
      })),
    };
  });
}

export function mapBatteriesToForm(batteries: InstalledDevice[]): BatteryFormData[] {
  return batteries.map((battery) => ({
    batteryId: battery.row_id ?? null,
    manufacturer: battery.meta_data.manufacturer_id.toString(),
    model: battery.meta_data.device_metadata_id.toString(),
    siteIdentifier: battery.site_identifier,
  }));
}

export function mapInvertersToAPI(inverters: InverterFormData[], siteId: number): InstalledDeviceToSave[] {
  return inverters.map<InstalledDeviceToSave>((inverter) => ({
    row_id: inverter.inverterId ?? undefined,
    clipsal_solar_id: siteId,
    quantity: 1,
    is_hybrid_inverter: inverter.hasBattery,
    api_key: inverter.apiKey,
    serial_number: inverter.serialNumber,
    site_identifier: inverter.siteIdentifier,
    device_metadata_id: Number(inverter.model),
    meta_data: {
      device_metadata_id: inverter.model,
      device_type: 'INVERTER',
    },
  }));
}

export function mapSubArraysToAPI(subArrays: InverterSubarrayData[], inverterId: number): InverterSubarrayToSave[] {
  return subArrays.map<InverterSubarrayToSave>((subArray) => ({
    subarray_id: subArray.subArrayId ?? undefined,
    solar_device_id: Number(subArray.model),
    inverter_id: inverterId,
    orientation: subArray.orientationDegrees,
    tilt: subArray.tiltDegrees,
    panels_per_string: subArray.modulesPerString,
    num_strings: subArray.numOfStrings,
  }));
}

export function mapMetersToAPI(meters: MeterFormData[], siteId: number): InstalledDeviceToSave[] {
  return meters.map((meter) => ({
    row_id: meter.meterId ?? undefined,
    clipsal_solar_id: siteId,
    quantity: 1,
    api_key: '',
    site_identifier: meter.siteIdentifier,
    device_metadata_id: Number(meter.model),
    monitoring_start: meter.monitoringStartDate,
    meta_data: {
      device_metadata_id: meter.model,
      device_type: 'METER',
    },
  }));
}

export function mapBatteriesToAPI(batteries: BatteryFormData[], siteId: number): InstalledDeviceToSave[] {
  return batteries.map((battery) => ({
    row_id: battery.batteryId ?? undefined,
    clipsal_solar_id: siteId,
    quantity: 1,
    is_hybrid_inverter: false,
    api_key: '',
    site_identifier: battery.siteIdentifier,
    device_metadata_id: Number(battery.model),
    meta_data: {
      device_metadata_id: battery.model,
      device_type: 'BATTERY_PACK',
    },
  }));
}

export function mapEvChargersToForm(evChargers: InstalledDevice[]): EvChargerFormData[] {
  return evChargers.map((evCharger) => ({
    evChargerId: evCharger.row_id ?? null,
    manufacturer: evCharger.meta_data.manufacturer_id.toString(),
    model: evCharger.meta_data.device_metadata_id.toString(),
    siteIdentifier: evCharger.site_identifier,
    serialNumber: evCharger.serial_number,
  }));
}

export function mapEvChargersToAPI(evChargers: EvChargerFormData[], siteId: number): InstalledDeviceToSave[] {
  return evChargers.map((evCharger) => ({
    row_id: evCharger.evChargerId ?? undefined,
    clipsal_solar_id: siteId,
    quantity: 1,
    is_hybrid_inverter: false,
    api_key: '',
    site_identifier: evCharger.siteIdentifier,
    serial_number: evCharger.serialNumber,
    device_metadata_id: Number(evCharger.model),
    meta_data: {
      device_metadata_id: evCharger.model,
      device_type: 'EV_CHARGER',
    },
  }));
}

export function mapDevicesToForm(installedDevice: InstalledDevice[]) {
  const savedMeterData = installedDevice.filter((device) => device.meta_data.device_type === 'METER');
  const savedBatteryData = installedDevice.filter((device) => device.meta_data.device_type === 'BATTERY_PACK');
  const savedEvChargerData = installedDevice.filter((device) => device.meta_data.device_type === 'EV_CHARGER');
  const formMeters = mapMetersToForm(savedMeterData);
  const formBatteries = mapBatteriesToForm(savedBatteryData);
  const formEvChargers = mapEvChargersToForm(savedEvChargerData);
  return { formMeters, formBatteries, formEvChargers };
}

export async function associateInvertersWithAPISubArrays(
  inverters: InstalledDevice[]
): Promise<InverterWithSubArrays[]> {
  const { jwtToken: token } = await getTokenAndEmailFromSession();
  return await Promise.all(
    inverters.map(async (inverter) => {
      const inverterSubArrays = await get<InverterSubarray[]>(
        'inverter_sub_arrays',
        `/site/inverters/${inverter.row_id}/subarrays`,
        token
      );
      return {
        ...inverter,
        subArrays: inverterSubArrays,
      };
    })
  );
}
export function associateInvertersWithFormSubArrays(inverter: InstalledDevice, inverterFormData: InverterFormData) {
  return {
    inverterId: inverter.row_id as number,
    manufacturer: inverter.meta_data.manufacturer_id.toString(),
    model: inverter.meta_data?.device_metadata_id.toString(),
    serialNumber: inverter.serial_number,
    siteIdentifier: inverter.site_identifier,
    apiKey: inverter.api_key,
    hasBattery: inverter.is_hybrid_inverter,
    subArrays: inverterFormData.subArrays,
  };
}
