import { Tenant } from '../../../api/api-user';
import { BusinessData } from './TenantForm';

export const mapTenantToApi = (values: BusinessData) => {
  return {
    tenant_name: values.name,
    address: values.address,
    city: values.city,
    state: values.state,
    country: values.country,
    zipcode: values.postCode,
  };
};

export const mapTenantToForm = (values: Tenant) => {
  return {
    name: values.tenant_name || '',
    address: values.address || '',
    city: values.city || '',
    state: values.state || '',
    postCode: values.zipcode || '',
    country: values.country || '',
  };
};
