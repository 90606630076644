import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import { MS_PER_DAY } from './constants';
const secret = 'Efwer#97FhgtsklhjhgfD';

type obj = {
  [key: string]: unknown;
};

export const getSelectedOpt = (list: number[], key: string, id: number): number[] => {
  if (key === '') {
    return list.filter((item) => {
      return item === id;
    });
  }
  return list.filter((item) => {
    return item[key] === id;
  });
};

export const dateTimeFormat = (): string => {
  const today = new Date();
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  return date + ' ' + time;
};

export const isEmpty = (obj: obj): boolean => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const toFixedDown = (value: number, digits: number): number | string => {
  const re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
    m = value.toString().match(re);
  return m ? parseFloat(m[1]) : value.valueOf();
};

/**
 * Top-level validation mechanism for any form.
 *
 * @param formValues
 * @param fieldValidationConfigArray
 * @returns {string}
 */
export const formValidation = (
  formValues: Record<string, any>,
  fieldValidationConfigArray: Record<string, any>[]
): string => {
  let message = '';

  const findFirstInvalidField = (item) => {
    if (item.required) {
      const value = formValues[item.value];
      const isFieldEmpty = !value || Number(value) === -1;

      if (item.type === 'email') {
        return isFieldEmpty || !checkIfEmailValid(String(value));
      } else if (item.type === 'phone') {
        return isFieldEmpty || !checkIfPhoneNumberValid(value.toString());
      }

      return isFieldEmpty;
    }

    return false;
  };

  // Find the first invalid field
  const firstInvalidField = fieldValidationConfigArray.find(findFirstInvalidField);

  // Display a message for the invalid field
  if (firstInvalidField) {
    if (!formValues[firstInvalidField.value]) {
      message = firstInvalidField.label + ' field cannot be empty';
    } else {
      message = 'Please provide a valid ' + firstInvalidField.label;
    }
  }

  return message;
};

export const validateNMI = (nmiValue: string): string | null => {
  if (nmiValue) {
    return ![10, 11].includes(nmiValue.toString().length)
      ? 'Customer NMI should be a 10 or 11 digit unique number or an empty field'
      : null;
  }
  return null;
};

export const domain_validation = (email: string): boolean => {
  const notAllowedDomains = ['clipsalsolar.com'];
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isAllowedDomain = true;
  if (re.test(email)) {
    const emailarr = email.split('@');
    for (let i = 0; i < notAllowedDomains.length; i++) {
      if (notAllowedDomains[i] === emailarr[1]) {
        isAllowedDomain = false;
        break;
      }
    }
    return isAllowedDomain;
  } else {
    return false;
  }
};
export const domain_validation_check = (email: string): boolean => {
  const allowedDomains = ['clipsalsolar.com'];
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isAllowedDomain = false;
  if (re.test(email)) {
    const emailarr = email.split('@');
    for (let i = 0; i < allowedDomains.length; i++) {
      if (allowedDomains[i] === emailarr[1]) {
        isAllowedDomain = true;
        break;
      }
    }
    return isAllowedDomain;
  } else {
    return false;
  }
};

function zeroPad(num, places) {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

export const addmonthExist = (start_date: string, end_date: string, seasonMonthList: string[]): string[] => {
  const max = 12;
  let temp: number | null = parseInt(start_date);
  while (temp != null) {
    if (!(zeroPad(temp, 2) in seasonMonthList)) {
      seasonMonthList.push(zeroPad(temp, 2));
    }

    temp += 1;
    if (temp > max) {
      temp = 1;
    }
    if (temp === parseInt(end_date)) {
      temp = null;
      seasonMonthList.push(zeroPad(end_date, 2));
    }
  }
  return seasonMonthList;
};

export const monthValidation = (start_date: string, end_date: string, seasonMonthList: string[]): string => {
  let msg = '';
  const max = 12;
  let temp: number | null = parseInt(start_date);
  while (temp != null) {
    if (seasonMonthList.includes(zeroPad(temp, 2))) {
      msg = 'Month in this season range already exist for the current tariff';
      return msg;
    }
    temp += 1;
    if (temp > max) {
      temp = 1;
    }
    if (temp === parseInt(end_date)) {
      temp = null;
    }
  }
  return msg;
};

export const monthTariffValidation = (seasonMonthList: string[]): string => {
  let msg = '';
  const valid_list = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  if (valid_list.join() == seasonMonthList.sort().join()) {
    msg = 'valid';
  }
  return msg;
};

type effective_dateValidationObj = {
  tariff_effective_date: string;
};

export const effective_dateValidation = (data: effective_dateValidationObj): string => {
  let message = '';
  if (data.tariff_effective_date == null) {
    message = 'Effective date is required';
  } else if (moment(data.tariff_effective_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
    return (message = 'Effective date should be greater than current date');
  }

  return message;
};

/**
 * Applies a rudimentary regex test against the supplied email string.
 *
 * @param {string} email The email string to validate
 * @returns {boolean}
 */
export const checkIfEmailValid = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Applies a rudimentary regex test against the supplied phone number string.
 *
 * @param {string} phoneNumber The
 * @returns {boolean}
 */
export const checkIfPhoneNumberValid = (phoneNumber: string): boolean => {
  // Ensure the string length is 12 (11 numbers prefixed by one '+')
  return phoneNumber.length === 12 && /\+[0-9]{11}/.test(phoneNumber);
};

type rateValidationObj = {
  rates: string;
};

export const rateValidation = (data: rateValidationObj): string => {
  let message = '';
  const payload = data['associateRate'];
  for (let i = 0; i < data['rates'].length; i++) {
    if (
      payload['import_rate_cents_per_kwh'] == data['rates'][i]['import_rate_cents_per_kwh'] &&
      payload['export_rate_cents_per_kwh'] == data['rates'][i]['export_rate_cents_per_kwh']
    ) {
      return (message = "Rate Combination already exists as '" + data['rates'][i]['name'] + "'");
    }
  }
  return message;
};

export const tierValidation = (data) => {
  let message = '';
  const fields = [
    { value: 'tier_max_energy_in_kwh', label: 'tier max energy' },
    { value: 'rate.import_rate_cents_per_kwh', label: 'import rate' },
  ];
  let filedname = '';
  let validationType = '';
  const tiervalidation = data.find((item, i, arr) => {
    for (let fieldCnt = 0; fieldCnt < fields.length; fieldCnt++) {
      const prevRecord = arr[i - 1];
      let prevVal = '';
      let curVal = '';
      const field = fields[fieldCnt].value;
      filedname = fields[fieldCnt].label;
      if (field.includes('.')) {
        const fieldarr = field.split('.');
        if (item[fieldarr[0]][0][fieldarr[1]]) {
          curVal = item[fieldarr[0]][0][fieldarr[1]];
          if (prevRecord) {
            prevVal = prevRecord[fieldarr[0]][0][fieldarr[1]];
          }
        }
      } else {
        if (item[field]) {
          curVal = item[field];
          if (prevRecord) {
            prevVal = prevRecord[field];
          }
        }
      }
      if (curVal === '') {
        validationType = 'form';
        return curVal === '';
      } else if (i !== 0 && parseInt(prevVal) > parseInt(curVal)) {
        return parseInt(prevVal) > parseInt(curVal);
      }
    }
  });
  if (tiervalidation) {
    let tiername = tiervalidation.tier_name;
    if (data.length === tiervalidation.tier_id) {
      tiername = 'Balance';
    }
    if (validationType === 'form') {
      message = filedname + " cann't be empty in " + tiername + '!';
    }
    // else{
    //   message= filedname +" is less than to the previous tier in "+ tiername+"!";
    // }
  }
  return message;
};
export const nextLetter = (s: string): string => {
  return s.replace(/([a-zA-Z])[^a-zA-Z]*$/, function (a) {
    let c = a.charCodeAt(0);
    switch (c) {
      case 90:
        return 'A';
      case 122:
        return 'a';
      default:
        return String.fromCharCode(++c);
    }
  });
};

export const letterValue = (letter: string): string => {
  const anum = {
    a: 1,
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
    h: 8,
    i: 9,
    j: 10,
    k: 11,
    l: 12,
    m: 13,
    n: 14,
    o: 15,
    p: 16,
    q: 17,
    r: 18,
    s: 19,
    t: 20,
    u: 21,
    v: 22,
    w: 23,
    x: 24,
    y: 25,
    z: 26,
  };

  return anum[letter];
};

export const stringToColour = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const camelize = (str: string): string => {
  return str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, function (match) {
    return match.charAt(match.length - 1).toUpperCase();
  });
};

export const snakeCaseToTitleCase = (string: string): string => {
  return string.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()));
};

export const pascalCaseToTitleCase = (s: string) =>
  s
    ?.replace(/([A-Z][a-z]|[A-Z]+(?=[A-Z]|$))/g, ' $1')
    ?.replace(/./, (m) => m?.toUpperCase())
    ?.trim();

export const encryptCodes = (passcode: string): string => {
  return CryptoJS.AES.encrypt(passcode, secret).toString();
};
export const decryptCodes = (encryptedCode: string): string => {
  return CryptoJS.AES.decrypt(encryptedCode, secret).toString(CryptoJS.enc.Utf8);
};

export const timestamp = (date: string): number => {
  const datestring = new Date(date).getTime();
  return datestring;
};

export const isObject = (obj: obj): boolean => {
  return obj !== undefined && obj !== null && obj.constructor == Object;
};

export const fileExtension = (url: string): string | undefined => {
  if (!url.toLowerCase().includes('base64')) {
    const newUrl = url.split('.').pop();
    if (newUrl) {
      return newUrl.split(/#|\?/)[0];
    }
  } else {
    const contenttype = url.split(';')[0];
    return contenttype.split('/')[1];
  }
};

export const getFilename = (url: string): string => {
  if (url) {
    url = decodeURI(url);
    url = url.substring(url.lastIndexOf('/') + 1);
    return (url.match(/[^.]+(\.[^?#]+)?/) || [])[0];
  }

  return '';
};

export const displayAPIErrorMessage = (error): void => {
  console.error(error);
  const hasResponseProperty = error?.response;

  const defaultErrMsg = (error) => {
    const errorMessage = error?.message;
    toast.error(errorMessage ? errorMessage : 'Internal server error', { autoClose: 5000 });
  };

  const responseErrMsg = (error) => {
    const errorMessage = error.response.data?.message;
    toast.error(errorMessage ? errorMessage : 'Internal server error', { autoClose: 5000 });
  };

  if (hasResponseProperty) return responseErrMsg(error);
  return defaultErrMsg(error);
};

type columnObj = {
  map_col: string;
};

export const lower_dict_keys = (dict_map: obj, columns: columnObj[]): obj => {
  const result: obj = {};
  for (const key in dict_map) {
    let mapObj;
    if (columns && columns.length > 0) {
      mapObj = columns.find((item) => item.map_col === key);
    }
    let map_key = key;
    if (mapObj) {
      map_key = mapObj.dataField;
    }
    let value = dict_map[key];

    if (key === 'time') {
      value = moment.utc(dict_map[key], 'X').format('YYYY-MM-DD HH:mm:ss');
    }
    try {
      result[map_key.toLowerCase()] = value;
    } catch (e) {
      result[map_key] = value;
    }
  }
  return result;
};
export const generate_payload = (list_data, defaults, columns, extracols) => {
  const payload: obj[] = [];

  for (let i = 0; i < list_data.length; i++) {
    let rcd = {};
    if (list_data[i].constructor === Object) {
      rcd = mapToObj(defaults, list_data[i]);

      if (extracols) {
        for (const key in extracols) {
          const val = extracols[key];
          rcd[key] = val;
        }
      }
    }
    payload.push(lower_dict_keys(rcd, columns));
  }
  return payload;
};

export const flatten_complex_element = (map, defaults, columns, extracols) => {
  const flattened = {};
  let list_element: obj[] | null = null;
  for (const key in map) {
    if (map[key].constructor === Object) {
      map = flatten_dict(key, map[key], flattened);
    } else if (Array.isArray(map[key])) {
      list_element = map[key];
    } else {
      flattened[key] = map[key];
    }
  }
  if (list_element === null) {
    list_element = [];
    list_element.push(flattened);
  } else {
    defaults = mapToObj(flattened, defaults);
  }
  return generate_payload(list_element, defaults, columns, extracols);
};
export const flatten_dict = (name, data, map) => {
  for (const key in data) {
    if (data.constructor === Object) {
      map = flatten_dict(key, data[key], map);
    } else {
      let key_to_use = key;
      if (key in map) {
        key_to_use = name + '_' + key;
      }
      map[key_to_use] = data[key];
    }
  }
  return map;
};
export const mapToObj = (map, existingmap): obj[] => {
  for (const key in map) existingmap[key] = map[key];
  return existingmap;
};

export const flatten = (data, defaults, columns, extracols) => {
  if (defaults === undefined) {
    defaults = {};
  }
  let data_stream: obj[] = [];
  let local_defaults = {};
  if (Object.keys(defaults).length > 0 && defaults.constructor === Object) {
    local_defaults = mapToObj(defaults, local_defaults);
  }
  let list_element: obj[] = [];

  if (Array.isArray(data)) {
    list_element = data;
  } else if (data.constructor === Object) {
    const keys = Object.keys(data);
    for (const key in keys) {
      if (Array.isArray(data[key])) {
        list_element = data[key];
      } else if (data.constructor === Object) {
        flatten_dict(key, data[key], local_defaults);
      } else {
        local_defaults[key] = data[key];
      }
    }
  }
  if (list_element.length > 0) {
    for (let i = 0; i < list_element.length; i++) {
      const flatten_list = flatten_complex_element(list_element[i], local_defaults, columns, extracols);
      data_stream = data_stream.concat(flatten_list);
    }
  } else {
    const flatten_list = flatten_complex_element(local_defaults, {}, columns, extracols);
    data_stream = data_stream.concat(flatten_list);
  }
  return data_stream;
};

/**
 * Formats a `Date` object, according to the string structure provided from the API.
 *
 * @param date - The date object to format.
 */
export function formatDate(date: Date): string {
  return `${date.getFullYear()}-${new Intl.DateTimeFormat('en', {
    month: '2-digit',
  }).format(date)}-${new Intl.DateTimeFormat('en', {
    day: '2-digit',
  }).format(date)}`;
}

/**
 * Formats a `Date` object to a date time string, according to the string structure provided from the API.
 *
 * @param date - The date object to format.
 */
export function formatDateTime(date: Date): string {
  const formattedDate = formatDate(date);
  const time = formatTime(date);

  return `${formattedDate} ${time}`;
}

/**
 * Formats a `Date` object to a time string as `HH:MM`
 *
 * @param date
 * @returns {string}
 */
export function formatShortTime(date: Date): string {
  return `${date.getHours()}:${date.getMinutes().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
}

/**
 * Formats a `Date` object to a time string as `HH:MM:SS`.
 *
 * @param date
 * @returns {string}
 */
export function formatTime(date: Date): string {
  return `${date.getHours()}:${date.getMinutes().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${date.getSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;
}

export const utcToLocal = (date: Date): string => {
  const local_time = moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
  return local_time;
};

/**
 * Calculates the time difference between two specified dates.
 *
 * @param start - The starting date, to be calculated from
 * @param end - The ending date, to be calculated to
 * @returns The number of days between the two specified dates.
 */
export function calculateDifferenceBetweenDates(start: Date, end: Date): number {
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

/**
 * Used to debounce a timeout on an `onChange` or similar event emitter.
 *
 * @param fn - The callable which is pseudo-invoked following the delay.
 * @param delay - Time, in ms, to wait until the function is invoked.
 */

export function debounceEvent<T, R>(fn: (...fnArgs: T[]) => R, delay = 300) {
  let timeoutId: NodeJS.Timeout;

  return function (this: any, ...args: T[]) {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
}

/**
 * Capitalizes the first character of a given string
 *
 * @param str - The string to capitalize the first letter of
 * @returns A new string with the first letter captialized
 */
export const capitalizeFirstChar = (str: string): string => str.charAt(0).toUpperCase() + str.substring(1);
