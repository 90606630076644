import { Table } from 'react-bootstrap';
import React from 'react';
import { ChannelDataTuple } from '../../../api/api-ww-meter';
import config from '../../../components/chart-colors.config';

type Props = {
  channels: ChannelDataTuple;
  onToggleLine: (channelIndex: number) => void;
  hiddenIndexes: number[];
};

export default function LongEnergyTable({ channels, onToggleLine, hiddenIndexes }: Props) {
  return (
    <>
      <h3 style={{ marginTop: '1rem' }}>Legend </h3>
      <div style={{ width: '25%' }}>
        <Table striped hover size="sm">
          <thead>
            <tr>
              <td>Channel</td>
            </tr>
          </thead>
          <tbody>
            {channels.map((channel, index) => (
              <tr
                style={{ cursor: 'pointer', opacity: hiddenIndexes.includes(index) ? 0.3 : 1 }}
                onClick={() => {
                  onToggleLine(index);
                }}
                key={`live-table-row-${index}`}
              >
                <td style={{ display: 'flex' }}>
                  <div
                    style={{
                      height: '20px',
                      width: '20px',
                      background: config[index],
                      borderRadius: '5px',
                      marginRight: '0.25rem',
                    }}
                  />{' '}
                  {channel.label} (CH {index + 1})
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
