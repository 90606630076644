import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

import { convertHourTo12HourTime, formatMonth } from '../../../common/date-formatting';
import config from './tou-rate-colours.config';

// @TODO: seems like only one season is ever returned -- are seasons the right way to refer to this top-level array?
export default function NestedTOUTariffTable({ seasons }) {
  if (!seasons.length) {
    return <div>No seasons configured for this tariff!</div>;
  }

  const allSeasonsRates = getAllSeasonsRates(seasons);

  return (
    <Accordion>
      {seasons.map((season, seasonIndex) => {
        return (
          <Card key={seasonIndex} style={{ margin: '1rem' }}>
            <Card.Header>
              <Accordion.Toggle style={{ cursor: 'pointer' }} as={Card.Header} eventKey={`${seasonIndex}`}>
                <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '0.5rem' }} />
                {formatMonth(season.start_month)} ({season.start_month}) - {formatMonth(season.end_month)} (
                {season.end_month})
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey={`${seasonIndex}`}>
              <Card.Body>
                <div style={getTOUAccordionBodyStyle()}>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr style={{ fontSize: '12px' }}>
                          <td></td>
                          {Array.from(Array(24)).map((_, i) => (
                            <td key={`time-value-${i}`}>{convertHourTo12HourTime(i)}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(season.applied_rates).map(([day, rateIndexes], rateRowIndex) => {
                          return (
                            <tr key={`applied-rate-row-${rateRowIndex}`}>
                              <td style={{ fontSize: '12px' }}>{day}</td>

                              {/* Each rateId matches to the ID of an applied rate object on the TOU response object. */}
                              {(rateIndexes as any).map((rateId, rateIndex) => (
                                <td key={`rate-value-${rateRowIndex}-${rateIndex}`}>
                                  {getAppliedRateWidget(rateId, allSeasonsRates)}
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <h2>Rates</h2>
                    <div style={{ margin: '2rem', padding: '1rem', borderRadius: '5px', border: '1px solid grey' }}>
                      <div style={getTOURateRowStyle()}>
                        <h3>Rate</h3>
                        <h3>Import</h3>
                        <h3>Export</h3>
                      </div>
                      <div>
                        {season.rates.map((rate, rateIndex) => {
                          return (
                            <div
                              key={`rate-value-${rateIndex}`}
                              style={{ ...getTOURateRowStyle(), marginBottom: '0.5rem' }}
                            >
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {getAppliedRateWidget(rate.id, allSeasonsRates)}
                              </div>
                              <div>{rate.import_rate}</div>
                              <div>{rate.export_rate}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      })}
    </Accordion>
  );
}

export function getAllSeasonsRates(seasons) {
  return seasons.reduce((allRates, season) => {
    season.rates.forEach((rate) => {
      const rateInReducedArray = allRates.find((_rate) => _rate.id === rate.id);

      if (!rateInReducedArray) {
        allRates.push(rate);
      }
    });

    return allRates;
  }, []);
}

export function getAppliedRateWidget(appliedRateId, allAppliedRates) {
  const rateIndex = allAppliedRates.indexOf(allAppliedRates.find((appliedRate) => appliedRate.id === appliedRateId));

  if (rateIndex !== -1) {
    if (rateIndex > config.length - 1) {
      const newRateIndex = rateIndex % 26;
      const { color, letter } = config[newRateIndex];
      const newLetter = `${letter}${rateIndex - 26 + 1}`;
      return <div style={getAppliedRateWidgetStyle(color)}>{newLetter}</div>;
    } else {
      const { color, letter } = config[rateIndex];
      return <div style={getAppliedRateWidgetStyle(color)}>{letter}</div>;
    }
  }

  // No rate.
  return <></>;
}

export function getTOUAccordionBodyStyle() {
  return {
    display: 'grid',
    gridTemplateColumns: '0.75fr 0.25fr',
  };
}

export function getTOURateRowStyle() {
  return {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  };
}

export function getAppliedRateWidgetStyle(color) {
  return {
    width: '15px',
    height: '15px',
    borderRadius: '300px',
    backgroundColor: color,
    color: 'white',
    fontWeight: 800,
    fontSize: '12px',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
}
