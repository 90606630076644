import React from 'react';
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function BarChart({ data, xAxisKey, barConfig }) {
  return (
    <ResponsiveContainer width={'99%'} height={250}>
      <RechartsBarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        {barConfig.map(({ key, color }, barConfigIndex) => (
          <Bar
            stackId="0" // @TODO: add stackId functionality if needed for separate stacks per interval
            opacity={1}
            key={`bar-chart-bar-${barConfigIndex}`}
            dataKey={key}
            stroke={color}
            fill={color}
          />
        ))}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
}
