import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import ReactLoader from 'react-loader';
import { toast } from 'react-toastify';

import { getTokenAndEmailFromSession } from '../../common/api-utils';
import { displayAPIErrorMessage } from '../../common/utils-helper';

const INITIAL_STATE = {
  isDropdownOpen: false,
  currentDropdownSelection: [],
  users: [],
  siteUsers: [],
  deletedUsers: [],
  displayedUsers: [],
  inputValue: '',
  originalSiteUsers: null,
  siteId: null,
  isLoaded: false,
};

/**
 * Houses form functionality for site users information to be used within the `Site` component.
 *
 * // @TODO: refactor with formik and yup
 * @returns {JSX.Element}
 *
 * @constructor
 * @component
 */
function SiteUserInformation({ siteId, handleCancel, isParentLoaded }) {
  const [state, setState] = useState(INITIAL_STATE);
  useEffect(() => {
    async function initialFetch() {
      if (isParentLoaded && siteId) {
        setState((prevState) => ({ ...prevState, isLoaded: false }));
        let newState = { ...state };
        const { jwtToken } = await getTokenAndEmailFromSession();
        const allUsers = await fetchUsers(jwtToken);
        const siteUsers = await fetchSiteUsers(siteId, jwtToken);
        newState = {
          ...newState,
          siteId,
          users: allUsers.data,
          siteUsers: siteUsers,
        };

        //Clone the original values
        newState.originalSiteUsers = newState.siteUsers.map((i) => ({ ...i }));
        newState.displayedUsers = newState.users.map((i) => ({ ...i }));

        setState({ ...newState, isLoaded: true });
      }
    }

    try {
      if (isParentLoaded) {
        initialFetch();
      }
    } catch (e) {
      setState((prevState) => ({ ...prevState, isLoaded: true }));
      displayAPIErrorMessage(e);
    }
  }, [siteId, isParentLoaded]);

  const fetchUsers = async (token) => {
    return API.get('users', '/user/users', { headers: { 'Content-Type': 'application/json', Authorization: token } });
  };

  const fetchSiteUsers = async (siteId, token) => {
    return API.get('associate-user', '/site/sites/' + siteId + '/users', {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  };

  const handleSelected = (e, user) => {
    const { currentDropdownSelection } = state;
    const ischecked = e.target.checked;
    if (ischecked) {
      user['override_global_recommend'] = 'N';
      const newList = currentDropdownSelection.slice();
      newList.push({ user: user });
      setState({ ...state, currentDropdownSelection: newList });
    } else {
      setState((state) => {
        const currentDropdownSelection = state.currentDropdownSelection.filter(
          (item) => item.user.user_id !== user.user_id
        );
        return {
          ...state,
          currentDropdownSelection,
        };
      });
    }
  };

  const searchRecords = (e) => {
    const searchText = e.target.value;
    setState({ ...state, inputValue: e.target.value });
    const searchMatches = state.users.filter((user) => {
      return user.user_name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    });
    setState({ ...state, displayedUsers: searchMatches, inputValue: searchText });
  };

  const showDropDownlist = () => {
    setState({ ...state, isDropdownOpen: true });
  };

  const onClickCancel = () => {
    setState({ ...state, isDropdownOpen: false, currentDropdownSelection: [], inputValue: '' });
  };

  const onClickOk = () => {
    const { currentDropdownSelection, siteUsers, users } = state;
    const newSiteUsers = siteUsers.map((siteUser) => ({ ...siteUser }));
    currentDropdownSelection.forEach((user) => {
      newSiteUsers.push(user);
    });
    const usersData = users.filter((item) => {
      return (
        currentDropdownSelection.filter((o) => {
          return o.user.user_id == item.user_id;
        }).length == 0
      );
    });
    setState({
      ...state,
      isDropdownOpen: false,
      siteUsers: newSiteUsers,
      displayedUsers: usersData,
      currentDropdownSelection: [],
      inputValue: '',
    });
  };

  const handleRemoveUser = (user) => {
    const { users, siteUsers, deletedUsers } = state;
    if (user) {
      const updatedDeletedUsers = deletedUsers.map((deletedUser) => ({ ...deletedUser }));
      updatedDeletedUsers.push(user);
      const updatedSiteUsers = siteUsers.filter((u) => {
        return u.user.user_id !== user.user.user_id;
      });
      setState({ ...state, users, siteUsers: updatedSiteUsers, deletedUsers: updatedDeletedUsers });
    }
  };

  const handleSaveUserAssociation = async (event) => {
    event.preventDefault();
    const { siteUsers, deletedUsers, siteId, originalSiteUsers } = state;
    const { jwtToken } = await getTokenAndEmailFromSession();

    try {
      // Filter existing users
      const siteUsersData = siteUsers
        .filter((i) => originalSiteUsers.map((o) => o.user.user_id).indexOf(i.user.user_id) < 0)
        .map((item) => ({
          user_id: item.user.user_id,
        }));
      const options = {
        body: siteUsersData,
        headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
      };
      // Post new users
      if (siteUsersData.length) await API.post('associate-user', `/site/sites/${siteId}/users`, options);

      // Save removed users if any
      if (deletedUsers.length) {
        const deletedUserIds = deletedUsers.map((deletedUser) => deletedUser.row_id);
        for (const deletedUserId of deletedUserIds) {
          await API.del('associate-user', `/site/sites/${siteId}/users/${deletedUserId.toString()}`, {
            headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
          });
        }
        toast.success('👍 Associate user updated successfully', {
          autoClose: 5000,
        });
      } else {
        toast.success('👍 Associate user saved successfully', {
          autoClose: 5000,
        });
      }

      //Reset State Variables
      setState({ ...state, deletedUsers: [], originalSiteUsers: siteUsers });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  const { siteUsers } = state;
  return (
    <div>
      <div className="filter-sec d-flex justify-content-start mt-4 mb-4">
        <form className="form-inline my-2 my-lg-0">
          <div className="position-relative lbl-top btn-search ">
            <label>User</label>
            <div>
              <input
                type="text"
                value={state.inputValue}
                placeholder="Please type for users."
                onClick={() => showDropDownlist()}
                onChange={(event) => searchRecords(event)}
              ></input>
              <span className="userdropdownpointer" onClick={() => showDropDownlist()}>
                <FontAwesomeIcon icon="sort-down" />
              </span>
            </div>
            {/* Display Dropdown with search. Re-visit with better UX */}
            {state.isDropdownOpen && (
              <div className="custom-dropdownbox">
                <div className="custom-dropdownbox-list">
                  {state.displayedUsers
                    .filter((i) => siteUsers.map((o) => o.user.user_id).indexOf(i.user_id) < 0)
                    .sort((a, b) => (a.user_full_name > b.user_full_name ? 1 : -1))
                    .map((user) => {
                      const found = state.currentDropdownSelection.filter((el) => el.user.user_id === user.user_id);
                      return (
                        <div key={user.user_id}>
                          <input
                            type="checkbox"
                            checked={found.length > 0}
                            onChange={(event) => handleSelected(event, user)}
                          />
                          <span>{user.user_full_name}</span>
                        </div>
                      );
                    })}

                  {!state.isLoaded && <ReactLoader loaded={!state.isLoaded} />}
                </div>
                <div className="btn-dropdown">
                  <span>
                    <a className="btn btn-outline-success btn-lg" onClick={() => onClickOk()} role="button">
                      OK
                    </a>
                  </span>
                  <span>&nbsp;&nbsp;</span>
                  <span>
                    <a onClick={() => onClickCancel()} className="btn btn-outline-danger btn-lg" role="button">
                      Cancel
                    </a>
                  </span>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>

      {/* Display Selected Users List. Re-think the way its displayed currently */}
      <Form noValidate>
        {siteUsers.map((siteUser) => {
          return (
            <Form.Row key={siteUser.user.user_id}>
              <Form.Group as={Col} md="3" controlId="name">
                <Form.Label>{siteUser.user.user_full_name}</Form.Label>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="name">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-lg"
                  onClick={() => handleRemoveUser(siteUser)}
                >
                  Remove
                </button>
              </Form.Group>
            </Form.Row>
          );
        })}
      </Form>
      <hr />
      <div className="btn-wrapper text-right mt-3">
        <button className="btn btn-upload" onClick={handleCancel}>
          <FontAwesomeIcon className="btn-img-pos" icon={faTimes} /> Cancel{' '}
        </button>
        <button className="btn btn-upload ml-3" onClick={handleSaveUserAssociation}>
          <FontAwesomeIcon className="btn-img-pos" icon={faSave} /> Save User Association{' '}
        </button>
      </div>
    </div>
  );
}

export default SiteUserInformation;
