import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import useConstant from 'use-constant';
import * as yup from 'yup';

const schema = yup.object().shape({
  site: yup.object().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
});

export const INITIAL_FORM_VALUES = {
  site: null,
  startDate: null,
  endDate: null,
};

export default function DataCorrectionForm({
  onSubmit,
  siteOptions,
  onSearchSites,
  onFormValueChange,
  onBackfillEntireHistory,
  formValues = INITIAL_FORM_VALUES,
  eventKey = '',
}) {
  const checkDateBeforeToday = (date) => {
    return date <= new Date();
  };

  const handleSearchKeystroke = useConstant(() =>
    (AwesomeDebouncePromise as any)(async (inputValue) => onSearchSites(inputValue), 300)
  );

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={formValues}
    >
      {({ handleSubmit, values, touched, errors, setFieldValue }) => {
        const wrappedHandleChange = (type, value) => {
          setFieldValue(type, value);
          onFormValueChange({ ...values, [type]: value });
        };

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Col md="4">
                <label>Site</label>
                <AsyncSelect
                  id={'select-site-' + eventKey}
                  classNamePrefix="react-select"
                  value={values.site}
                  placeholder={'Select a site'}
                  defaultOptions={siteOptions}
                  loadOptions={handleSearchKeystroke}
                  onChange={(e) => wrappedHandleChange('site', e)}
                />
              </Col>

              <Form.Group as={Col} md="4" controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  as={DatePicker}
                  autoComplete="off"
                  filterDate={checkDateBeforeToday}
                  placeholderText={'Select a start date'}
                  dateFormat="yyyy-MM-dd"
                  isInvalid={touched.startDate && !!errors.startDate}
                  isValid={touched.startDate && !errors.startDate}
                  selected={values.startDate}
                  onChange={(e) => wrappedHandleChange('startDate', e)}
                />
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  as={DatePicker}
                  autoComplete="off"
                  filterDate={checkDateBeforeToday}
                  placeholderText="Select an end date"
                  dateFormat="yyyy-MM-dd"
                  isInvalid={touched.endDate && !!errors.endDate}
                  isValid={touched.endDate && !errors.endDate}
                  selected={values.endDate}
                  onChange={(e) => wrappedHandleChange('endDate', e)}
                />
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Button
              data-testid={'submit-data-correction-btn-' + eventKey}
              style={{ margin: '2rem 0', height: '50px', width: '200px' }}
              size={'lg'}
              variant="success"
              type="submit"
            >
              Submit Data Correction
            </Button>

            {values.site && (
              <Button
                data-testid={'backfill-entire-history-btn-' + eventKey}
                style={{ margin: '2rem 1rem', height: '50px', width: '200px' }}
                size={'lg'}
                variant="primary"
                type="button"
                onClick={() => onBackfillEntireHistory(values.site.value)}
              >
                Backfill Entire History
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
