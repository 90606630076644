import { cloneDeep } from 'lodash';
import { Button } from 'react-bootstrap';
import React from 'react';

export default function LargeButton(props) {
  // Clone so we don't mutate props object directly
  const filteredProps = cloneDeep(props);
  // Remove custom props before attaching to DOM element below
  delete filteredProps.isSelected;

  return (
    <Button
      {...filteredProps}
      style={{
        margin: '0.5rem 0.25rem',
        height: '40px',
        width: '100px',
        background: props.isSelected ? 'black' : undefined,
      }}
    >
      {props.children}
    </Button>
  );
}
