import React, { useState } from 'react';

const INITIAL_STATE = {
  searchTerm: '',
};

export default function TableSearch({ onSearch, containerStyle }) {
  const [state, setState] = useState(INITIAL_STATE);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSearch(state.searchTerm);
      }}
      style={{ display: 'grid', gridTemplateColumns: '0.75fr 0.25fr', ...containerStyle }}
    >
      <input
        data-testid="search-input-box"
        type="text"
        className="form-control"
        placeholder={'Search'}
        value={state.searchTerm}
        onChange={(e) => {
          setState({
            searchTerm: e.currentTarget.value,
          });
        }}
      />

      <button style={{ margin: '0 0.5rem' }} className="btn btn-upload" type="submit" data-testid="search-submit-btn">
        Search
      </button>
    </form>
  );
}
