import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { del, get, getTokenAndEmailFromSession, post } from '../../../common/api-utils';
import CircuitsSlidingPane from '../dialogs/CircuitsSlidingPane';
import ModelManufacturerSiteIdentifierFields from './ModelManufacturerSiteIdentifierFields';
import { CircuitData, CommonFieldListProps, MeterFormData, MonitorType } from './site-devices-types';
import { toast } from 'react-toastify';
import { FiRefreshCw } from 'react-icons/fi';
import DatePickerFormControl from '../../../components/DatePickerFormControl';
import { InstalledDevice } from '../../../api/api-device';
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/alert';
import { displayAPIErrorMessage } from '../../../common/utils-helper';

export const EMPTY_METER_TEMPLATE: MeterFormData = {
  meterId: null,
  manufacturer: '',
  model: '',
  siteIdentifier: '',
  monitoringStartDate: '',
};

type MeterFormProps = CommonFieldListProps;

interface State {
  circuits: CircuitData[] | null;
  isCircuitsPaneOpen: boolean;
  monitorTypes: MonitorType[] | null;
}

const INITIAL_STATE = {
  isCircuitsPaneOpen: false,
  circuits: null,
  monitorTypes: null,
};

const MeterForm = (props: MeterFormProps) => {
  const { control, errors, siteId } = props;
  const { fields, append, remove } = useFieldArray({
    name: `meters`,
    control,
  });
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const [state, setState] = useState<State>(INITIAL_STATE);

  useEffect(() => {
    setExpandedIndex(fields.length - 1);
  }, [fields.length]);

  useEffect(() => {
    const fetchMonitorTypes = async () => {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const monitorTypes = await get('assignments', '/site/assignments', jwtToken);
      setState((prevState) => ({
        ...prevState,
        monitorTypes,
      }));
    };

    fetchMonitorTypes();
  }, []);

  const handleToggleCircuitsSlidingPane = async (isOpen: boolean) => {
    setState({
      ...state,
      isCircuitsPaneOpen: isOpen,
    });
  };

  const handleInlineEditCircuit = async (newCircuitData) => {
    const { jwtToken } = await getTokenAndEmailFromSession();

    try {
      await post('circuits', `/site/sites/${siteId}/circuits`, newCircuitData, jwtToken);

      setState({
        ...state,
        circuits: newCircuitData,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  async function handleDeleteMeter(meterId: number, index: number) {
    if (window.confirm('Are you sure you want to delete this meter?')) {
      if (meterId) {
        try {
          const { jwtToken } = await getTokenAndEmailFromSession();
          await del<InstalledDevice>('site-devices', `/site/sites/${siteId}/devices/${meterId}`, jwtToken);
        } catch (e) {
          displayAPIErrorMessage(e);
        }
      }

      remove(index);
    }
  }

  const handleReloadCircuits = async () => {
    const { jwtToken } = await getTokenAndEmailFromSession();
    setState({
      ...state,
      isCircuitsPaneOpen: false,
    });
    const circuits = await get('circuits', '/site/sites/' + siteId + '/circuits', jwtToken);

    setState({
      ...state,
      circuits,
      isCircuitsPaneOpen: true,
    });
  };

  return (
    <Flex direction="column">
      {!!fields.length && (
        <Alert status="warning" variant="subtle" flexDirection="column" alignItems="flex-start" mb={4} p={6}>
          <Flex alignItems="center" my={4}>
            <AlertIcon w={8} h={8} />
            <AlertTitle color="#856404">Note</AlertTitle>
          </Flex>
          <AlertDescription mb={8} color="#856404" lineHeight={8}>
            The &quot;re-sync circuits&quot; button will queue a job to synchronise circuits with data retrieved from
            the third party API (e.g. WW, SA) for this site. Please wait up to 30 seconds after clicking the sync button
            before refreshing the circuits pane.
          </AlertDescription>
          <Flex mb={2}>
            <Button
              mr={6}
              py={10}
              style={{ fontSize: '13px' }}
              colorScheme={'blue'}
              type="button"
              onClick={async () => {
                const { jwtToken } = await getTokenAndEmailFromSession();
                await post('refresh_circuits', `/site/sites/${siteId}/refresh_circuits`, {}, jwtToken);
                toast.success(
                  'Successfully re-synced site circuits. Please wait 30 seconds for the sync job to complete.'
                );
              }}
            >
              Re-sync Circuits <FiRefreshCw style={{ marginLeft: '0.25rem' }} size={18} />
            </Button>

            <Button
              py={10}
              style={{ fontSize: '13px' }}
              colorScheme={'green'}
              type="button"
              onClick={async () => {
                const { jwtToken } = await getTokenAndEmailFromSession();
                const circuits = await get('circuits', '/site/sites/' + siteId + '/circuits', jwtToken);
                setState({ ...state, isCircuitsPaneOpen: true, circuits });
              }}
            >
              Open Monitor Pane
            </Button>
          </Flex>
        </Alert>
      )}
      <Accordion
        defaultIndex={0}
        allowToggle
        index={expandedIndex}
        onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}
      >
        {fields.map((field, index) => (
          <AccordionItem key={`${field.id}-${index}`} index={index}>
            <AccordionButton role={'button'} as={Box} py={4}>
              <Flex w={'100%'} justify={'space-between'} align={'center'}>
                <Heading>Meter {index + 1}</Heading>
                <Flex align={'center'}>
                  <Button
                    type="button"
                    variant={'ghost'}
                    onClick={() => handleDeleteMeter(field.meterId, index)}
                    size={'xs'}
                    style={{ fontSize: '12px' }}
                    mr={2}
                    colorScheme="red"
                    aria-label="Delete meter"
                  >
                    Remove
                  </Button>
                  <AccordionIcon w={8} h={8} mr={3} />
                </Flex>
              </Flex>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <ModelManufacturerSiteIdentifierFields
                {...{ ...props, index, field, deviceType: 'METER', fieldKey: 'meters' }}
              />
              <FormControl isInvalid={!!errors?.['meters']?.[index]?.monitoringStartDate} mt={3}>
                <FormLabel>Monitoring Start Date</FormLabel>
                <DatePickerFormControl
                  maxDate={new Date()}
                  isInvalid={!!errors?.['meters']?.[index]?.monitoringStartDate}
                  control={control}
                  fieldName={`meters.${index}.monitoringStartDate`}
                  onCheckDateValidity={(val: Date) => {
                    if (new Date() < new Date(val)) {
                      toast.error('Monitoring Start Date cannot be in the future', { autoClose: 5000 });
                      return false;
                    }
                    return true;
                  }}
                />
                <FormErrorMessage>{errors?.['meters']?.[index]?.monitoringStartDate?.message}</FormErrorMessage>
              </FormControl>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      {!fields.length && (
        <Alert status="info" my={4} rounded={4}>
          <AlertIcon w={8} h={8} />
          <AlertDescription py={4}>No Meter! Please click &quot;Add meter&quot; to add one.</AlertDescription>
        </Alert>
      )}
      <Button py={8} my={7} w="fit-content" onClick={() => append(EMPTY_METER_TEMPLATE)} type="button">
        Add Meter
      </Button>
      {state.isCircuitsPaneOpen && (
        <CircuitsSlidingPane
          onReloadCircuitData={handleReloadCircuits}
          siteId={siteId}
          circuitsData={state.circuits}
          monitorTypes={state.monitorTypes}
          onRequestClose={() => handleToggleCircuitsSlidingPane(false)}
          setCircuitData={handleInlineEditCircuit}
        />
      )}
    </Flex>
  );
};

export default MeterForm;
