import 'react-datepicker/dist/react-datepicker.css';

import { faPlusCircle, faTimes, IconName } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import DatePicker from 'react-datepicker';
import Loader from 'react-loader';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { getTokenAndEmailFromSession } from '../../../common/api-utils';
import { get } from '../../../common/api-utils';
import siteConfig from '../../../common/site-config';
import TableSearch from '../../../components/table/TableSearch';
import NestedTariffTable from '../../../components/table/tariff/NestedTariffTable';

interface ISelectTariffModalProps {
  tariffDialogData?: {
    selectedRetailer?: { value?: string };
    retailerOptions?: any[];
  };
  siteId?: any;
  expandFetchData?: unknown;
  selectedTariffType?: unknown;
  onClose?: () => void;
  onAssociateNewTariff?: (val) => Promise<void>;
}
export default class SelectTariffModal extends Component<ISelectTariffModalProps> {
  state = {
    isLoaded: false,
    limit: 10,
    offset: 0,
    data: [],
    expandedRows: [],
    page: 1,
    totalSize: 0,
    searchTerm: null,
    selectedRetailer: null,
    selectedTariff: null,
    expandedRowId: null,
    effectiveDate: null,
    sortOrder: 'asc',
    siteCircuits: [],
    orderBy: '',
    sortType: '',
    selectedColumn: '',
    selectedTariffType: '',
    expandFetchData: null,
  };

  bootstrapTableRef = React.createRef();

  postNewTariffAssociation = async (token, body) => {
    return API.post('site_tariffs', `/site/sites/${this.props.siteId}/tariffs`, {
      body,
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  };

  /**
   * Promisified state setter for access to post-state mutation logic without this yucky boilerplate everywhere.
   *
   * @param newState
   * @returns {Promise<*>}
   */
  setAsyncState = (newState) => this.setState(newState);

  /* setAsyncState = (newState) => new Promise((resolve) => this.setState(newState, resolve)); */

  async componentDidMount() {
    const { jwtToken: token } = await getTokenAndEmailFromSession();
    const selectedRetailer = this.props.tariffDialogData.selectedRetailer;
    const { data, item_count: totalSize } = await this.fetchTariffsByRetailer(token, selectedRetailer?.value);

    // Get circuits for this site
    const siteId = this.props.siteId;

    const siteCircuits = await get('circuits', `/site/sites/${siteId}/circuits`, token);

    this.setState({
      ...this.state,
      siteCircuits,
      isLoaded: true,
      selectedRetailer,
      totalSize,
      data,
    });
  }

  async fetchTariffsByRetailer(
    token?,
    retailerId?,
    offset = this.state.offset,
    orderBy = this.state.orderBy,
    sortOrder = this.state.sortOrder,
    searchValue?
  ) {

    return API.get(
      'tariffs',
      '/tariff/tariffs' +
        `?limit=${this.state.limit}` +
        `&offset=${offset}` +
        `&ordering=${orderBy || 'tariff_id'}` +
        (retailerId ? `&retailer_id=${retailerId}` : '' )+
        (searchValue ?? this.state.searchTerm ? `&search_term=${searchValue ?? this.state.searchTerm}` : ``),
      { headers: { 'Content-Type': 'application/json', Authorization: token } }
    );
  }

  handleAddTariffToSite = async () => {
    const { jwtToken: token } = await getTokenAndEmailFromSession();
    const { selectedTariff, effectiveDate, siteCircuits } = this.state;

    if (!effectiveDate) {
      toast.error('Please ensure an effective date is set before saving a tariff to a site!', {
        autoClose: 5000,
      });
      return;
    }

    const siteHasControlledLoad = siteCircuits.find((circuit) => circuit.controlled_load_yn === 'Y');
    const siteHasControlledLoad2 = siteCircuits.find((circuit) => circuit.controlled_load2_yn === 'Y');

    if (siteHasControlledLoad && selectedTariff.controlled_load_yn === 'N') {
      toast.error('Sites with a controlled load must be associated with a controlled load tariff!', {
        autoClose: 5000,
      });
      return;
    }

    if (siteHasControlledLoad2 && selectedTariff.controlled_load2_yn === 'N') {
      toast.error('Sites with a controlled load 2 must be associated with a controlled load 2 tariff!', {
        autoClose: 5000,
      });
      return;
    }

    const body = {
      tariff_id: selectedTariff.tariff_id,
      tariff_effective_date: effectiveDate,
      site_id: this.props.siteId,
    };

    // Post to site ID
    const newTariff = await this.postNewTariffAssociation(token, body);

    toast.success(`Successfully added new site tariff`, {
      autoClose: 5000,
    });

    this.props.onAssociateNewTariff(newTariff);
  };

  handleSelectRetailer = async (newRetailer) => {
    const { value: retailerId } = newRetailer;
    await this.setAsyncState({ ...this.state, isLoaded: false });
    const { jwtToken: token } = await getTokenAndEmailFromSession();
    const { data, item_count: totalSize } = await this.fetchTariffsByRetailer(token, retailerId);

    this.setState({
      ...this.state,
      isLoaded: true,
      offset: 0,
      page: 1,
      data,
      totalSize,
      selectedRetailer: newRetailer,
    });
  };

  handleOnSelect = (selectedTariff) => {
    this.setState({
      ...this.state,
      selectedTariff,
    });
  };

  handleDateChange = (effectiveDate) => {
    this.setState({ ...this.state, effectiveDate: effectiveDate });
  };

  fetchTariffDetails = async (tariff) => {
    const { jwtToken: token } = await getTokenAndEmailFromSession();
    const type = tariff.tariff_type;

    const typeToEndPointName = {
      tou: 'tou_rates',
      flat: 'flat_rates',
      tiered: 'tiered_rates',
    };

    const endPoint = typeToEndPointName[type.toLowerCase()];
    const rates = await API.get('tariff_rates', `/tariff/tariffs/${tariff.tariff_id}/${endPoint}`, {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });

    // @TODO: fetch data format changed -- modify in NestedTariffTable
    this.setState({ expandFetchData: rates, selectedTariffType: type });
  };

  handleTableChange = async (type, { page, sizePerPage, sortField }) => {
    const { jwtToken: token } = await getTokenAndEmailFromSession();
    const limit = sizePerPage;

    await this.setAsyncState({
      ...this.state,
      limit,
      offset: limit * (page - 1),
      page,
      sortField,
    });

    const { data } = await this.fetchTariffsByRetailer(token, this.state.selectedRetailer.value);

    this.setState({
      ...this.state,
      data,
    });
  };

  sortFormatter = (order, column) => {
    const { sortType, selectedColumn } = this.state;
    let iconClass = 'sort';

    if (selectedColumn === column.dataField) {
      iconClass = sortType === 'asc' ? 'sort-up' : 'sort-down';
    }

    return (
      <>
        <span>&nbsp;&nbsp;</span>
        <FontAwesomeIcon icon={iconClass as IconName} />
      </>
    );
  };

  handleTableSearch = async (searchTerm) => {
    const { jwtToken } = await getTokenAndEmailFromSession();
    const args = [
      jwtToken,
      this.props.tariffDialogData.selectedRetailer?.value,
      0,
      this.state.orderBy,
      this.state.sortOrder,
    ];

    if (searchTerm || searchTerm === '') {
      args.push(searchTerm);
    }

    const { data, item_count: totalSize } = await this.fetchTariffsByRetailer(...args);

    this.setState({
      ...this.state,
      data,
      page: 1,
      totalSize,
      offset: 0,
      searchTerm: searchTerm || null,
    });
  };

  handleExpandRow = () => {
    return <NestedTariffTable expandData={this.state.expandFetchData} expandType={this.state.selectedTariffType} />;
  };

  render() {
    const { selectedTariff } = this.state;
    const columns = siteConfig.site.tariff.tableConfig.columns;

    /* columns.forEach((column) => {
      if (column.sort) {
        column.sortCaret = this.sortFormatter;
      }
    }); */

    const { page, limit, totalSize } = this.state;
    const { tariffDialogData } = this.props;
    const selectStyles = { menu: (styles) => ({ ...styles, zIndex: 999 }) };

    return (
      <div className="container select-tariff-container">
        <div className="modal show" id="associateTariffModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" data-testid="associate-tariff-title">
                  Associate New Tariff
                </h4>
                <button type="button" className="close" onClick={this.props.onClose}>
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div
                  style={{
                    padding: '2rem',
                    display: 'grid',
                    gridColumnGap: '0.5rem',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    width: '50%',
                  }}
                >
                  <div className="retailer-selection-box" data-testid="select-retailer">
                    <label className="label-zindex">Energy Retailer</label>
                    <Select
                      className="custom-select-new"
                      styles={selectStyles}
                      options={tariffDialogData.retailerOptions}
                      placeholder="Select energy retailers..."
                      value={this.state.selectedRetailer}
                      isSearchable
                      onChange={this.handleSelectRetailer}
                    />
                  </div>

                  <div className="retailer-selection-box" data-testid="select-date">
                    <label className="label-zindex">Set Effective Date</label>
                    <DatePicker
                      className="custom_datepicker"
                      placeholder="Select effective date"
                      dateFormat="dd-MM-yyyy"
                      autoComplete="off"
                      selected={this.state.effectiveDate}
                      onChange={this.handleDateChange}
                    />
                  </div>
                </div>

                <TableSearch
                  onSearch={this.handleTableSearch}
                  containerStyle={{ width: '25%', margin: '1rem 0', marginLeft: '2rem' }}
                />

                <div className="col-sm-12 col-md-12 col-lg-12 tariff-table">
                  <PaginationProvider
                    pagination={paginationFactory({
                      page,
                      sizePerPage: limit,
                      totalSize,
                      firstPageText: 'First',
                      prePageText: 'Back',
                      nextPageText: 'Next',
                      lastPageText: 'Last',
                      nextPageTitle: 'First page',
                      prePageTitle: 'Pre page',
                      firstPageTitle: 'Next page',
                      lastPageTitle: 'Last page',
                      showTotal: true,
                    })}
                  >
                    {({ paginationTableProps }) => (
                      <div>
                        <ToolkitProvider keyField="tariff_id" columns={columns} data={this.state.data}>
                          {(props) => (
                            <div>
                              <BootstrapTable
                                remote
                                onTableChange={this.handleTableChange}
                                ref={this.bootstrapTableRef}
                                {...props.baseProps}
                                {...paginationTableProps}
                                rowClasses={(_, idx) => `table-row-${idx}`}
                                selectRow={{
                                  mode: 'radio',
                                  clickToSelect: true,
                                  selected: [selectedTariff?.tariff_id],
                                  onSelect: this.handleOnSelect,
                                  bgColor: '#3dcd58',
                                }}
                                expandRow={{
                                  renderer: () => this.handleExpandRow(),
                                  showExpandColumn: true,
                                  expandByColumnOnly: true,
                                  onlyOneExpanding: true,
                                  expanded: this.state.expandedRows,
                                  onExpand: async (tariff, isExpanded) => {
                                    const expandedRows = [];

                                    if (isExpanded) {
                                      expandedRows.push(tariff.tariff_id);
                                      await this.fetchTariffDetails(tariff);
                                    }

                                    this.setState({ expandedRows });
                                  },
                                }}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </PaginationProvider>

                  <div className="btn-wrapper text-right mt-3">
                    <button className="btn btn-upload" onClick={this.props.onClose}>
                      <FontAwesomeIcon icon={faTimes} className="btn-img-pos" />
                      Cancel
                    </button>
                    {this.state.selectedTariff && (
                      <button className="btn btn-upload ml-3" type="button" onClick={this.handleAddTariffToSite}>
                        <FontAwesomeIcon icon={faPlusCircle} className="btn-img-pos" data-testid="add-tariff-to-site" />
                        Add new Tariff to Site
                      </button>
                    )}
                  </div>
                  <Loader loaded={this.state.isLoaded} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
