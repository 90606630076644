import React from 'react';

import Sidebar from './Sidebar';
import Header from './TopNav';

export default function ContentWrapper(props) {
  let breadcrumbs = (
    <li className="breadcrumb-item active" aria-current="page">
      {props.title}
    </li>
  );

  if (props?.breadcrumbs) {
    breadcrumbs = props.breadcrumbs.map((breadcrumb, i) => {
      return (
        <li
          key={breadcrumb.title + breadcrumb.url + i.toString()}
          className="breadcrumb-item active"
          aria-current="page"
        >
          {i !== props.breadcrumbs.length - 1 ? <a href={breadcrumb.url}>{breadcrumb.title}</a> : breadcrumb.title}
        </li>
      );
    });
  }

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div role="main" className="content-panel">
            <div className="content-section">
              <div className="content-head">
                <h1 data-testid="page-title">{props.title}</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-white p-0">
                    <li className="breadcrumb-item">
                      <a href="#/sites">Clipsal</a>
                    </li>
                    {breadcrumbs}
                  </ol>
                </nav>
              </div>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
