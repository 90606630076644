import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { get, getTokenAndEmailFromSession, post } from '../../../common/api-utils';
import { mapArrayToSelectFormat } from '../../../common/array-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import ContentWrapper from '../../../components/ContentWrapper';
import DefaultTariffForm from './DefaultTariffForm';

const INITIAL_STATE = {
  isLoaded: false,
  distributorOptions: [],
};
export default function AddDefaultTariff({ history }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { jwtToken } = await getTokenAndEmailFromSession();
        const distributors = await get('distributors', '/tariff/distributors', jwtToken);
        const distributorOptions = distributors.map(mapArrayToSelectFormat('distributor_name', 'id'));

        setState({
          ...state,
          isLoaded: true,
          distributorOptions,
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }

    if (!state.isLoaded) {
      fetchAPI();
    }
  }, [state.isLoaded]);

  async function handleAddDefaultTariff(values, actions) {
    try {
      actions.setSubmitting(true);
      const { jwtToken, email } = await getTokenAndEmailFromSession();
      const defaultTariffToSave = {
        state: values.state,
        distributor_id: values.distributor.value,
        year: values.year,
        tariff_id: values.tariff_id,
        created_by: email,
      };

      await post('tariff', `/tariff/default_tariffs`, defaultTariffToSave, jwtToken);
      toast.success('Successfully added Default Tariff');
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      displayAPIErrorMessage(e);
    }
  }

  return (
    <ContentWrapper
      breadcrumbs={[{ title: 'Default Tariff', url: '#/defaulttariffs' }, { title: 'Add Default Tariff' }]}
      title={'Add Default Tariff'}
    >
      <DefaultTariffForm
        distributorOptions={state.distributorOptions}
        history={history}
        onSubmit={handleAddDefaultTariff}
      />
    </ContentWrapper>
  );
}
