import React from 'react';
import { createIcon } from '@chakra-ui/react';

const EMPTY_PATH_COLOR = '#C2C6C7';

export const createCustomWifiIcon = (signalLevel = 0, signalIconColor: string) => {
  return createIcon({
    displayName: 'WifiIcon',
    path: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.094 18.0009L12.0001 20.2977L9.92163 18.0009C10.1706 17.6867 10.4874 17.4329 10.8483 17.2584C11.2092 17.084 11.6048 16.9933 12.0057 16.9933C12.4065 16.9933 12.8022 17.084 13.1631 17.2584C13.524 17.4329 13.8408 17.6867 14.0898 18.0009H14.094Z"
          fill={signalLevel > 0 ? signalIconColor : EMPTY_PATH_COLOR}
        />
        <path
          d="M15.5949 15.0005C14.6081 14.1234 13.3338 13.639 12.0136 13.639C10.6934 13.639 9.41912 14.1234 8.43237 15.0005"
          stroke={signalLevel > 1 ? signalIconColor : EMPTY_PATH_COLOR}
          strokeWidth="2.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M18.4565 12.1408C16.7088 10.5067 14.4056 9.5976 12.013 9.5976C9.62041 9.5976 7.31719 10.5067 5.56958 12.1408"
          stroke={signalLevel > 2 ? signalIconColor : EMPTY_PATH_COLOR}
          strokeWidth="2.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M21.0001 8.97834C18.5267 6.77181 15.3279 5.55237 12.0132 5.55237C8.69861 5.55237 5.49982 6.77181 3.02637 8.97834"
          stroke={signalLevel > 3 ? signalIconColor : EMPTY_PATH_COLOR}
          strokeWidth="2.3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  });
};

export const createCustomCellularIcon = (signalLevel = 0, signalIconColor: string) => {
  return createIcon({
    displayName: 'CellularIcon',
    path: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M22.125 20.2491H19.875C19.5766 20.2491 19.2905 20.1306 19.0795 19.9196C18.8685 19.7087 18.75 19.4225 18.75 19.1241V4.87415C18.75 4.57578 18.8685 4.28963 19.0795 4.07865C19.2905 3.86767 19.5766 3.74915 19.875 3.74915H22.125C22.4234 3.74915 22.7095 3.86767 22.9205 4.07865C23.1315 4.28963 23.25 4.57578 23.25 4.87415V19.1241C23.25 19.4225 23.1315 19.7087 22.9205 19.9196C22.7095 20.1306 22.4234 20.2491 22.125 20.2491Z"
            fill={signalLevel > 3 ? signalIconColor : EMPTY_PATH_COLOR}
          />
          <path
            d="M16.125 20.2494H13.875C13.5766 20.2494 13.2905 20.1309 13.0795 19.9199C12.8685 19.709 12.75 19.4228 12.75 19.1244V8.62445C12.75 8.32608 12.8685 8.03993 13.0795 7.82896C13.2905 7.61798 13.5766 7.49945 13.875 7.49945H16.125C16.4234 7.49945 16.7095 7.61798 16.9205 7.82896C17.1315 8.03993 17.25 8.32608 17.25 8.62445V19.1244C17.25 19.4228 17.1315 19.709 16.9205 19.9199C16.7095 20.1309 16.4234 20.2494 16.125 20.2494Z"
            fill={signalLevel > 2 ? signalIconColor : EMPTY_PATH_COLOR}
          />
          <path
            d="M10.125 20.2491H7.875C7.57663 20.2491 7.29048 20.1306 7.0795 19.9196C6.86853 19.7087 6.75 19.4225 6.75 19.1241V11.6241C6.75 11.3258 6.86853 11.0396 7.0795 10.8287C7.29048 10.6177 7.57663 10.4991 7.875 10.4991H10.125C10.4234 10.4991 10.7095 10.6177 10.9205 10.8287C11.1315 11.0396 11.25 11.3258 11.25 11.6241V19.1241C11.25 19.4225 11.1315 19.7087 10.9205 19.9196C10.7095 20.1306 10.4234 20.2491 10.125 20.2491Z"
            fill={signalLevel > 1 ? signalIconColor : EMPTY_PATH_COLOR}
          />
          <path
            d="M4.125 20.2499H1.875C1.57663 20.2499 1.29048 20.1313 1.0795 19.9204C0.868526 19.7094 0.75 19.4232 0.75 19.1249V14.6249C0.75 14.3265 0.868526 14.0404 1.0795 13.8294C1.29048 13.6184 1.57663 13.4999 1.875 13.4999H4.125C4.42337 13.4999 4.70952 13.6184 4.92049 13.8294C5.13147 14.0404 5.25 14.3265 5.25 14.6249V19.1249C5.25 19.4232 5.13147 19.7094 4.92049 19.9204C4.70952 20.1313 4.42337 20.2499 4.125 20.2499Z"
            fill={signalLevel > 0 ? signalIconColor : EMPTY_PATH_COLOR}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    viewBox: '0 0 24 24',
  });
};

export const PolarityNormalIcon = createIcon({
  displayName: 'PolarityNormalIcon',
  path: (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23499 18C3.41221 18 3.55587 17.8672 3.55587 17.7033V13.3983H5.37367C5.55089 13.3983 5.69455 13.2654 5.69455 13.1016C5.69455 12.9377 5.55089 12.8049 5.37367 12.8049H3.55587L3.55587 9.39674H5.37367C5.55089 9.39674 5.69455 9.2639 5.69455 9.10004C5.69455 8.93617 5.55089 8.80334 5.37367 8.80334H3.55587L3.55587 5.39523H5.37367C5.55089 5.39523 5.69455 5.26239 5.69455 5.09852C5.69455 4.93466 5.55089 4.80182 5.37367 4.80182H3.55587V0.296703C3.55587 0.132838 3.41221 -1.43256e-08 3.23499 0C3.05777 1.43255e-08 2.91411 0.132838 2.91411 0.296703V4.80182H1.73792C1.68153 4.80182 1.62614 4.81556 1.57732 4.84166C1.03452 5.13182 0.354923 5.70944 0.152875 6.5118C-0.0403812 7.27924 0.222902 8.1627 1.24353 9.08292C0.743376 9.47194 0.408662 9.86602 0.213197 10.2599C-0.0217545 10.7334 -0.0474224 11.191 0.0628786 11.6067C0.278612 12.4197 1.00079 13.0349 1.57141 13.3552C1.62161 13.3834 1.6792 13.3983 1.73792 13.3983H2.91411V17.7033C2.91411 17.8672 3.05778 18 3.23499 18ZM2.91411 12.8049H1.8302C1.35133 12.5179 0.836866 12.0333 0.686155 11.4653C0.609322 11.1757 0.623754 10.8555 0.796487 10.5074C0.963426 10.171 1.28528 9.795 1.84545 9.39674H2.91411V12.8049ZM2.91411 8.80334H1.85932C0.799468 7.92147 0.642386 7.18457 0.777916 6.64637C0.917511 6.09202 1.39025 5.64734 1.82687 5.39523H2.91411V8.80334Z"
        fill="#2B2E2D"
      />
      <path
        d="M8.95793 4.72045C9.53589 4.6049 9.96893 4.12943 9.96893 3.56044C9.96893 2.90498 9.39428 2.37363 8.68541 2.37363C7.97654 2.37363 7.40189 2.90498 7.40189 3.56044C7.40189 4.09722 7.78728 4.55076 8.31617 4.69741L8.31617 15.1079L6.82188 13.7262C6.69657 13.6103 6.4934 13.6103 6.36808 13.7262C6.24277 13.842 6.24277 14.0299 6.36808 14.1458L8.41015 16.034C8.53547 16.1498 8.73864 16.1498 8.86395 16.034L10.906 14.1458C11.0313 14.0299 11.0313 13.842 10.906 13.7262C10.7807 13.6103 10.5775 13.6103 10.4522 13.7262L8.95793 15.1079V4.72045Z"
        fill="#2B2E2D"
      />
      <path
        d="M10.0834 3.60002C10.0834 4.3456 9.46776 4.95002 8.70837 4.95002C7.94898 4.95002 7.33337 4.3456 7.33337 3.60002C7.33337 2.85443 7.94898 2.25002 8.70837 2.25002C9.46776 2.25002 10.0834 2.85443 10.0834 3.60002Z"
        fill="#2B2E2D"
      />
    </svg>
  ),
  viewBox: '0 0 11 18',
});

export const PolarityReverseIcon = createIcon({
  displayName: 'PolarityReverseIcon',
  path: (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.765 2.82812e-07C7.58779 2.98305e-07 7.44412 0.132838 7.44412 0.296702L7.44412 4.60174L5.62633 4.60174C5.44911 4.60174 5.30545 4.73458 5.30545 4.89844C5.30545 5.06231 5.44911 5.19515 5.62633 5.19515L7.44412 5.19515L7.44413 8.60326L5.62633 8.60326C5.44911 8.60326 5.30545 8.7361 5.30545 8.89996C5.30545 9.06383 5.44911 9.19667 5.62633 9.19667L7.44413 9.19667L7.44413 12.6048L5.62633 12.6048C5.44911 12.6048 5.30545 12.7376 5.30545 12.9015C5.30545 13.0653 5.44911 13.1982 5.62633 13.1982L7.44413 13.1982L7.44413 17.7033C7.44413 17.8672 7.58779 18 7.76501 18C7.94223 18 8.08589 17.8672 8.08589 17.7033L8.08589 13.1982L9.26208 13.1982C9.31847 13.1982 9.37386 13.1844 9.42268 13.1583C9.96548 12.8682 10.6451 12.2906 10.8471 11.4882C11.0404 10.7208 10.7771 9.8373 9.75647 8.91708C10.2566 8.52806 10.5913 8.13398 10.7868 7.74009C11.0218 7.26662 11.0474 6.80904 10.9371 6.39334C10.7214 5.58029 9.99921 4.96511 9.42859 4.64482C9.37839 4.61664 9.3208 4.60174 9.26208 4.60174L8.08589 4.60174L8.08588 0.296702C8.08588 0.132838 7.94222 2.67319e-07 7.765 2.82812e-07ZM8.08589 5.19515L9.1698 5.19515C9.64867 5.4821 10.1631 5.96674 10.3138 6.53474C10.3907 6.8243 10.3762 7.14452 10.2035 7.49261C10.0366 7.82903 9.71472 8.205 9.15455 8.60326L8.08589 8.60326L8.08589 5.19515ZM8.08589 9.19667L9.14068 9.19666C10.2005 10.0785 10.3576 10.8154 10.2221 11.3536C10.0825 11.908 9.60975 12.3527 9.17313 12.6048L8.08589 12.6048L8.08589 9.19667Z"
        fill="#2B2E2D"
      />
      <path
        d="M2.04207 13.2796C1.46411 13.3951 1.03107 13.8706 1.03107 14.4396C1.03107 15.095 1.60572 15.6264 2.31459 15.6264C3.02346 15.6264 3.59811 15.095 3.59811 14.4396C3.59811 13.9028 3.21272 13.4492 2.68383 13.3026L2.68383 2.89213L4.17812 4.27383C4.30343 4.3897 4.5066 4.3897 4.63191 4.27383C4.75723 4.15796 4.75723 3.9701 4.63191 3.85423L2.58985 1.96602C2.46453 1.85015 2.26136 1.85015 2.13605 1.96602L0.0939824 3.85423C-0.0313294 3.9701 -0.0313294 4.15796 0.0939825 4.27383C0.219294 4.3897 0.422464 4.3897 0.547776 4.27383L2.04207 2.89213L2.04207 13.2796Z"
        fill="#2B2E2D"
      />
      <path
        d="M0.916632 14.4C0.916632 13.6544 1.53224 13.05 2.29163 13.05C3.05102 13.05 3.66663 13.6544 3.66663 14.4C3.66663 15.1456 3.05102 15.75 2.29163 15.75C1.53224 15.75 0.916632 15.1456 0.916632 14.4Z"
        fill="#2B2E2D"
      />
    </svg>
  ),
  viewBox: '0 0 11 18',
});
