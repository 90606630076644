import { Box } from '@chakra-ui/react';
import { ButtonGroup } from 'react-bootstrap';
import React from 'react';
import LargeButton from './LargeButton';
import { IntervalType } from './MeterConfigurationChartContainer';

type IntervalTypeButtonGroupProps = {
  selectedInterval: IntervalType;
  onSetIntervalType: (intervalType: IntervalType) => void;
};

export default function IntervalTypeButtonGroup({ selectedInterval, onSetIntervalType }: IntervalTypeButtonGroupProps) {
  return (
    <Box>
      <ButtonGroup aria-label="Interval type">
        <LargeButton
          data-testid="short-energy-btn"
          onClick={() => onSetIntervalType('short_energy')}
          isSelected={selectedInterval === 'short_energy'}
          variant="secondary"
        >
          Short Energy
        </LargeButton>
        <LargeButton
          data-testid="long-energy-btn"
          onClick={() => onSetIntervalType('long_energy')}
          isSelected={selectedInterval === 'long_energy'}
          variant="secondary"
        >
          Long Energy
        </LargeButton>
      </ButtonGroup>
    </Box>
  );
}
