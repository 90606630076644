import { API, Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { SortOrder } from 'react-bootstrap-table-next';
import { PaginatedResponse } from './types/helper-types';

/**
 * Checks the current session, retrieving the ID token and decoding the user's email from it.
 *
 * @TODO: check cached session in local storage, return that if still valid, else refresh token normally.
 *
 * @returns {Promise<{jwtToken: string, email: *}>}
 */
export async function getTokenAndEmailFromSession() {
  const session = await Auth.currentSession();
  const jwtToken = session.getIdToken().getJwtToken();
  const decodedToken = jwt_decode(jwtToken);
  return { jwtToken, email: (decodedToken as any).email };
}

/**
 * Performs a request, via a provided request method. Used internally.
 *
 * @param requestMethod
 * @param name
 * @param url
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
async function sendWriteRequest(requestMethod, name, url, body, token) {
  let headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers = { ...headers, Authorization: token };
  }

  return API[requestMethod](name, url, {
    headers,
    body,
  });
}

export async function del<T = any>(name, url, token): Promise<T> {
  return API.del(name, url, {
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });
}

export async function get<T = any>(name, url, token): Promise<T> {
  return API.get(name, url, {
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });
}

export async function post<T = any>(name, url, body, token): Promise<T> {
  return sendWriteRequest('post', name, url, body, token);
}

export async function patch<T = any>(name, url, body, token): Promise<T> {
  return sendWriteRequest('patch', name, url, body, token);
}

export async function put<T = any>(name, url, body, token): Promise<T> {
  return sendWriteRequest('put', name, url, body, token);
}

interface FetchPaginatedAPIContent {
  apiName: string;
  collection: string;
  endPoint: string;
  offset: number;
  sizePerPage: number;
  orderBy: string;
  sortOrder?: SortOrder;
  searchTerm?: string;
  activeOnly?: boolean;
}

export async function fetchPaginatedAPIContent<T>({
  apiName,
  collection,
  endPoint,
  offset,
  sizePerPage,
  orderBy,
  sortOrder,
  searchTerm,
  activeOnly,
}: FetchPaginatedAPIContent): Promise<PaginatedResponse<T>> {
  const { jwtToken } = await getTokenAndEmailFromSession();
  if (sortOrder === 'desc') orderBy = `-${orderBy}`;
  if (orderBy?.includes('.')) orderBy = orderBy.replace('.', '__');
  let uri = `/${collection}/${endPoint}`;
  uri += `?limit=${sizePerPage}&offset=${offset}&ordering=${orderBy}`;
  if (searchTerm) uri += `&search_term=${searchTerm}`;
  if (endPoint === 'articles' && activeOnly) uri += `&is_active=1`;
  return get(apiName, uri, jwtToken);
}
