import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';

import ContentWrapper from '../../components/ContentWrapper';
import SiteDeviceContextProvider from '../../context/SiteDeviceContext';
import SiteBasicInformation from './SiteBasicInformation';
import SiteDeviceInformation from './devices/SiteDeviceInformation';
import SiteInvestmentInformation from './SiteInvestmentInformation';
import SiteUserInformation from './SiteUserInformation';

const INITIAL_STATE = { tabIndex: 0, isEditing: false, siteId: null, loaded: false };

const TAB_CONFIG = ['Basic Information', 'Investments', 'Associate Devices', 'Associate Users'];

const selectedTabColorConfig = { color: 'white !important', bg: '#6bc966', boxShadow: 'none' };

const defaultTabStyleConfig = {
  fontSize: '1.6rem',
  padding: '1rem 3.5rem',
  borderRadius: '.5rem .5rem 0rem 0rem',
  color: '#626469',
};

/* ChakraUI margin-right is not working due to some CSS conflicts, So had to add this */
const customMarginRight = {
  marginRight: '7px',
};

const CustomTab = ({ title, isEditing, dataTestId }) => {
  const ChakraTab = (
    <Tab
      _selected={selectedTabColorConfig}
      {...defaultTabStyleConfig}
      style={customMarginRight}
      data-testid={dataTestId}
    >
      {title}
    </Tab>
  );
  return <>{isEditing ? ChakraTab : title === 'Basic Information' && ChakraTab}</>;
};

const tabTypeToTabIndex = {
  device: 2,
  user: 3,
};

const Site = (props) => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    const siteId = parseInt(props.match.params.id);
    const type = props.match.params.type;

    setState((prevState) => ({
      ...prevState,
      loaded: true,
      siteId,
      isEditing: !!siteId,
      tabIndex: tabTypeToTabIndex[type] ?? 0,
    }));
  }, []);

  const handleCancel = () => {
    props.history.goBack();
  };

  const handleTabsChange = (index) => {
    setState((prevState) => ({ ...prevState, tabIndex: index }));
  };

  const commonTabProps = {
    isParentLoaded: state.loaded,
    siteId: state.siteId,
  };

  return (
    <ContentWrapper
      breadcrumbs={[{ title: 'Sites', url: '#/sites' }, { title: state.isEditing ? 'Edit Site' : 'Add Site' }]}
      title={state.isEditing ? `Edit Site` : 'Add Site'}
    >
      <Tabs index={state.tabIndex} onChange={handleTabsChange} variant="enclosed-colored" isLazy>
        <TabList mb={8} mt={8}>
          {TAB_CONFIG.map((title, index) => (
            <CustomTab
              dataTestId={`${title.replace(/\s+/g, '-').toLowerCase()}-tab`}
              isEditing={state.isEditing}
              key={index}
              title={title}
            />
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <SiteBasicInformation
              handleCancel={handleCancel}
              history={props.history}
              isEditing={state.isEditing}
              {...commonTabProps}
            />
          </TabPanel>
          <TabPanel>
            <SiteInvestmentInformation handleCancel={handleCancel} {...commonTabProps} />
          </TabPanel>
          <TabPanel>
            <SiteDeviceContextProvider>
              <SiteDeviceInformation {...commonTabProps} />
            </SiteDeviceContextProvider>
          </TabPanel>
          <TabPanel>
            <SiteUserInformation handleCancel={handleCancel} {...commonTabProps} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Loader loaded={state.loaded} />
    </ContentWrapper>
  );
};

export default Site;
