import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { del, getTokenAndEmailFromSession, patch, post } from '../../../common/api-utils';
import { convertHourTo12HourTime } from '../../../common/date-formatting';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import { getAppliedRateWidget } from '../../../components/table/tariff/NestedTOUTariffTable';
import TOURateSlidingPane from '../TOURateSlidingPane';

export default function EditableTOURateAssignmentTable({
  season,
  allRates,
  onUpdateRates,
  onUpdateSeason,
  hasUnsavedRateChanges,
  setHasUnsavedRateChanges,
}) {
  const [state, setState] = useState({
    selectedRate: allRates[0],
    isEditRateSlidingPaneOpen: false,
    isMouseDown: false,
  });

  function handleCloseSlidingPane(selectedRate = null) {
    setState({
      ...state,
      isEditRateSlidingPaneOpen: false,
      selectedRate,
    });
  }

  if (!season) {
    return <div>No season selected!</div>;
  }

  function updateAppliedRate(day, rateColumnIndex) {
    const { id } = state.selectedRate;
    // Deep clone our season object to avoid duplicate object references in memory.
    // This also serves to prevent direct state object mutation.
    const newSeason = cloneDeep(season);

    newSeason.applied_rates[day][rateColumnIndex] = id;

    onUpdateSeason(newSeason);
  }

  async function handleSubmitRateForm(values) {
    const { selectedRate } = state;

    try {
      const { jwtToken: token, email } = await getTokenAndEmailFromSession();

      let newRate = {
        name: '',
        import_rate_cents_per_kwh: values.import,
        export_rate_cents_per_kwh: values.export,
        created_by: email,
        id: null,
      };

      if (selectedRate) {
        newRate = {
          ...newRate,
          id: selectedRate.id,
        };

        const rateModel = await patch('rate', `/tariff/rates/${newRate.id}`, newRate, token);
        const newRates = [
          ...allRates.filter((seasonRate) => seasonRate.id !== newRate.id),
          {
            import_rate: newRate.import_rate_cents_per_kwh,
            export_rate: newRate.export_rate_cents_per_kwh,
            id: rateModel.id,
          },
        ];

        onUpdateRates(newRates);
        handleCloseSlidingPane(selectedRate);
      } else {
        delete newRate.id;
        const rateModel = await post('rates', `/tariff/rates`, newRate, token);
        const newSelectedRate = {
          import_rate: newRate.import_rate_cents_per_kwh,
          export_rate: newRate.export_rate_cents_per_kwh,
          id: rateModel.id,
        };
        setHasUnsavedRateChanges(false);
        // Add newly created rate to rate array
        onUpdateRates([...allRates, newSelectedRate]);

        handleCloseSlidingPane(newSelectedRate);
      }
      toast.success('👍 Sucessfully updated the rate!', {
        autoClose: 5000,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  async function handleDeleteRate(rate) {
    const isConfirmed = window.confirm('Are you sure you want to delete this rate?');
    const { jwtToken: token } = await getTokenAndEmailFromSession();

    if (isConfirmed) {
      await del('rate', `/tariff/rates/${rate.id}`, token);
      onUpdateRates(season.rates.filter((seasonRate) => seasonRate.id !== rate.id));
      toast.success('👍 Sucessfully deleted the rate!', {
        autoClose: 5000,
      });
    }
  }

  function handleClearAppliedRates() {
    onUpdateSeason({
      ...season,
      /* applied_rates: EMPTY_SEASON_TEMPLATE.applied_rates, */
    });
  }

  function handleFillAppliedRates() {
    const { selectedRate } = state;

    if (!selectedRate) {
      toast.error('Ensure you add or select a rate before filling the current season rates table.');
      return;
    }

    const fill = () => Array.from(Array(24)).map(() => selectedRate.id);
    const filledAppliedRates = {
      MON: fill(),
      TUE: fill(),
      WED: fill(),
      THU: fill(),
      FRI: fill(),
      SAT: fill(),
      SUN: fill(),
    };

    onUpdateSeason({
      ...season,
      applied_rates: filledAppliedRates,
    });
  }

  return (
    <div>
      <div style={{ margin: '1rem 0' }}>
        <table style={{ cursor: 'pointer' }} className="table">
          <thead>
            <tr>
              <td />
              {Array.from(Array(24)).map((_, i) => (
                <td style={{ fontSize: '10px' }} key={`time-value-${i}`}>
                  {convertHourTo12HourTime(i)}
                </td>
              ))}
            </tr>
          </thead>

          <tbody
            onMouseUp={(e) => {
              e.preventDefault();
              setState({ ...state, isMouseDown: false });
            }}
            onMouseLeave={(e) => {
              e.preventDefault();
              setState({ ...state, isMouseDown: false });
            }}
          >
            {Object.entries(season.applied_rates).map(([day, rateIndexes], rateRowIndex) => {
              return (
                <tr key={`applied-rate-row-${rateRowIndex}`}>
                  <td style={{ fontSize: '10px' }}>{day}</td>

                  {/* Each rateId matches to the ID of an applied rate object on the TOU response object. */}
                  {(rateIndexes as any).map((rateId, rateIndex) => (
                    <td
                      data-testid={`tou-season-${rateRowIndex}-${rateIndex}`}
                      onMouseDown={(e) => {
                        e.preventDefault();

                        if (!state.selectedRate) {
                          toast.error('No rate selected! Ensure you choose a rate under the Rates section.');
                          return;
                        }

                        setState({ ...state, isMouseDown: true });

                        updateAppliedRate(day, rateIndex);
                      }}
                      onMouseEnter={(e) => {
                        e.preventDefault();

                        if (state.isMouseDown) {
                          updateAppliedRate(day, rateIndex);
                        }
                      }}
                      style={{
                        textAlign: 'center',
                        border: '1px solid #dee2e6',
                      }}
                      key={`rate-value-${rateRowIndex}-${rateIndex}`}
                    >
                      {getAppliedRateWidget(rateId, allRates)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Button
        onClick={handleClearAppliedRates}
        size={'lg'}
        variant="outline-primary"
        style={{ margin: '2rem 1rem 2rem 0' }}
        type="button"
      >
        Clear Applied Rates
      </Button>

      <Button
        onClick={handleFillAppliedRates}
        size={'lg'}
        variant="outline-secondary"
        style={{ margin: '2rem 0' }}
        type="button"
      >
        Fill with Selected Rate
      </Button>

      <div style={{ width: '50%' }}>
        <h2>Rates</h2>
        <div style={{ padding: '1rem', borderRadius: '5px', border: '1px solid grey' }}>
          <>
            <div style={{ display: 'grid', gridTemplateColumns: '50px repeat(4, 1fr)' }}>
              <div>&nbsp;</div>
              <h3 style={{ textAlign: 'center' }}>Rate</h3>
              <h3>Import</h3>
              <h3>Export</h3>
              <h3>Actions</h3>
            </div>
            <div>
              {allRates.length ? (
                allRates.map((rate, rateIndex) => {
                  return (
                    <div
                      key={`rate-value-${rateIndex}`}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '50px repeat(4, 1fr)',
                        padding: '0.5rem 0',
                        borderTop: rateIndex === 0 && '1px solid grey',
                        borderBottom: '1px solid grey',
                      }}
                    >
                      <div>
                        <Form.Check
                          data-testid={`tou-rate-check-${rateIndex}`}
                          onChange={() =>
                            setState({
                              ...state,
                              selectedRate: rate,
                            })
                          }
                          checked={Number(state?.selectedRate?.id) === rate.id}
                          type={'radio'}
                          id={`radio-select-${rate.id}`}
                        />
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {getAppliedRateWidget(rate.id, allRates)}
                      </div>
                      <div>{rate.import_rate}</div>
                      <div>{rate.export_rate}</div>
                      <div style={{ display: 'flex' }}>
                        <Button
                          data-testid={`tou-rate-edit-${rateIndex}`}
                          onClick={() => {
                            setState({
                              ...state,
                              selectedRate: rate,
                              isEditRateSlidingPaneOpen: true,
                            });
                          }}
                          size={'lg'}
                          variant="outline-primary"
                          type="button"
                        >
                          Edit
                        </Button>

                        <Button
                          onClick={() => handleDeleteRate(rate)}
                          size={'lg'}
                          variant="outline-danger"
                          type="button"
                          data-testid={`tou-rate-delete-${rateIndex}`}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h3>No rates exist for this season. Click &quot;New Rate&quot; below to add one.</h3>
              )}

              <Button
                onClick={() => {
                  setState({
                    ...state,
                    selectedRate: null,
                    isEditRateSlidingPaneOpen: true,
                  });
                }}
                size={'lg'}
                variant="primary"
                type="button"
                style={{
                  marginTop: '1rem',
                }}
                data-testid="tou-rate-add"
              >
                New Rate
              </Button>
            </div>
          </>
        </div>
      </div>

      {state.isEditRateSlidingPaneOpen && (
        <TOURateSlidingPane
          hasUnsavedRateChanges={hasUnsavedRateChanges}
          setHasUnsavedRateChanges={setHasUnsavedRateChanges}
          onSubmitRateForm={handleSubmitRateForm}
          onRequestClose={() => handleCloseSlidingPane()}
          rate={state.selectedRate}
        />
      )}
    </div>
  );
}
