import { Box } from '@chakra-ui/layout';
import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import * as yup from 'yup';

const schema = yup.object().shape({
  heading: yup.string().required(),
  subheading: yup.string().required(),
  article_url: yup.string().required(),
  image: yup.string().required(),
  publisher_id: yup.object().required(),
  published_at: yup.date().required(),
});

export const INITIAL_FORM_VALUES = {
  heading: '',
  subheading: '',
  article_url: '',
  image: '',
  imagePreview: null,
  publisher_id: '',
  published_at: new Date(),
  is_active: false,
};

const checkDateBeforeToday = (date) => {
  return date <= new Date();
};

export default function ArticleForm({ onSubmit, publisherOptions, initialValues = INITIAL_FORM_VALUES }) {
  return (
    <Formik
      enableReinitialize
      validateOnChange
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting, handleSubmit, handleChange, values, touched, errors, setFieldValue, setValues }) => {
        const handleDrop = (acceptedFiles) => {
          acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.error('file reading was aborted');
            reader.onerror = () => console.error('file reading has failed');
            reader.onload = () => {
              if (values.imagePreview) {
                URL.revokeObjectURL(values.imagePreview);
              }

              const result = String(reader.result).replace(/data:image\/(.*);base64,/g, '');
              setValues({
                ...values,
                image: result,
                imagePreview: URL.createObjectURL(file),
              });
            };

            reader.readAsDataURL(file);
          });
        };
        const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop, accept: 'image/*', maxFiles: 1 });

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="heading">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  data-testid="article-heading-input"
                  as="textarea"
                  placeholder="Enter article heading"
                  name="heading"
                  value={values.heading}
                  onChange={handleChange}
                  isInvalid={touched.heading && !!errors.heading}
                  isValid={touched.heading && !errors.heading}
                />
                <Form.Control.Feedback type="invalid" data-testid="article-heading-error">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="subheading" data-testid="article-subheading-input">
                <Form.Label>Subheading</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter article subheading"
                  name="subheading"
                  value={values.subheading}
                  onChange={handleChange}
                  isInvalid={touched.subheading && !!errors.subheading}
                  isValid={touched.subheading && !errors.subheading}
                />
                <Form.Control.Feedback type="invalid" data-testid="article-subheading-error">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="4" controlId="article_url">
                <Form.Label>Article URL</Form.Label>
                <Form.Control
                  data-testid="article-url-input"
                  type="text"
                  placeholder="Enter article URL"
                  name="article_url"
                  value={values.article_url}
                  onChange={handleChange}
                  isInvalid={touched.article_url && !!errors.article_url}
                  isValid={touched.article_url && !errors.article_url}
                />
                <Form.Control.Feedback type="invalid" data-testid="article-url-error">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>

              <Col md="4">
                <Form.Group>
                  <Form.Label>Publisher</Form.Label>
                  <Select
                    data-testid="publisher-input"
                    isSearchable
                    id={'publisher_id'}
                    options={publisherOptions}
                    onChange={(e) => setFieldValue('publisher_id', e)}
                    placeHolder={'Select a publisher...'}
                    isInvalid={!!touched.publisher_id && !!errors.publisher_id}
                  />
                  {!!touched.publisher_id && errors.publisher_id && (
                    <Box data-testid="publisher-input-error" pt={1} fontSize="11.2px" color="red">
                      This field is required
                    </Box>
                  )}
                </Form.Group>
              </Col>

              <Form.Group as={Col} md="4" controlId="published_at">
                <Form.Label>Publish date</Form.Label>
                <Form.Control
                  as={DatePicker}
                  autoComplete="off"
                  filterDate={checkDateBeforeToday}
                  placeholderText={'Select a publish date'}
                  dateFormat="yyyy-MM-dd"
                  isInvalid={touched.published_at && !!errors.published_at}
                  isValid={touched.published_at && !errors.published_at}
                  selected={new Date(values.published_at)}
                  onChange={(e) => setFieldValue('published_at', e)}
                />
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="4" controlId="is_active">
                <h3>Is this article active in Cortex?</h3>
                <Form.Check
                  onChange={() => setFieldValue('is_active', !values.is_active)}
                  checked={values.is_active}
                  type="checkbox"
                  label="Active"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Col md="4">
                <div style={getDropzoneStyle()} {...getRootProps()}>
                  <input data-testid="article-upload-image" {...getInputProps()} />
                  {!values.image ? (
                    <>
                      <p>Drag &rsquo;n&rsquo; drop article image here, or click to browse files</p>
                      <p>Only .png and .jpg files will be accepted.</p>
                      <p>Note that portrait images are best for display in a card thumbnail</p>
                    </>
                  ) : (
                    <div>
                      <div style={thumbStyle}>
                        <div style={thumbInnerStyle}>
                          <img src={values.imagePreview} style={imgStyle} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {errors.image && (
                  <div style={{ color: 'red' }} data-testid="article-upload-image-error">
                    Image is required.
                  </div>
                )}
              </Col>
            </Form.Row>

            <Button
              data-testid="article-submit-btn"
              style={{ margin: '2rem 0', height: '50px', width: '200px' }}
              size={'lg'}
              variant="success"
              disabled={isSubmitting}
              type="submit"
            >
              Submit {isSubmitting && <Spinner style={{ marginLeft: '0.5rem' }} animation="border" />}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

const getDropzoneStyle = () => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px',
  border: '2px dotted black',
  margin: '0.5rem',
});

const thumbStyle = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
};

const thumbInnerStyle = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const imgStyle = {
  display: 'block',
  width: 'auto',
  height: '100%',
};
