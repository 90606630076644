/**
 * A higher-order function intended to be used to convert flat arrays of objects (with any internal structure) to a
 * uniform select format:
 * {
 *   label: {string|number},
 *   value: {string|number},
 * }
 *
 * @param {string|number} labelKey
 * @param {string|number} valueKey
 * @returns {function(*): {label: *, value: *}}
 */
export function mapArrayToSelectFormat(labelKey, valueKey) {
  return (item) => ({
    label: item[labelKey],
    value: item[valueKey],
  });
}

/**
 * Reduces an array of model properties from a full model object to an object containing only the keys specified
 * in the property array.
 * Useful in cases where serialized back-end models return more object properties than we want to work with, e.g.
 * User or Site.
 *
 * @param {object} originalModel The original model object (usually retrieve from the back-end)
 * @param {array<string>} propertyArray An array of properties to copy from the original model.
 * @returns {object} The resulting intersected object.
 */
export function reducePropertyArrayToModelStructure(originalModel, propertyArray) {
  return propertyArray.reduce((newObject, property) => {
    newObject[property] = originalModel[property];
    return newObject;
  }, {});
}
