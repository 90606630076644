import React, { useEffect, useState } from 'react';
import InverterForm from './InverterForm';
import BatteryForm from './BatteryForm';
import MeterForm from './MeterForm';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { Button } from '@chakra-ui/button';
import { getTokenAndEmailFromSession, get, post } from '../../../common/api-utils';
import { CommonFieldListProps, SiteDevicesFormData } from './site-devices-types';
import { schema } from './site-devices-validation-schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  associateInvertersWithAPISubArrays,
  associateInvertersWithFormSubArrays,
  mapBatteriesToAPI,
  mapBatteriesToForm,
  mapDevicesToForm,
  mapEvChargersToAPI,
  mapEvChargersToForm,
  mapInvertersToAPI,
  mapInvertersToForm,
  mapMetersToAPI,
  mapMetersToForm,
  mapSubArraysToAPI,
} from './form-mapping-helpers';
import { InstalledDevice } from '../../../api/api-device';
import { InverterSubarray } from '../../../api/api-subarray';
import CenteredLoader from '../../../components/CenteredLoader';
import { toast } from 'react-toastify';
import { DeviceType } from '../../../api/api-device-metadata';
import { WarningIcon } from '@chakra-ui/icons';
import '../../../styles/site-devices.css';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import EvChargerForm from './EvChargerForm';
interface DeviceTypeTabConfig {
  label: string;
  value: DeviceType;
  apiEndPoint: string;
  component: (props: CommonFieldListProps) => JSX.Element;
}
const DEVICE_TYPES: DeviceTypeTabConfig[] = [
  { label: 'Inverter', value: 'INVERTER', apiEndPoint: 'inverters', component: InverterForm },
  { label: 'Meter', value: 'METER', apiEndPoint: 'meters', component: MeterForm },
  { label: 'Battery', value: 'BATTERY_PACK', apiEndPoint: 'batteries', component: BatteryForm },
  { label: 'EV Charger', value: 'EV_CHARGER', apiEndPoint: 'ev_chargers', component: EvChargerForm },
];

interface PageLoadingState {
  isSiteDataFetched: boolean;
  isSavingDevices: boolean;
}
const INITIAL_PAGE_LOADING_STATE = {
  isSiteDataFetched: false,
  isSavingDevices: false,
};
/**
 * Houses form functionality for site devices information to be used within the `Site` component.
 *
 * @returns {JSX.Element}
 *
 * @constructor
 * @component
 */
export default function SiteDeviceInformation({ siteId, isParentLoaded }) {
  const {
    register,
    control,
    reset,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<SiteDevicesFormData>({
    resolver: yupResolver(schema),
  });
  const [pageLoadingState, setPageLoadingState] = useState<PageLoadingState>(INITIAL_PAGE_LOADING_STATE);
  useEffect(() => {
    async function initialFetch() {
      try {
        const { jwtToken } = await getTokenAndEmailFromSession();

        if (siteId) {
          const [inverters, meters, batteries, evChargers] = await Promise.all([
            ...DEVICE_TYPES.map((deviceType) =>
              get<InstalledDevice[]>('site-device', `/site/sites/${siteId}/${deviceType.apiEndPoint}`, jwtToken)
            ),
          ]);

          const invertersWithSubArrays = await associateInvertersWithAPISubArrays(inverters);

          const formInverters = mapInvertersToForm(invertersWithSubArrays);
          const formMeters = mapMetersToForm(meters);
          const formBatteries = mapBatteriesToForm(batteries);
          const formEvChargers = mapEvChargersToForm(evChargers);

          reset({
            inverters: formInverters,
            batteries: formBatteries,
            meters: formMeters,
            evChargers: formEvChargers,
          });
          setPageLoadingState((prevState) => ({ ...prevState, isSiteDataFetched: true }));
        }
      } catch (error) {
        toast.error(
          "An error occurred loading this site's devices. This is likely a data integrity issue, please contact support.",
          {
            autoClose: 10000,
          }
        );

        console.log('error', error);
        setPageLoadingState((prevState) => ({ ...prevState, isSiteDataFetched: true }));
      }
    }
    if (isParentLoaded && !pageLoadingState.isSiteDataFetched) {
      initialFetch();
    }
  }, [isParentLoaded, siteId, pageLoadingState.isSiteDataFetched]);

  const handleFormSubmit = async (data: SiteDevicesFormData) => {
    setPageLoadingState((prevState) => ({ ...prevState, isSavingDevices: true }));
    const newMeters = data.meters.filter((meter) => !meter.meterId);
    const devices = [
      ...mapBatteriesToAPI(data.batteries, siteId),
      ...mapMetersToAPI(data.meters, siteId),
      ...mapEvChargersToAPI(data.evChargers, siteId),
    ];
    const inverters = [...mapInvertersToAPI(data.inverters, siteId)];

    try {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const savedDeviceData = await post<InstalledDevice[]>(
        'site-devices',
        `/site/sites/${siteId}/devices`,
        devices,
        jwtToken
      );
      const { formMeters, formBatteries, formEvChargers } = mapDevicesToForm(savedDeviceData);

      const savedInverterData = await post<InstalledDevice[]>(
        'site-devices',
        `/site/sites/${siteId}/devices`,
        inverters,
        jwtToken
      );
      const formInverters = savedInverterData.map((savedInverter, index) =>
        associateInvertersWithFormSubArrays(savedInverter, data.inverters[index])
      );

      reset({ inverters: formInverters, meters: formMeters, batteries: formBatteries, evChargers: formEvChargers });

      const subArrayPromises = formInverters.map((inverter) => {
        const allSubArrays = mapSubArraysToAPI(inverter.subArrays, inverter.inverterId);
        return post<InverterSubarray>(
          'sub_arrays',
          `/site/inverters/${inverter.inverterId}/subarrays`,
          allSubArrays,
          jwtToken
        );
      });

      if (newMeters.length) {
        await Promise.all([
          ...subArrayPromises,
          post('refresh_circuits', `/site/sites/${siteId}/refresh_circuits`, {}, jwtToken),
        ]);
        toast.success('Successfully re-synced site circuits. Please wait 30 seconds for the sync job to complete.');
      } else {
        await Promise.all(subArrayPromises);
      }

      toast.success('Successfully updated site devices', { autoClose: 5000 });
      setPageLoadingState((prevState) => ({ ...prevState, isSavingDevices: false }));
    } catch (e) {
      displayAPIErrorMessage(e);
      toast.error(
        'Something went wrong while saving the devices. Please ensure that the value of the Site Identifier field is unique to each device.',
        {
          autoClose: 10000,
        }
      );
      setPageLoadingState((prevState) => ({ ...prevState, isSavingDevices: false }));
    }
  };
  if (!pageLoadingState.isSiteDataFetched) return <CenteredLoader />;
  return (
    <Box
      as="form"
      onSubmit={handleSubmit(handleFormSubmit)}
      className="site-devices-form"
      data-testid={'site-devices-form'}
    >
      <Flex direction="column" maxWidth={'1000px'}>
        <Tabs isLazy variant="soft-rounded" colorScheme="green" isFitted>
          <TabList mb={5}>
            {DEVICE_TYPES.map((deviceType, index) => (
              <Tab data-testid={`${deviceType.label.toLowerCase()}-tab-btn`} key={index} mr={10}>
                <Text px={10} py={3}>
                  {deviceType.label}
                  {errors?.[deviceType.apiEndPoint] && <WarningIcon ml={2} w={7} h={7} color="red.500" />}
                </Text>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {DEVICE_TYPES.map((deviceType, index) => (
              <TabPanel key={index}>
                <deviceType.component
                  {...{
                    reset,
                    control,
                    setError,
                    register,
                    errors,
                    setValue,
                    getValues,
                    deviceType: deviceType.value,
                    siteId,
                  }}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        <Button
          data-testid={'save-all-devices-btn'}
          isDisabled={!pageLoadingState.isSiteDataFetched}
          isLoading={pageLoadingState.isSavingDevices}
          loadingText="Saving..."
          w="200px"
          py={9}
          type="submit"
          mx="auto"
          mt={18}
          rounded={5}
          colorScheme={'clipsalGreen'}
        >
          Save All Devices
        </Button>
      </Flex>
    </Box>
  );
}
