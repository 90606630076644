import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

library.add(faSpinner);

export default ({ isLoading, text, loadingText, className = '', disabled = false, ...props }) => (
  <Button className={`LoaderButton ${className}`} disabled={disabled || isLoading} {...props}>
    {isLoading && <FontAwesomeIcon icon="spinner" spin />}
    {!isLoading ? text : loadingText}
  </Button>
);
