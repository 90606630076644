import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from 'react-loader';
import { toast } from 'react-toastify';

import { del, getTokenAndEmailFromSession } from '../../../common/api-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import ContentWrapper from '../../../components/ContentWrapper';
import Table, { HandleTableChangeProps, SortOrder } from '../../../components/table/Table';

const INITIAL_STATE = {
  page: 1,
  data: [],
  offset: 0,
  sizePerPage: 10,
  totalSize: 0,
  orderBy: 'id',
  sortOrder: 'asc' as SortOrder,
  isLoaded: false,
  searchTerm: '',
};

export default function DefaultTariff({ history }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    async function fetchAPI() {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const { data, item_count: totalSize } = await fetchDefaultTariff(jwtToken, state.offset);

      setState({
        ...state,
        isLoaded: true,
        data,
        totalSize,
      });
    }

    fetchAPI();
  }, []);

  async function fetchDefaultTariff(token?, offset?, orderBy?, sortOrder?, searchValue?) {
    if (sortOrder === 'desc') orderBy = `-${orderBy}`;
    return API.get(
      'tariff',
      `/tariff/default_tariffs` +
        `?limit=${state.sizePerPage}` +
        `&offset=${offset}` +
        `&ordering=${orderBy || 'row_id'}` +
        (searchValue ?? state.searchTerm ? `&search_term=${searchValue ?? state.searchTerm}` : ``),
      { headers: { 'Content-Type': 'application/json', Authorization: token } }
    );
  }

  async function handleTableChange({ page, sizePerPage, sortField, sortOrder }: HandleTableChangeProps) {
    try {
      setState({
        ...state,
        isLoaded: false,
      });

      await Promise.resolve();

      const { jwtToken } = await getTokenAndEmailFromSession();
      const offset = (page - 1) * sizePerPage;
      const { data } = await fetchDefaultTariff(jwtToken, offset, sortField, sortOrder);
      setState({
        ...state,
        page,
        offset,
        orderBy: sortField,
        sortOrder,
        data,
        sizePerPage,
        isLoaded: true,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  async function handleDeleteDefaultTariff(defaultTariff) {
    try {
      const defaultTariffId = defaultTariff.row_id;
      const { jwtToken } = await getTokenAndEmailFromSession();
      await del('tariff', `/tariff/default_tariffs/${defaultTariffId}`, jwtToken);

      toast.success('Successfully deleted default tariff');

      setState({
        ...state,
        data: state.data.filter((item) => item.row_id !== defaultTariffId),
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  async function handleChangePageSize(e) {
    const sizePerPage = Number(e.currentTarget.value);
    setState({
      ...state,
      sizePerPage,
      isLoaded: false,
    });

    state.sizePerPage = sizePerPage;
    const { jwtToken } = await getTokenAndEmailFromSession();
    const offset = 0;
    const { data } = await fetchDefaultTariff(jwtToken, offset);

    setState({
      ...state,
      data,
      sizePerPage,
      isLoaded: true,
    });
  }

  return (
    <ContentWrapper title={'Default Tariff'}>
      <div>
        <Button
          style={{ margin: '1rem 0', height: '50px', width: '200px' }}
          size={'lg'}
          variant="success"
          type="button"
          onClick={() => history.push('defaulttariffs/create')}
        >
          Add Default Tariff
        </Button>
        <div>
          <Table
            remote
            isRowExpandable={false}
            data={state.data}
            page={state.page}
            sortOrder={state.sortOrder}
            onChangePageSize={handleChangePageSize}
            sizePerPage={state.sizePerPage}
            totalSize={state.totalSize}
            tableConfig={DEFAULT_TARIFF_TABLE_CONFIG}
            onTableChange={handleTableChange}
            onHandleAction={handleDeleteDefaultTariff}
          />
          <Loader loaded={state.isLoaded} />
        </div>
      </div>
    </ContentWrapper>
  );
}

const DEFAULT_TARIFF_TABLE_CONFIG = {
  keyField: 'row_id',
  columns: [
    { dataField: 'row_id', text: 'Default Tariff ID', sort: true, data_type: 'int' },
    { dataField: 'state', text: 'State', sort: true, data_type: 'string' },
    { dataField: 'distributor_id', text: 'Distributor ID', sort: true, data_type: 'int' },
    { dataField: 'year', text: 'Year', sort: true, data_type: 'string' },
    { dataField: 'tariff_id', text: 'Tariff Id', data_type: 'int', sort: true },
    { dataField: 'tariff_plan_name', text: 'Tariff Plan Name', data_type: 'string', sort: true },
    { dataField: 'updated', text: 'Updated On', sort: true, data_type: 'date' },
    {
      dataField: 'action',
      text: 'Action',
      formatterType: 'action',
      formatterDetails: [
        {
          name: 'Edit',
          action: '#/defaulttariffs/defaulttariff/:id/edit',
          params: [{ key: 'defaulttariff', value: 'row_id' }],
        },
        {
          name: 'Delete',
          action: 'functioncall',
          value: 'delete_default_tariff',
          displayValue: 'row_id',
          messageNeeded: true,
        },
      ],
    },
  ],
};
