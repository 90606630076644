import { ChannelDataTuple, LongEnergyInterval, ShortEnergyInterval } from '../../../api/api-ww-meter';

type DataInterval = {
  x: number; // timestamp
  y: number;
};

type SeriesConfig = {
  name: string | null;
  data: DataInterval[];
};

export type DataTypeChartSeriesConfig = {
  name: string;
  data: SeriesConfig[];
};

type ShortPowerDataTypeToSeriesConfig = {
  powerRealChartData: DataTypeChartSeriesConfig;
  powerReactiveChartData: DataTypeChartSeriesConfig;
  currentChartData: DataTypeChartSeriesConfig;
  voltageChartData: DataTypeChartSeriesConfig;
  powerFactorChartData: DataTypeChartSeriesConfig;
};

type LongPowerDataTypeToSeriesConfig = {
  powerRealChartData: DataTypeChartSeriesConfig;
  powerReactiveChartData: DataTypeChartSeriesConfig;
  voltageMaxChartData: DataTypeChartSeriesConfig;
  voltageMinChartData: DataTypeChartSeriesConfig;
  currentMaxChartData: DataTypeChartSeriesConfig;
  currentMinChartData: DataTypeChartSeriesConfig;
  powerFactorChartData: DataTypeChartSeriesConfig;
};

export function buildEmptyChannelData(): SeriesConfig[] {
  return [
    { name: null, data: [] },
    { name: null, data: [] },
    { name: null, data: [] },
    { name: null, data: [] },
    { name: null, data: [] },
    { name: null, data: [] },
  ];
}

export function buildShortChartData(
  data: ShortEnergyInterval[],
  channels: ChannelDataTuple
): ShortPowerDataTypeToSeriesConfig {
  const powerArrayValues = [
    {
      name: 'Power (Real)',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Power (Reactive)',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Current',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Voltage',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Power Factor',
      data: buildEmptyChannelData(),
    },
  ];

  data.forEach(
    // Renaming each property so it's clearer
    ({
      pRealKw: powerRealValueArray,
      pReactiveKw: powerReactiveValueArray,
      iRMS: currentValueArray,
      vRMS: voltageValueArray,
      powerFactor: powerFactorValueArray,
      timestamp,
    }) => {
      // Each of these tuples is indexed by channel index (0 - 5)
      const indexToValueArray = [
        powerRealValueArray,
        powerReactiveValueArray,
        currentValueArray,
        voltageValueArray,
        powerFactorValueArray,
      ];

      indexToValueArray.forEach((valueTuple, index) => {
        valueTuple.forEach((value, channelIndex) => {
          const series = powerArrayValues[index].data[channelIndex];

          if (!series.name) {
            const channel = channels[channelIndex];
            series.name = `${channel.label} (CH ${channelIndex + 1})`;
          }

          series.data.push({
            x: timestamp * 1000,
            y: value,
          });
        });
      });
    }
  );

  return {
    powerRealChartData: powerArrayValues[0],
    powerReactiveChartData: powerArrayValues[1],
    currentChartData: powerArrayValues[2],
    voltageChartData: powerArrayValues[3],
    powerFactorChartData: powerArrayValues[4],
  };
}

export function buildLongChartData(
  data: LongEnergyInterval[],
  channels: ChannelDataTuple
): LongPowerDataTypeToSeriesConfig {
  const powerArrayValues = [
    {
      name: 'Power (Real)',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Power (Reactive)',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Voltage (Max)',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Voltage (Min)',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Current (Max)',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Current (Min)',
      data: buildEmptyChannelData(),
    },
    {
      name: 'Power Factor',
      data: buildEmptyChannelData(),
    },
  ];

  data.forEach(
    // Renaming each property so it's clearer
    ({
      pRealKw: powerRealValueArray,
      pReactiveKw: powerReactiveValueArray,
      vRMSMax: voltageMaxValueArray,
      vRMSMin: voltageMinValueArray,
      iRMSMax: currentMaxValueArray,
      iRMSMin: currentMinValueArray,
      powerFactor: powerFactorValueArray,
      timestamp,
    }) => {
      // Each of these tuples is indexed by channel index (0 - 5)
      const indexToValueArray = [
        powerRealValueArray,
        powerReactiveValueArray,
        voltageMaxValueArray,
        voltageMinValueArray,
        currentMaxValueArray,
        currentMinValueArray,
        powerFactorValueArray,
      ];

      indexToValueArray.forEach((valueTuple, index) => {
        valueTuple.forEach((value, channelIndex) => {
          const series = powerArrayValues[index].data[channelIndex];

          if (!series.name) {
            const channel = channels[channelIndex];
            series.name = `${channel.label} (CH ${channelIndex + 1})`;
          }

          series.data.push({
            x: timestamp * 1000,
            y: value,
          });
        });
      });
    }
  );

  return {
    powerRealChartData: powerArrayValues[0],
    powerReactiveChartData: powerArrayValues[1],
    voltageMaxChartData: powerArrayValues[2],
    voltageMinChartData: powerArrayValues[3],
    currentMaxChartData: powerArrayValues[4],
    currentMinChartData: powerArrayValues[5],
    powerFactorChartData: powerArrayValues[6],
  };
}
