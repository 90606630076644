import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import LargeButton from './LargeButton';
import { ChartDataType, IntervalType } from './MeterConfigurationChartContainer';

type ChartDataTypeButtonGroupProps = {
  selectedDataType: ChartDataType;
  selectedInterval: IntervalType;
  onSetDataType: (dataType: ChartDataType) => void;
};

export default function ChartDataTypeButtonGroup({
  selectedDataType,
  selectedInterval,
  onSetDataType,
}: ChartDataTypeButtonGroupProps) {
  return (
    <div>
      <ButtonGroup aria-label="Data type">
        <LargeButton
          data-testid="power-real-btn"
          isSelected={selectedDataType === 'powerReal'}
          onClick={() => onSetDataType('powerReal')}
          variant="secondary"
        >
          Power (Real)
        </LargeButton>
        <LargeButton
          data-testid="power-reactive-btn"
          isSelected={selectedDataType === 'powerReactive'}
          onClick={() => onSetDataType('powerReactive')}
          variant="secondary"
        >
          Power (Reactive)
        </LargeButton>

        {selectedInterval === 'short_energy' ? (
          <>
            <LargeButton
              data-testid="current-btn"
              isSelected={selectedDataType === 'current'}
              onClick={() => onSetDataType('current')}
              variant="secondary"
            >
              Current
            </LargeButton>
            <LargeButton
              data-testid="voltage-btn"
              isSelected={selectedDataType === 'voltage'}
              onClick={() => onSetDataType('voltage')}
              variant="secondary"
            >
              Voltage
            </LargeButton>
            <LargeButton
              data-testid="power-factor-btn"
              isSelected={selectedDataType === 'powerFactor'}
              onClick={() => onSetDataType('powerFactor')}
              variant="secondary"
            >
              Power Factor
            </LargeButton>
          </>
        ) : (
          <>
            <LargeButton
              data-testid="current-min-btn"
              isSelected={selectedDataType === 'currentMin'}
              onClick={() => onSetDataType('currentMin')}
              variant="secondary"
            >
              Current (Min)
            </LargeButton>
            <LargeButton
              data-testid="current-max-btn"
              isSelected={selectedDataType === 'currentMax'}
              onClick={() => onSetDataType('currentMax')}
              variant="secondary"
            >
              Current (Max)
            </LargeButton>
            <LargeButton
              data-testid="voltage-min-btn"
              isSelected={selectedDataType === 'voltageMin'}
              onClick={() => onSetDataType('voltageMin')}
              variant="secondary"
            >
              Voltage (Min)
            </LargeButton>
            <LargeButton
              data-testid="voltage-max-btn"
              isSelected={selectedDataType === 'voltageMax'}
              onClick={() => onSetDataType('voltageMax')}
              variant="secondary"
            >
              Voltage (Max)
            </LargeButton>
            <LargeButton
              data-testid="power-factor-long-btn"
              isSelected={selectedDataType === 'powerFactor'}
              onClick={() => onSetDataType('powerFactor')}
              variant="secondary"
            >
              Power Factor
            </LargeButton>
          </>
        )}
      </ButtonGroup>
    </div>
  );
}
