import React from 'react';

export default function NestedFlatTariffTable({ rates }) {
  return (
    <div style={{ width: '100%' }}>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Start month</th>
              <th>End month</th>
              <th>Import</th>
              <th>Export</th>
            </tr>
          </thead>
          <tbody>
            {rates.map((rate, rateIndex) => {
              return (
                <tr key={rateIndex}>
                  <td>{rate.start_month}</td>
                  <td>{rate.end_month}</td>
                  <td>{rate.import_rate}</td>
                  <td>{rate.export_rate}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
