import React from 'react';
import SlidingPane from 'react-sliding-pane';

import { FlatRateForm } from './forms/FlatTariffForm';

const INITIAL_FORM_VALUES = {
  import: 0,
  export: 0,
};

export default function TOURateSlidingPane({
  onRequestClose,
  rate,
  onSubmitRateForm,
  hasUnsavedRateChanges,
  setHasUnsavedRateChanges,
}) {
  const initialFormValues = rate
    ? {
        import: rate.import_rate,
        export: rate.export_rate,
      }
    : INITIAL_FORM_VALUES;

  return (
    <SlidingPane onRequestClose={onRequestClose} isOpen title={`${rate ? 'Edit' : 'Add'} Rate`}>
      <h3>{rate ? 'Edit' : 'Add'} Tariff Rate</h3>
      <FlatRateForm
        onSubmit={onSubmitRateForm}
        initialFormValues={initialFormValues}
        hasUnsavedRateChanges={hasUnsavedRateChanges}
        setHasUnsavedRateChanges={setHasUnsavedRateChanges}
      />
    </SlidingPane>
  );
}
