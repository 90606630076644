import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import * as yup from 'yup';

const schema = yup.object().shape({
  state: yup.string().required(),
  distributor: yup.object().required(),
  year: yup.number().required(),
  tariff_id: yup.number().required(),
});

export const INITIAL_FORM_VALUES = {
  state: '',
  distributor: [],
  year: '',
  tariff_id: '',
};

export default function DefaultTariffForm({ onSubmit, distributorOptions, initialValues = INITIAL_FORM_VALUES }) {
  return (
    <Formik
      enableReinitialize
      validateOnChange
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting, handleSubmit, values, touched, errors, setFieldValue, handleChange }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  isInvalid={touched.state && !!errors.state}
                  isValid={touched.state && !errors.state}
                />
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="url">
                <Form.Label>Distributor</Form.Label>
                <Select
                  id={'distributor'}
                  isSearchable
                  placeholder={'Select a distributor...'}
                  options={distributorOptions}
                  value={values.distributor}
                  onChange={(e) => setFieldValue('distributor', e)}
                  isInvalid={touched.distributor && !!errors.distributor}
                  isValid={touched.distributor && !errors.distributor}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="state">
                <Form.Label>Year</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Year"
                  name="year"
                  value={values.year}
                  onChange={handleChange}
                  isInvalid={touched.year && !!errors.year}
                  isValid={touched.year && !errors.year}
                />
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="url">
                <Form.Label>Tariff Id</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter tariff id"
                  name="tariff_id"
                  value={values.tariff_id}
                  onChange={handleChange}
                  isInvalid={touched.tariff_id && !!errors.tariff_id}
                  isValid={touched.tariff_id && !errors.tariff_id}
                />
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Button
              style={{ margin: '2rem 0', height: '50px', width: '200px' }}
              size={'lg'}
              variant="success"
              disabled={isSubmitting}
              type="submit"
            >
              Submit {isSubmitting && <Spinner style={{ marginLeft: '0.5rem' }} animation="border" />}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
