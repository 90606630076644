import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import siteConfig from '../common/site-config';
import { decryptCodes, displayAPIErrorMessage } from '../common/utils-helper';
import { useAuthContext } from '../context/AuthContext';

const INITIAL_STATE = { userRole: '', username: '' };

const TopNav = () => {
  const [state, setState] = useState(INITIAL_STATE);
  const history = useHistory();
  const location = useLocation();

  const { authenticatedUserDetails, isLoadingAuth, logout } = useAuthContext();

  const capitalizeFirstChar = (str) => str.charAt(0).toUpperCase() + str.substring(1);

  useEffect(() => {
    const userRoleInLocalStorage = localStorage.getItem('role');
    if (!isLoadingAuth && (authenticatedUserDetails as any).idToken && userRoleInLocalStorage) {
      const userRole = decryptCodes(userRoleInLocalStorage);
      setState({
        username: capitalizeFirstChar((authenticatedUserDetails as any).idToken.payload['cognito:username']),
        userRole,
      });
    }
  }, [authenticatedUserDetails, isLoadingAuth]);

  /* if user clicks logs out */
  const onLogout = async (event) => {
    event.preventDefault();
    try {
      await logout();
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  /* if user clicks change password */
  const onChangePassword = async (event) => {
    event.preventDefault();
    try {
      history.push('/changepassword');
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  return (
    <header className="navbar sticky-top">
      <div className="logo">
        <div onClick={(e) => e.preventDefault()} className="align-self-center d-block header-logo-pos" />
        <button className="btn-open btn  border-0 d-block d-md-none ">☰ </button>
      </div>
      <nav>
        <ul>
          {siteConfig.headers.menu.map((header, idx) => {
            if (header.roles.includes(state.userRole)) {
              return (
                <li key={idx} className={`${location.pathname.split('/')[1] === header.name && 'header-active'}`}>
                  <a
                    href={header.href}
                    id={header.name}
                    className={`${location.pathname.split('/')[1] === header.name && 'active'}`}
                  >
                    {header.label}
                  </a>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </nav>
      <div className="loggeduser-sec">
        <div className="btn-group mt-3" style={{ cursor: 'pointer' }}>
          <div className="dropdown header-link">
            <div
              id="btnGroupDrop1"
              data-target="#dropdownLogLink"
              data-toggle="dropdown"
              className="dropdown-toggle"
              data-testid="top-nav-dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon className="header-icon" icon={faUserCircle} />
              <span> {state.username}</span>
            </div>
          </div>
          <div className="dropdown-menu dropdown-menu-right" id="dropdownLogLink">
            <a className="dropdown-item" href="#/changepassword" onClick={onChangePassword}>
              Change Password
            </a>
            <div className="dropdown-item" data-testid="logout-button" onClick={onLogout}>
              Logout
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopNav;
