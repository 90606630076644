import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { get, getTokenAndEmailFromSession, patch, post } from '../../../common/api-utils';
import { mapArrayToSelectFormat } from '../../../common/array-utils';
import { displayAPIErrorMessage, formatDate } from '../../../common/utils-helper';
import ContentWrapper from '../../../components/ContentWrapper';
import ArticleForm from './ArticleForm';

const INITIAL_STATE = {
  isLoaded: false,
  article: null,
  publisherOptions: [],
};

export default function EditArticle({ history, match }) {
  const [state, setState] = useState(INITIAL_STATE);
  const articleId = match.params.id.replace(':', '');

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { jwtToken } = await getTokenAndEmailFromSession();
        const [article, publishers] = await Promise.all([
          get('article', `/common/articles/${articleId}`, jwtToken),
          get('publishers', '/common/publishers', jwtToken),
        ]);

        const publisherOptions = publishers.data.map(mapArrayToSelectFormat('name', 'id'));

        setState({
          ...state,
          isLoaded: true,
          publisherOptions,
          article: {
            ...article,
            published_at: new Date(article.published_at),
            publisher_id: publisherOptions.find((opt) => opt.value === article.publisher_id),
            imagePreview: article.image_url,
            image: 'FROM_URL',
          },
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }

    if (!state.isLoaded) {
      fetchAPI();
    }
  }, [state.isLoaded, articleId]);

  async function handleEditArticle(values, actions) {
    try {
      actions.setSubmitting(true);

      const { jwtToken } = await getTokenAndEmailFromSession();
      const articleToSave = {
        id: articleId,
        heading: values.heading,
        subheading: values.subheading,
        article_url: values.article_url,
        publisher_id: values.publisher_id.value,
        published_at: formatDate(values.published_at),
        is_active: !!values.is_active,
      };

      const imageBase64 = values.image;
      await patch('articles', `/common/articles/${articleId}`, articleToSave, jwtToken);
      if (values.image !== 'FROM_URL') {
        await post('articles', `/common/articles/${articleId}/upload`, imageBase64, jwtToken);
      }

      toast.success('Successfully edited article');
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      displayAPIErrorMessage(e);
    }
  }

  return (
    <ContentWrapper
      breadcrumbs={[{ title: 'Articles', url: '#/articles' }, { title: 'Edit Article' }]}
      title={'Edit Article'}
    >
      {state.isLoaded ? (
        <ArticleForm
          initialValues={state.article}
          publisherOptions={state.publisherOptions}
          history={history}
          onSubmit={handleEditArticle}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
          <Spinner style={{ width: '50px', height: '50px' }} animation="border" />
        </div>
      )}
    </ContentWrapper>
  );
}
