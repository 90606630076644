import React, { useState, useEffect } from 'react';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Article, Publisher } from '../../../api/api-articles';

import { del, getTokenAndEmailFromSession } from '../../../common/api-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import ContentWrapper from '../../../components/ContentWrapper';
import Table, { HandleTableChangeProps } from '../../../components/table/Table';
import { fetchEditorialContent } from './articles-table-helper';
import { useHistory } from 'react-router-dom';
import { SortOrder } from '../../../components/table/Table';
import { PaginatedResponse } from '../../../common/types/helper-types';

type EditorialContentTableState = typeof INITIAL_STATE;

type TableConfig = typeof ARTICLE_TABLE_CONFIG | typeof PUBLISHER_TABLE_CONFIG;

type TableName = keyof Omit<EditorialContentTableState, 'isLoaded'>;

type APIName = 'articles' | 'publisher';

export type EndPoint = 'articles' | 'publishers';

const INITIAL_STATE = {
  articles: {
    page: 1,
    data: [] as Article[],
    offset: 0,
    sizePerPage: 10,
    totalSize: 0,
    orderBy: 'id',
    sortOrder: 'asc' as SortOrder,
  },
  activeArticles: {
    page: 1,
    data: [] as Article[],
    offset: 0,
    sizePerPage: 10,
    totalSize: 0,
    orderBy: 'id',
    sortOrder: 'asc' as SortOrder,
  },
  publishers: {
    page: 1,
    data: [] as Publisher[],
    offset: 0,
    sizePerPage: 10,
    totalSize: 0,
    orderBy: 'id',
    sortOrder: 'asc' as SortOrder,
  },
  isLoaded: false,
};

export default function EditorialContentTable() {
  const [editorialContent, setEditorialContent] = useState<EditorialContentTableState>(INITIAL_STATE);

  const handleChangePageSize = async (e: React.ChangeEvent<HTMLInputElement>, tableName: TableName) => {
    const sizePerPage = Number(e.currentTarget.value);
    setEditorialContent({
      ...editorialContent,
      isLoaded: false,
      [tableName]: {
        ...editorialContent[tableName],
        sizePerPage,
      },
    });
  };

  useEffect(() => {
    async function fetchAPI() {
      try {
        const [articles, activeArticles, publishers] = await Promise.all([
          fetchEditorialContent<PaginatedResponse<Article>>(
            editorialContent.articles.offset,
            editorialContent.articles.sizePerPage,
            editorialContent.articles.orderBy,
            editorialContent.articles.sortOrder,
            'articles'
          ),
          fetchEditorialContent<PaginatedResponse<Article>>(
            editorialContent.activeArticles.offset,
            editorialContent.activeArticles.sizePerPage,
            editorialContent.activeArticles.orderBy,
            editorialContent.activeArticles.sortOrder,
            'articles',
            true
          ),
          fetchEditorialContent<PaginatedResponse<Publisher>>(
            editorialContent.publishers.offset,
            editorialContent.publishers.sizePerPage,
            editorialContent.publishers.orderBy,
            editorialContent.publishers.sortOrder,
            'publishers'
          ),
        ]);

        setEditorialContent({
          isLoaded: true,
          articles: {
            ...editorialContent.articles,
            data: articles.data,
            totalSize: articles.item_count,
          },
          activeArticles: {
            ...editorialContent.activeArticles,
            data: activeArticles.data,
            totalSize: activeArticles.item_count,
          },
          publishers: {
            ...editorialContent.publishers,
            data: publishers.data,
            totalSize: publishers.item_count,
          },
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }

    if (!editorialContent.isLoaded) fetchAPI();
  }, [editorialContent.isLoaded]);

  function withTableChange(tableName: TableName) {
    return function handleTableChange({ page, sizePerPage, sortField, sortOrder }: HandleTableChangeProps) {
      const offset = (page - 1) * sizePerPage;

      let activeOnly = false;
      if (tableName === 'activeArticles') activeOnly = true;

      let endPoint = 'articles' as EndPoint;
      if (tableName === 'publishers') endPoint = 'publishers' as EndPoint;

      async function fetchAPI() {
        try {
          const updatedContent = await fetchEditorialContent<PaginatedResponse<Article | Publisher>>(
            offset,
            sizePerPage,
            sortField,
            sortOrder,
            endPoint,
            activeOnly
          );
          setEditorialContent({
            ...editorialContent,
            [tableName]: {
              ...editorialContent[tableName],
              data: updatedContent.data,
              totalSize: updatedContent.item_count,
              sortOrder,
              page,
            },
          });
        } catch (e) {
          displayAPIErrorMessage(e);
        }
      }
      fetchAPI();
    };
  }

  function withDeleteContent(endPoint: EndPoint, tableName: TableName) {
    return async function handleDeleteContent(contentType: Article | Publisher) {
      try {
        const message = endPoint.substring(0, endPoint.length - 1);
        let apiName = 'articles' as APIName;
        if (endPoint === 'publishers') apiName = 'publisher';
        const { jwtToken } = await getTokenAndEmailFromSession();
        await del(apiName, `/common/${endPoint}/${contentType.id}`, jwtToken);
        toast.success(`Successfully deleted ${message}`);
        const data = editorialContent[tableName].data as (Article | Publisher)[];

        setEditorialContent({
          ...editorialContent,
          [tableName]: {
            ...editorialContent[tableName],
            data: data.filter((content) => content.id !== contentType.id),
          },
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    };
  }

  const commonProps = {
    editorialContent,
    setEditorialContent,
    handleChangePageSize,
    withTableChange,
    withDeleteContent,
  };

  return (
    <ContentWrapper title={'Articles'}>
      <div>
        <Tabs defaultActiveKey="active-articles">
          <Tab
            eventKey="active-articles"
            title="Active articles"
            data-testid="active-article-tab"
            id="active-article-tab"
          >
            <TableContent {...commonProps} type={'activeArticles'} config={ARTICLE_TABLE_CONFIG} />
          </Tab>
          <Tab eventKey="articles" title="Articles" data-testid="article-tab" id="article-tab">
            <TableContent {...commonProps} type={'articles'} config={ARTICLE_TABLE_CONFIG} />
          </Tab>
          <Tab eventKey="publishers" title="Publishers" data-testid="publisher-tab" id="publisher-tab">
            <TableContent {...commonProps} type={'publishers'} config={PUBLISHER_TABLE_CONFIG} />
          </Tab>
        </Tabs>
      </div>
    </ContentWrapper>
  );
}

type TableContentProps = {
  editorialContent: EditorialContentTableState;
  setEditorialContent: (newState: EditorialContentTableState) => void;
  handleChangePageSize: (e: React.ChangeEvent<HTMLInputElement>, type: TableName) => void;
  withTableChange: (
    tableName: TableName
  ) => ({ changeType, page, sizePerPage, sortField, sortOrder }: HandleTableChangeProps) => void;
  withDeleteContent: (endPoint: EndPoint, tableName: TableName) => (contentType: Article | Publisher) => void;
  type: TableName;
  config: TableConfig;
};

const TableContent = ({
  editorialContent,
  handleChangePageSize,
  withTableChange,
  withDeleteContent,
  type,
  config,
}: TableContentProps) => {
  const history = useHistory();
  const { isLoaded } = editorialContent;
  const content = editorialContent[type];
  const { sortOrder, data, page, sizePerPage, totalSize } = content;

  let route = 'articles' as EndPoint;
  if (type === 'publishers') route = 'publishers';

  return (
    <>
      <Button
        data-testid={`create-${type}-btn`}
        style={{ margin: '1rem 0', height: '50px', width: '200px' }}
        size={'lg'}
        variant="success"
        type="button"
        onClick={() => history.push(`${route}/create`)}
      >
        {`Create ${route === 'articles' ? 'article' : 'publisher'}`}
      </Button>
      {isLoaded ? (
        <Table
          remote
          isRowExpandable={false}
          sortOrder={sortOrder}
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          tableConfig={config}
          onTableChange={withTableChange(type)}
          onChangePageSize={(e: React.ChangeEvent<HTMLInputElement>) => handleChangePageSize(e, type)}
          onHandleAction={withDeleteContent(route, type)}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Spinner style={{ width: '50px', height: '50px' }} animation="border" />
        </div>
      )}
    </>
  );
};

const PUBLISHER_TABLE_CONFIG = {
  keyField: 'id',
  columns: [
    { dataField: 'id', text: 'ID', sort: true, data_type: 'string' },
    { dataField: 'name', text: 'Name', sort: true, data_type: 'string' },
    { dataField: 'url', text: 'URL', sort: true, data_type: 'string' },
    {
      dataField: 'action',
      text: 'Action',
      formatterType: 'action',
      formatterDetails: [
        { name: 'Edit', action: '#/publishers/publisher/:id/edit', params: [{ key: 'publisher', value: 'id' }] },
        {
          name: 'Delete',
          value: 'delete_publisher',
          action: 'functioncall',
          displayValue: 'id',
          messageNeeded: true,
        },
      ],
    },
  ],
};

const ARTICLE_TABLE_CONFIG = {
  keyField: 'id',
  columns: [
    { dataField: 'id', text: 'ID', sort: true, data_type: 'string' },
    { dataField: 'is_active', text: 'Active?', data_type: 'boolean' },
    { dataField: 'heading', text: 'Heading', sort: true, data_type: 'string' },
    { dataField: 'subheading', text: 'Subheading', sort: true, data_type: 'string' },
    { dataField: 'publisher.name', text: 'Publisher', sort: true, data_type: 'string' },
    { dataField: 'article_url', text: 'Article URL', sort: true, data_type: 'string' },
    {
      dataField: 'action',
      text: 'Action',
      formatterType: 'action',
      formatterDetails: [
        { name: 'Edit', action: '#/articles/article/:id/edit', params: [{ key: 'article', value: 'id' }] },
        {
          name: 'Delete',
          value: 'delete_article',
          action: 'functioncall',
          displayValue: 'id',
          messageNeeded: true,
        },
      ],
    },
  ],
};
