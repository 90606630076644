import { API } from 'aws-amplify';
import { Spinner } from '@chakra-ui/react';
import {
  faCheck,
  faCogs,
  faDesktop,
  faDownload,
  faEdit,
  faEye,
  faInfo,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import { get, getTokenAndEmailFromSession } from '../../common/api-utils';
import { getMinuteDifferenceBetweenTimeStamps } from '../../common/date-formatting';
import { getFromPath } from '../../common/object-utils';
import { displayAPIErrorMessage } from '../../common/utils-helper';
import MeterCommsStatus, { CellMode } from '../SignalQuality';

export default function NestedSiteTable({
  expandData,
  tabs,
  expandType,
  row,
  handleRowAction,
  onExpandFetch,
  onHandleAction,
}) {
  if (!expandType) {
    return <></>;
  }

  const [state, setState] = useState({ openPopupDiscountId: null });

  const tableColumns = COLUMN_CONFIG.subType[expandType];

  const tableBody = expandData?.map((data, index) => {
    return (
      <tr key={index}>
        {tableColumns.map((column, expandIndex) => {
          let value = data[column.value];

          // Traverse an object path to find the end node if it's specified
          if (column.value.includes('.')) {
            value = getFromPath(data, column.value);
          }

          if (column.tofix && value) {
            value = value.toFixed(column.tofix);
          }

          if (!column.value.toLowerCase().includes('functioncall')) {
            return <td key={expandIndex}>{value}</td>;
          }

          // @TODO: reconsider this map, half the values seem unused
          const columnTypeToJSX = {
            action: (
              <td key={expandIndex}>
                {column?.list?.map((listItem, columnListIndex) => {
                  let icon;
                  if (listItem.type.includes('delete')) {
                    icon = faTrash;
                  } else {
                    const typeToIconMap = {
                      Edit: faEdit,
                      bill_view: faEye,
                    };

                    icon = typeToIconMap[listItem.type];
                  }

                  return (
                    <span
                      className={'custom-link'}
                      key={columnListIndex}
                      title={listItem.type}
                      onClick={(event) =>
                        handleRowAction(event, data, null, listItem.displayValue, listItem.type, listItem.messageNeeded)
                      }
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon className="icon-disp" icon={icon ?? faDownload} size="lg" />
                      &nbsp;&nbsp;
                    </span>
                  );
                })}
              </td>
            ),
            monitorfetch: (
              <td key={expandIndex} title="Monitor Details">
                <div
                  className={'custom-link'}
                  onClick={() => onHandleAction(data, 'list_circuits')}
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon className="icon-disp" icon={faDesktop} />
                </div>
              </td>
            ),
            meter_config: (
              <td key={expandIndex} title="Device Configuration" data-testid={`meter-config-btn-${index}`}>
                <a
                  className={'custom-link'}
                  target="_blank"
                  rel="noreferrer"
                  href={(() => {
                    const meter = data;

                    // Redirect to internal tool if WW, else redirect to SA dashboard for site
                    if (meter.meta_data?.manufacturer.manufacturer_name === 'Wattwatchers') {
                      return `/#/wattwatchers_meter_configuration/${meter.site_identifier}`;
                    } else {
                      return `https://clipsalsolar.solaranalytics.com/au/dashboard/${meter.site_identifier}`;
                    }
                  })()}
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon className="icon-disp" icon={faCogs} />
                </a>
              </td>
            ),
            infofetch: (
              <td key={expandIndex}>
                <div className={`popup custom-link ${state.openPopupDiscountId === data.id && 'visible'}`}>
                  <FontAwesomeIcon
                    onClick={() => setState({ ...state, openPopupDiscountId: data.id })}
                    className="icon-disp"
                    icon={faInfo}
                  />
                  <div
                    className={`popuptext ${state.openPopupDiscountId === data.id && 'visible'}`}
                    id={'popup' + index}
                  >
                    <span className="close-btn-span" onClick={() => setState({ ...state, openPopupDiscountId: null })}>
                      X
                    </span>

                    {column.subType === 'discount' && state.openPopupDiscountId === data.id && (
                      <TariffDiscountTable isOpen={state.openPopupDiscountId === data.id} tariffId={data.tariff_id} />
                    )}

                    {column.subType === 'description' && (
                      <table className="info_table">
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Size</th>
                          </tr>
                        </thead>
                        {data?.products?.map((info, productIndex) => {
                          return (
                            <tbody key={productIndex}>
                              <tr>
                                <td>{info.product_metadata.description}</td>
                                <td>{info.size}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    )}
                  </div>
                </div>
              </td>
            ),
            operating_state: (
              <td key={expandIndex} title="Sub Array Details">
                <OperatingStateChip operatingState={data.operating_state} />
              </td>
            ),
            is_hybrid_inverter: (
              <td key={expandIndex} title="Hybrid Inverter">
                <IsHybridChip isHybridInverter={data.is_hybrid_inverter} />
              </td>
            ),
            viewbill: (
              <td key={expandIndex} title="View Bill">
                <div
                  className={'custom-link'}
                  onClick={() => {
                    onHandleAction(
                      data,
                      data[column?.bill?.dependsOn].toLowerCase() === column?.bill?.match_val.toLowerCase()
                        ? 'bill_view'
                        : 'bill_view_manual',
                      data
                    );
                  }}
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {data[column?.bill?.value]}
                </div>
              </td>
            ),
            status: <MeterStatusCell key={expandIndex} meter={data} />,
          };

          return columnTypeToJSX[column.type] ?? <td key={expandIndex}>{value}</td>;
        })}
      </tr>
    );
  });

  return (
    <div>
      <div className="tabbed-data">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {tabs.map((item, i) => (
            <li key={`expand-item-${i}`} className="nav-item">
              <a
                className={`nav-link ${item.value === expandType && 'link_active'}`}
                onClick={() => onExpandFetch(item.value, row)}
                id={item.value + '-tab'}
                data-toggle="tab"
                href={'#' + item.value}
                role="tab"
                aria-controls={item.value}
                aria-selected="true"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" role="tabpanel">
          <table className="table table-sm">
            <thead>
              <tr>
                {tableColumns.map((item, i) => (
                  <th key={`tab-label-${i}`}>{item.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function MeterStatusCell({ meter }) {
  if (meter.meta_data.manufacturer.manufacturer_name !== 'Wattwatchers' || !meter.site_identifier) {
    return (
      <td title={'Not available'}>
        <div>N/A - Wattwatchers sites only</div>
      </td>
    );
  }

  const [state, setState] = useState({
    isLoaded: false,
    lastHeardInMinutes: null,
    signalQualityDbm: 0,
    commsType: '',
    cellMode: null,
  });

  useEffect(() => {
    const fetchMeterData = async () => {
      try {
        const { jwtToken: token } = await getTokenAndEmailFromSession();
        const meterData = await get('ww_meter_data', `/device/wattwatchers/devices/${meter.site_identifier}`, token);
        const { signalQualityDbm, type, lastHeardAt } = meterData.comms;
        const lastHeardInMinutes = getMinuteDifferenceBetweenTimeStamps(meterData.timezone, lastHeardAt);
        // Meters beginning with DD typically denote 4g capability
        const is4gMeter = meter.site_identifier?.substring(0, 2) === 'DD';
        const cellMode: CellMode = is4gMeter ? '4G' : '3G';
        setState({
          isLoaded: true,
          signalQualityDbm,
          cellMode: cellMode,
          commsType: type,
          lastHeardInMinutes,
        });
      } catch (error) {
        setState((prevState) => ({ ...prevState, isLoaded: true }));
      }
    };
    fetchMeterData();
  }, [meter.site_identifier]);

  const { lastHeardInMinutes, signalQualityDbm, commsType, cellMode } = state;

  return (
    <>
      {state.isLoaded ? (
        <MeterCommsStatus
          commsType={commsType}
          cellMode={cellMode}
          signalQualityDbm={signalQualityDbm}
          lastHeardInMinutes={lastHeardInMinutes}
        />
      ) : (
        <td>
          <Spinner emptyColor="gray.200" color="blue.500" size="md" />
        </td>
      )}
    </>
  );
}

function OperatingStateChip({ operatingState }) {
  const operatingStateToVariant = {
    NORMAL: 'success',
    OFFLINE: 'danger',
    NO_COMMS: 'warning',
  };

  return (
    <Badge pill variant={operatingStateToVariant[operatingState] ?? 'info'}>
      {operatingState ?? 'NULL_OPERATING_STATE'}
    </Badge>
  );
}

function IsHybridChip({ isHybridInverter }) {
  return isHybridInverter ? (
    <FontAwesomeIcon className="icon-disp" icon={faCheck} />
  ) : (
    <FontAwesomeIcon className="icon-disp" icon={faTimes} />
  );
}

function TariffDiscountTable({ tariffId, isOpen }) {
  const [state, setState] = useState({ discounts: [], isLoaded: false });

  useEffect(() => {
    async function fetchDiscounts() {
      try {
        const { jwtToken } = await getTokenAndEmailFromSession();
        const discounts = await API.get('discounts', `/tariff/tariffs/${tariffId}/discounts`, {
          headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
        });

        setState({
          discounts,
          isLoaded: true,
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }

    if (isOpen) {
      fetchDiscounts();
    }
  }, []);

  if (!state.isLoaded) {
    return <div>Loading...</div>;
  }

  if (!state.discounts.length) {
    return (
      <div style={{ background: 'white', padding: '1rem' }}>
        <h3>No discounts exist for this tariff.</h3>
      </div>
    );
  }

  return (
    <table className="info_table">
      <thead>
        <tr>
          <th>Product</th>
          <th>Discount</th>
          <th>Unit</th>
        </tr>
      </thead>
      {state.discounts.map((discount, discountIndex) => {
        return (
          <tbody key={discountIndex}>
            <tr>
              <td>{discount.discount_type.discount_type}</td>
              <td className="info_table_td">{discount.discount}</td>
              <td>{discount.unit}</td>
            </tr>
          </tbody>
        );
      })}
    </table>
  );
}

const COLUMN_CONFIG = {
  tablist: [
    { label: 'Tariff', value: 'tariff' },
    { label: 'Inverter', value: 'inverter' },
    { label: 'Meter', value: 'meter' },
    { label: 'Battery', value: 'battery_pack' },
    { label: 'Solar Module', value: 'solar_module' },
    { label: 'EV Charger', value: 'ev_charger' },
    { label: 'Investment', value: 'investments' },
    { label: 'Bills', value: 'bills' },
  ],
  subType: {
    tariff: [
      {
        label: 'Plan Name',
        value: 'tariff_plan_name',
      },
      { label: 'ID', value: 'id' },
      { label: 'Tariff ID', value: 'tariff_id' },
      { label: 'Plan ID', value: 'tariff_plan_id' },
      { label: 'Tariff Type', value: 'tariff_type' },
      { label: 'Retailer', value: 'retailer_name' },
      { label: 'Supply Charge (c/day)', value: 'tariff_supply_charge_cents_per_day' },
      { label: 'FiT (c/kWh)', value: 'FIT' },
      { label: 'Controlled Load (c/kWh)', value: 'controlled_load_rate_per_kwh_in_cents' },
      { label: 'Discounts', type: 'infofetch', value: 'functioncall', subType: 'discount' },
      { label: 'Rate (c/kWh)', value: 'rate' },
      { label: 'Quantity (kWh)', value: 'quantity' },
      { label: 'Effective Date', value: 'tariff_effective_date' },
      {
        label: 'Action',
        value: 'functioncall',
        type: 'action',
        list: [
          { type: 'Edit', displayValue: 'tariff_plan_name', messageNeeded: false, display: true },
          { type: 'delete_tariff', displayValue: 'tariff_plan_name', messageNeeded: true, display: true },
        ],
      },
    ],
    history: [
      { label: 'Plan Name', value: 'tariff_plan_name' },
      { label: 'Plan ID', value: 'tariff_plan_id' },
      { label: 'Tariff Type', value: 'tariff_type' },
      { label: 'Retailer', value: 'retailer_name' },
      { label: 'Supply Charge (c/day)', value: 'tariff_supply_charge_cents_per_day' },
      { label: 'FiT (c/kWh)', value: 'FIT' },
      { label: 'Controlled Load (c/kWh)', value: 'controlled_load_rate_per_kwh_in_cents' },
      { label: 'Discount (%)', value: 'tariff_percent_discount' },
      { label: 'Tier 1 Rate(c/kWh)', value: 'rate' },
      { label: 'Quantity(kWh)', value: 'quantity' },
    ],
    inverter: [
      { label: 'Manufacturer', value: 'meta_data.manufacturer.manufacturer_name' },
      { label: 'Model', value: 'meta_data.model' },
      { label: 'Options', value: 'meta_data.options' },
      { label: 'Version', value: 'meta_data.version' },
      { label: 'AC Output Power (W)', value: 'ac_power' },
      { label: 'Operating Status', value: 'operating_state' },
      { label: 'Fault Code', value: 'fault_code' },
      { label: 'Site Identifier', value: 'site_identifier' },
      { label: 'Hybrid Inverter', value: 'Functioncall', type: 'is_hybrid_inverter' },
    ],
    meter: [
      { label: 'Manufacturer', value: 'meta_data.manufacturer.manufacturer_name' },
      { label: 'Model', value: 'meta_data.model' },
      { label: 'Monitoring Start Date', value: 'monitoring_start' },
      { label: 'Options', value: 'meta_data.options' },
      { label: 'Version', value: 'meta_data.version' },
      { label: 'Operating State', value: 'Functioncall', type: 'operating_state' },
      { label: 'Fault Code', value: 'fault_code' },
      { label: 'Site Identifier', value: 'site_identifier' },
      { label: 'Signal Strength', value: 'Functioncall', type: 'status' },
      { label: 'Configuration', value: 'Functioncall', type: 'meter_config' },
      { label: 'Monitors', value: 'Functioncall', type: 'monitorfetch' },
    ],
    battery_pack: [
      { label: 'Manufacturer', value: 'meta_data.manufacturer.manufacturer_name' },
      { label: 'Model', value: 'meta_data.model' },
      { label: 'Options', value: 'meta_data.options' },
      { label: 'Version', value: 'meta_data.version' },
      { label: 'Energy Capacity', value: 'battery_pack_w_h_rtg' },
      { label: 'Voltage', value: 'battery_pack_v' },
      { label: 'Amps', value: 'battery_pack_a' },
      { label: 'Fault Code', value: 'fault_code' },
      { label: 'Site Identifier', value: 'site_identifier' },
    ],
    solar_module: [
      { label: 'Manufacturer', value: 'meta_data.manufacturer.manufacturer_name' },
      { label: 'Model', value: 'meta_data.model' },
      { label: 'Options', value: 'meta_data.options' },
      { label: 'Version', value: 'meta_data.version' },
      { label: 'Power Capacity', value: 'solar_wrtg' },
      { label: 'Quantity', value: 'quantity' },
      { label: 'Installed Capacity', value: 'solar_installed_capacity' },
    ],
    ev_charger: [
      { label: 'Manufacturer', value: 'meta_data.manufacturer.manufacturer_name' },
      { label: 'Model', value: 'meta_data.model' },
      { label: 'Site Identifier', value: 'site_identifier' },
      { label: 'Serial Number', value: 'serial_number' },
    ],
    investments: [
      { label: 'Investment Type', value: 'investment_type' },
      { label: 'Description', type: 'infofetch', value: 'functionCall', subType: 'description' },
      { label: 'Installation Date', value: 'installation_date' },
      { label: 'No of Payments', value: 'no_of_payments' },
      { label: 'Billing Period', value: 'billing_period' },
      { label: 'Amount', value: 'amount' },
    ],
    insight_history: [
      { label: 'Datetime', value: 'datetime' },
      { label: 'Name', value: 'name' },
      { label: 'Text', value: 'text' },
    ],
    bills: [
      { label: 'Source', value: 'source' },
      { label: 'Start Date', value: 'start_date' },
      { label: 'End Date', value: 'end_date' },
      { label: 'Retailer', value: 'retailer.retailer_name' },
      { label: 'Type', value: 'type' },

      { label: 'Total Bill', value: 'total_amount', tofix: 2 },
      { label: 'Currency', value: 'currency' },
      { label: 'Average Daily Consumption (kWh)', value: 'consumption', tofix: 2 },
      {
        label: 'Action',
        value: 'functioncall',
        type: 'action',
        list: [
          { display: true, type: 'bill_view' },
          {
            type: 'delete_bill',
            dependsOn: 'source',
            match_val: 'manual bill',
            displayValue: 'start_date',
            messageNeeded: true,
            display: true,
          },
        ],
      },
    ],
  },
};
