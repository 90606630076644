import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { displayAPIErrorMessage } from '../../common/utils-helper';
import LoaderButton from '../../components/LoaderButton';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/TopNav';

interface IChangePasswordState {
  isLoading?: boolean;
  password?: string;
  confirmPassword?: string;
  currentPassword?: string;
  username?: string;
  type?: string;
}

interface IChangePasswordProps {
  userHasAuthenticated: (val: boolean) => void;
}
export default class Devices extends Component<RouteComponentProps & IChangePasswordProps, IChangePasswordState> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      password: '',
      confirmPassword: '',
      currentPassword: '',
      username: '',
      type: '',
    };
  }

  validateForm() {
    return (
      this.state.currentPassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.confirmPassword.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, this.state.currentPassword, this.state.confirmPassword);
        })
        .then((data) => {
          if (data === 'SUCCESS') {
            this.props.userHasAuthenticated(true);
            this.props.history.push('/sites');
            toast.success('Password changed successfully!', {
              autoClose: 5000,
            });
          } else {
            toast.error('Error while changing password!', {
              autoClose: 5000,
            });
          }
        })
        .catch((e) => {
          displayAPIErrorMessage(e);
          this.setState({ isLoading: false });
        });
    } catch (e) {
      displayAPIErrorMessage(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10 pt-3 px-4">
              <section>
                <div className="pt-4">
                  <form className="login-form" onSubmit={this.handleSubmit}>
                    <div className="position-relative mb-5">
                      <FormGroup controlId="currentPassword">
                        <label>Current Password</label>
                        <FormControl
                          autoFocus
                          type="password"
                          value={this.state.currentPassword}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </div>
                    <div className="position-relative mb-5">
                      <FormGroup controlId="password">
                        <label>Password</label>
                        <FormControl type="password" value={this.state.password} onChange={this.handleChange} />
                      </FormGroup>
                    </div>
                    <div className="position-relative mb-5">
                      <FormGroup controlId="confirmPassword">
                        <label>Confirm Password</label>
                        <FormControl value={this.state.confirmPassword} onChange={this.handleChange} type="password" />
                      </FormGroup>
                    </div>
                    <div className="form-group mt-5">
                      <LoaderButton
                        className="btn btn-lg btn-block btn-big btn-blue"
                        block
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Set New Password"
                        loadingText="Setting New Password…"
                      />
                    </div>
                  </form>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
