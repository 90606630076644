import React from 'react';

import siteConfig from '../common/site-config';
import { camelize, decryptCodes } from '../common/utils-helper';

interface ISideBarState {
  selectedSideMenu?: string;
  userRole?: string;
}

class Sidebar extends React.Component<ISideBarState, ISideBarState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedSideMenu: '',
      userRole: '',
    };
  }
  async componentDidMount() {
    try {
      const currentpage = window.location.hash;
      if (localStorage.getItem('role')) {
        const userRole = decryptCodes(localStorage.getItem('role'));
        this.setState({ userRole, selectedSideMenu: currentpage.replace('#/', '') });
      }
    } catch (e) {
      alert(e);
    }
  }
  stopClosing = (event) => {
    event.stopPropagation();
  };
  render() {
    const { selectedSideMenu, userRole } = this.state;
    return (
      <nav className="bg-primary-clipsal left-nav-panel sidebar">
        <div className="sidebar-sticky">
          <ul className="nav flex-column">
            {siteConfig.sidebar.topmenu.map((item, i) => {
              let classname = 'nav-item';
              if (item.roles.includes(userRole)) {
                const params = selectedSideMenu.split('/');
                const paramKeyVal = params[0].split(':');
                let lable_name = item.label;
                if (paramKeyVal[0] === item.name) {
                  classname += ' sidebar-active';
                  if (parseInt(paramKeyVal[1]) > 0) {
                    lable_name = 'Edit ' + camelize(paramKeyVal[0]);
                  }
                }
                if (item.list) {
                  const dataTarget = '#' + item.name;
                  let heirachyul = 'panel-collapse collapse';
                  const filtItems = item.list.filter((filt_item) => paramKeyVal[0] === filt_item.name);
                  if (filtItems.length > 0) {
                    heirachyul += ' show';
                  }
                  return (
                    <li key={i}>
                      <a
                        className="nav-link"
                        data-toggle="collapse"
                        onClick={(e) => e.preventDefault()}
                        data-target={dataTarget}
                        data-testid={`sidebar-administration`}
                      >
                        {lable_name}
                      </a>
                      <div id={item.name} className={`collapse ${heirachyul}`}>
                        <ul className="nav flex-column">
                          <ul className="nav flex-column" style={{ paddingLeft: '2em' }}>
                            {item.list.map((innerItem, j) => {
                              let inner_class_name = 'nav-item';
                              let inner_lable_name = innerItem.label;
                              if (paramKeyVal[0] === innerItem.name) {
                                inner_class_name += ' sidebar-active';
                                if (parseInt(paramKeyVal[1]) > 0) {
                                  inner_lable_name = 'Edit ' + camelize(paramKeyVal[0]);
                                }
                              }
                              return (
                                <li key={j} className={inner_class_name} data-testid={inner_lable_name}>
                                  <a className="nav-link" href={innerItem.href}>
                                    {inner_lable_name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </ul>
                      </div>
                    </li>
                  );
                }
                return (
                  <li key={i} className={classname} data-testid={`sidebar-${item.name}`}>
                    <a href={item.href} id={item.name} className="nav-link">
                      {lable_name}
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
