// @TODO: populate with decent colours

export default [
  { color: '#46C36E', letter: 'A' },
  { color: '#A88AA1', letter: 'B' },
  { color: '#EE7A44', letter: 'C' },
  { color: '#463442', letter: 'D' },
  { color: '#597D88', letter: 'E' },
  { color: '#194D29', letter: 'F' },
  { color: '#A84010', letter: 'G' },
  { color: '#00a5d0', letter: 'H' },
  { color: '#bea623', letter: 'I' },
  { color: '#b513ca', letter: 'J' },
  { color: '#d63d42', letter: 'K' },
  { color: '#1e6662', letter: 'L' },
  { color: '#3aa718', letter: 'M' },
  { color: '#1b4ac8', letter: 'N' },
  { color: '#f52626', letter: 'O' },
  { color: '#c4610c', letter: 'P' },
  { color: '#463442', letter: 'Q' },
  { color: '#F8C9B4', letter: 'R' },
  { color: '#F3A27C', letter: 'S' },
  { color: '#EE7A44', letter: 'T' },
  { color: '#E05515', letter: 'U' },
  { color: '#A84010', letter: 'V' },
  { color: '#DFD9E8', letter: 'W' },
  { color: '#BFB3D1', letter: 'X' },
  { color: '#9F8CBA', letter: 'Y' },
  { color: '#8066A3', letter: 'Z' },
];
