import { Field, FieldArray, Formik, getIn } from 'formik';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import Loader from 'react-loader';
import { Prompt } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { get, getTokenAndEmailFromSession, put } from '../../../common/api-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import TEMPLATES from '../tariff-templates/tiered-tariff-rate-templates.json';

// Schema for sub-field validation.
const schema = yup.object().shape({
  tieredRateFormData: yup.array().of(
    yup.object().shape({
      import: yup.string().min(1, 'Provide a value').required('Required'),
      export: yup.string().min(1, 'Provide a value').required('Required'),
      maxEnergy: yup.string().min(1, 'Provide a value').required('Required'),
    })
  ),
});

// Config for each row's displayed fields
const FIELD_CONFIG = [
  {
    label: 'Import rate (cents per kWh)',
    placeholder: 'Enter import rate',
    id: 'import',
  },
  {
    label: 'Export rate (cents per kWh)',
    placeholder: 'Enter export rate',
    id: 'export',
  },
  {
    label: 'Max. energy (kWh)',
    placeholder: 'Enter max energy',
    id: 'maxEnergy',
  },
];

const EMPTY_TIERED_RATE_ROW = {
  import: '',
  export: '',
  maxEnergy: '',
};

const INITIAL_STATE = {
  isLoaded: false,
  startMonth: null,
  endMonth: null,
  initialFormValues: {
    tieredRateFormData: [EMPTY_TIERED_RATE_ROW],
    rateTemplateIndex: null,
  },
};

export default function TieredTariffForm({ tariffId, aggregationPeriod }) {
  const [state, setState] = useState(INITIAL_STATE);
  const [hasUnsavedRateChanges, setHasUnsavedRateChanges] = useState(false);
  const formRefValues = useRef(null);

  useEffect(() => {
    async function fetchAPI() {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      const rates = await get('rates', `/tariff/tariffs/${tariffId}/tiered_rates`, token);
      let newState = {
        ...state,
        startMonth: 1,
        endMonth: 12,
        isLoaded: true,
      };

      if (rates && rates.length) {
        const rate = rates[0];
        const tieredRateFormData = rate.tiers.map((tier) => ({
          import: tier.import_rate,
          export: tier.export_rate,
          maxEnergy: tier.tier_max_energy_in_kwh,
        }));

        newState = {
          ...newState,
          startMonth: rate.start_month,
          endMonth: rate.end_month,
          initialFormValues: {
            ...newState.initialFormValues,
            tieredRateFormData,
          },
        };
      }

      setState(newState);
    }

    if (!state.isLoaded && tariffId) {
      fetchAPI();
    }
  }, []);

  const initialRateData = {
    rateTemplateIndex: state.initialFormValues.rateTemplateIndex,
    tieredRateFormData: state.initialFormValues.tieredRateFormData,
  };

  useEffect(() => {
    function handleWindowUnload(e) {
      if (hasUnsavedRateChanges && !isEqual(initialRateData, formRefValues.current.values)) {
        e.preventDefault();
        e.returnValue = '';
        return;
      }

      delete e['returnValue'];
    }

    window.addEventListener('beforeunload', handleWindowUnload);

    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [hasUnsavedRateChanges]);

  async function handleSubmit(values) {
    try {
      const { jwtToken: token } = await getTokenAndEmailFromSession();

      const tiers = values.tieredRateFormData.map((tierFormData, index) => ({
        tier_id: index + 1,
        import_rate: tierFormData.import,
        export_rate: tierFormData.export,
        tier_max_energy_in_kwh: tierFormData.maxEnergy,
      }));

      const rate = {
        start_month: state.startMonth,
        end_month: state.endMonth,
        aggregation_period: aggregationPeriod,
        tiers,
      };

      await put('rates', `/tariff/tariffs/${tariffId}/tiered_rates`, [rate], token);
      setHasUnsavedRateChanges(false);

      toast.success('Successfully saved tariff rates');
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  if (!state.isLoaded) {
    return <Loader loaded={state.isLoaded} />;
  }

  return (
    <>
      <Prompt
        when={hasUnsavedRateChanges}
        message="You have unsaved changes in the rate, are you sure you want to leave?"
      />
      <h2>Tiered Rate Configuration</h2>
      <Formik
        innerRef={formRefValues}
        validateOnChange
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={state.initialFormValues}
      >
        {({ handleSubmit, handleChange, values }) => (
          <Form noValidate onSubmit={handleSubmit} onChange={() => setHasUnsavedRateChanges(true)}>
            <div style={{ padding: '1rem', marginBottom: '2rem', border: '1px solid grey', borderRadius: '10px' }}>
              <h3>Tariff Rate Templates</h3>

              <Alert variant="warning">
                <div>
                  Use the below templates to automatically fill the tiered rates below. You can modify the rates after
                  loading a template as you please.
                </div>
              </Alert>
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="rateTemplateIndex">
                  <Form.Label>Rate Template</Form.Label>
                  <Form.Control
                    data-testid="tiered-select-template"
                    as="select"
                    name="rateTemplateIndex"
                    value={values.rateTemplateIndex ?? 'Choose a template'}
                    onChange={(e) => {
                      handleChange(e);

                      const rateTemplateIndex = e.currentTarget.value;
                      const rateTemplate = TEMPLATES[rateTemplateIndex];

                      const tieredRateFormData = rateTemplate.tiers.map((tier) => ({
                        import: tier.import_rate,
                        export: tier.export_rate,
                        maxEnergy: tier.tier_max_energy_in_kwh,
                      }));

                      setState({
                        ...state,
                        startMonth: rateTemplate.start_month,
                        endMonth: rateTemplate.end_month,
                        initialFormValues: {
                          rateTemplateIndex,
                          tieredRateFormData,
                        },
                      });
                    }}
                  >
                    <option>Choose a template</option>
                    {TEMPLATES.map((template, templateIndex) => (
                      <option value={templateIndex} key={`template-value-${templateIndex}`}>
                        {template.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </div>

            <FieldArray meta name="tieredRateFormData" render={(arrayHelpers) => RateTierForm(arrayHelpers)} />
            <Button
              style={{ margin: '2rem 1rem', height: '50px', width: '300px' }}
              size={'lg'}
              variant="success"
              type="submit"
              data-testid="submit-tariff-button"
            >
              Save Tiers
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

export const RateTierForm = ({ push, form, remove }) => {
  const { values, errors, touched } = form;

  return (
    <div style={{ width: '100%' }}>
      {values.tieredRateFormData.map((tieredRateFormData, index) => (
        <Form.Row key={index}>
          <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} md="1">
            <h3>Tier {index + 1}</h3>
          </Col>

          {FIELD_CONFIG.map(({ label, placeholder, id }, fieldIndex) => {
            const error = getIn(errors, `tieredRateFormData[${index}].${id}`);
            const touch = getIn(touched, `tieredRateFormData[${index}].${id}`);

            return (
              <Form.Group key={`tiered-form-field-${fieldIndex}`} as={Col} md="3" controlId={id}>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                  as={Field}
                  type="number"
                  placeholder={placeholder}
                  name={`tieredRateFormData.${index}.${id}`}
                  isInvalid={touch && !!error}
                  isValid={touch && !error}
                />
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
              </Form.Group>
            );
          })}

          <Col md="2">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Button
                size={'lg'}
                variant="outline-danger"
                type="button"
                style={{
                  ...getButtonStyle(),
                  cursor: values.tieredRateFormData.length === 1 ? 'not-allowed' : 'pointer',
                }}
                disabled={values.tieredRateFormData.length === 1}
                onClick={() => {
                  if (values.tieredRateFormData.length > 1) {
                    remove(index);
                  }
                }}
              >
                Remove
              </Button>
            </div>
          </Col>
        </Form.Row>
      ))}

      <Button
        size={'lg'}
        disabled={values.tieredRateFormData.length >= 5}
        variant="outline-success"
        type="button"
        style={{
          ...getButtonStyle(),
          cursor: values.tieredRateFormData.length >= 5 ? 'not-allowed' : 'pointer',
        }}
        onClick={() => {
          if (values.tieredRateFormData.length <= 4) {
            push(EMPTY_TIERED_RATE_ROW);
          }
        }}
      >
        Add
      </Button>
    </div>
  );
};

const getButtonStyle = () => ({
  margin: '0 1rem',
  height: '50px',
  width: '100px',
});
