import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { get, getTokenAndEmailFromSession, post } from '../../../common/api-utils';
import { mapArrayToSelectFormat } from '../../../common/array-utils';
import { displayAPIErrorMessage, formatDate } from '../../../common/utils-helper';
import ContentWrapper from '../../../components/ContentWrapper';
import ArticleForm from './ArticleForm';

const INITIAL_STATE = {
  isLoaded: false,
  publisherOptions: [],
};

export default function CreateArticle({ history }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { jwtToken } = await getTokenAndEmailFromSession();
        const publishers = await get('publishers', '/common/publishers', jwtToken);
        const publisherOptions = publishers.data.map(mapArrayToSelectFormat('name', 'id'));

        setState({
          ...state,
          isLoaded: true,
          publisherOptions,
        });
      } catch (e) {
        displayAPIErrorMessage(e);
      }
    }

    if (!state.isLoaded) {
      fetchAPI();
    }
  }, [state.isLoaded]);

  async function handleCreateArticle(values, actions) {
    try {
      actions.setSubmitting(true);

      const { jwtToken } = await getTokenAndEmailFromSession();
      const articleToSave = {
        heading: values.heading,
        subheading: values.subheading,
        article_url: values.article_url,
        publisher_id: values.publisher_id.value,
        published_at: formatDate(values.published_at),
        is_active: !!values.is_active,
      };

      const imageBase64 = values.image;
      const articlesResponse = await post('articles', `/common/articles`, articleToSave, jwtToken);
      const articleId = articlesResponse.id;
      await post('articles', `/common/articles/${articleId}/upload`, imageBase64, jwtToken);

      toast.success('Successfully created article');
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      displayAPIErrorMessage(e);
    }
  }

  return (
    <ContentWrapper
      breadcrumbs={[{ title: 'Articles', url: '#/articles' }, { title: 'Create Article' }]}
      title={'Create Article'}
    >
      {state.isLoaded ? (
        <ArticleForm publisherOptions={state.publisherOptions} history={history} onSubmit={handleCreateArticle} />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
          <Spinner style={{ width: '50px', height: '50px' }} animation="border" />
        </div>
      )}
    </ContentWrapper>
  );
}
