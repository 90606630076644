import * as yup from 'yup';

const meterSchema = yup.object().shape({
  manufacturer: yup.string().required('This field is required.'),
  model: yup.string().required('This field is required.'),
  siteIdentifier: yup.string().required('This field is required.'),
  monitoringStartDate: yup.string().required('This field is required.'),
});

export const schema = yup.object().shape({
  meters: yup.array().of(meterSchema),
});

export const subArraySchema = yup.object().shape({
  manufacturer: yup.string().required('This field is required.'),
  model: yup.string().required('This field is required.'),
  modulesPerString: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must have at least one')
    .required('This field is required.'),
  numOfStrings: yup
    .number()
    .typeError('Must be a number')
    .min(1, 'Must have at least one')
    .required('This field is required.'),
  orientationDegrees: yup
    .number()
    .typeError('Must be a number')
    .min(0, 'Must be between 0 and 360 degrees')
    .max(360, 'Must be between 0 and 360 degrees')
    .required('This field is required.'),
  tiltDegrees: yup
    .number()
    .typeError('Must be a number')
    .min(0, 'Must be between 0 and 90 degrees')
    .max(90, 'Must be between 0 and 90 degrees')
    .required('This field is required.'),
});

export const inverterSchema = yup.object().shape({
  manufacturer: yup.string().required('This field is required.'),
  model: yup.string().required('This field is required.'),
  siteIdentifier: yup.string().nullable(),
  subArrays: yup.array().of(subArraySchema),
  hasBattery: yup.boolean(),
});

