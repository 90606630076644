import React from 'react';

import NestedFlatTariffTable from './NestedFlatTariffTable';
import NestedTieredTariffTable from './NestedTieredTariffTable';
import NestedTOUTariffTable from './NestedTOUTariffTable';

export default function NestedTariffTable({ expandType, expandData }) {
  if (!expandType) {
    return <></>;
  }

  const expandTypeToTable = {
    flat: <NestedFlatTariffTable rates={expandData} />,
    tiered: <NestedTieredTariffTable rates={expandData} />,
    tou: <NestedTOUTariffTable seasons={expandData} />,
  };

  return expandTypeToTable[expandType?.toLowerCase()] ?? <div>Table not found.</div>;
}
