import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { FiRefreshCw } from 'react-icons/fi';
import Loader from 'react-loader';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';

import { getTokenAndEmailFromSession, post } from '../../../common/api-utils';
import { SIZE_PER_PAGE } from '../../../common/constants';
import CircuitApplianceAssociationDragDrop from '../CircuitApplianceAssociationDragDrop';

const INITIAL_STATE = {
  isLoaded: false,
  sizePerPage: 10,
  associationState: {
    currentlyEditingApplianceName: [],
    appliances: [],
    circuits: [],
    editingIndex: null,
    isAddApplianceBtnHovered: null,
    isLoaded: null,
  },
  originalAppliances: '',
};

interface ICircuitsSlidingPaneState {
  isLoaded?: boolean;
  sizePerPage?: number;
  associationState?: {
    currentlyEditingApplianceName?: any;
    appliances?: any;
    circuits?: any;
    editingIndex?: any;
    isAddApplianceBtnHovered?: any;
    isLoaded?: any;
  };
  originalAppliances: any;
}

export default function CircuitsSlidingPane({
  circuitsData,
  page = 1,
  onRequestClose,
  monitorTypes,
  setCircuitData,
  siteId = null,
  onReloadCircuitData = null,
  isOpen = true,
}) {
  const [state, setState] = useState<ICircuitsSlidingPaneState>(INITIAL_STATE);
  const totalSize = circuitsData.length;
  const columns = buildColumns();

  useEffect(() => {
    async function initialiseCircuits() {
      const childComponentState = {
        circuits: [],
        appliances: [],
        isLoaded: false,
        isAddApplianceBtnHovered: false,
      };

      let circuits = circuitsData;

      const { jwtToken } = await getTokenAndEmailFromSession();
      const appliances = await API.get('appliances', '/site/sites/' + siteId + '/appliances', {
        headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
      });

      appliances.forEach((appliance, i) => {
        childComponentState[`droppable-${i}`] = circuits.filter((circuit) => appliance.circuits.includes(circuit.id));

        // Filter out any circuits we just added to this droppable field
        circuits = circuits.filter((circuit) => !appliance.circuits.includes(circuit.id));
      });

      setState({
        ...state,
        isLoaded: true,
        originalAppliances: cloneDeep(appliances),
        associationState: {
          ...childComponentState,
          circuits,
          appliances,
        },
      });
    }

    initialiseCircuits();
  }, []);

  function buildColumns() {
    return CONFIG.columns.map((column) => {
      // Clone object so as to not modify the original
      const newColumn = { ...column };

      if (newColumn.inlineEditType === 'change_monitor_type') {
        newColumn.editor = {
          type: Type.SELECT,
          options: monitorTypes,
        };
      }

      return newColumn;
    });
  }

  async function onClickSave() {
    toast.success('Successfully saved circuit & appliance circuitsData.');

    if (!isEqual(state.associationState.appliances, state.originalAppliances)) {
      const { jwtToken } = await getTokenAndEmailFromSession();
      await post('appliances', `/site/sites/${siteId}/refresh_appliances_history`, {}, jwtToken);
    }

    onRequestClose();
  }

  function handleInlineEdit() {
    setCircuitData(circuitsData);
  }

  const setAssociationState = (associationState) => {
    setState({
      ...state,
      associationState,
    });
  };

  const ContentTable = () => {
    return (
      <PaginationProvider
        pagination={paginationFactory({
          page,
          sizePerPage: state.sizePerPage,
          totalSize,
          firstPageText: 'First',
          prePageText: 'Back',
          nextPageText: 'Next',
          lastPageText: 'Last',
          nextPageTitle: 'First page',
          prePageTitle: 'Pre page',
          firstPageTitle: 'Next page',
          lastPageTitle: 'Last page',
          showTotal: true,
        })}
      >
        {({ paginationTableProps }) => (
          <div>
            <BootstrapTable
              keyField={'id'}
              columns={columns}
              data={circuitsData}
              cellEdit={cellEditFactory({ mode: 'click', afterSaveCell: handleInlineEdit, blurToSave: true })}
              {...paginationTableProps}
            />
            <div className="float-left-custom">
              <select
                className="pageSizeList"
                value={state.sizePerPage}
                onChange={(e) => setState({ ...state, sizePerPage: Number(e.currentTarget.value) })}
              >
                {SIZE_PER_PAGE.map((item, sizePerPageIndex) => (
                  <option key={`size-per-page-option-${sizePerPageIndex}`} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            {state.isLoaded && (
              <CircuitApplianceAssociationDragDrop
                siteId={siteId}
                setAssociationState={setAssociationState}
                associationState={state.associationState}
              />
            )}

            <div className="d-flex float-right">
              <button className="btn btn-small btn-upload m-2" onClick={onClickSave}>
                <FontAwesomeIcon className="btn-img-pos" icon={faSave} size="xs" />
                Save
              </button>
              <button className="btn btn-small btn-upload m-2" onClick={() => onRequestClose()}>
                <FontAwesomeIcon className="btn-img-pos" icon={faTimes} size="xs" />
                Cancel
              </button>
            </div>
          </div>
        )}
      </PaginationProvider>
    );
  };

  return (
    <SlidingPane
      className="some-custom-class"
      overlayClassName="some-custom-overlay-class"
      isOpen={isOpen}
      title={'Site Circuits'}
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        onRequestClose();
      }}
    >
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 tariff-table">
          {onReloadCircuitData && (
            <>
              <Alert variant="warning">
                <Alert.Heading>
                  <h2>Note</h2>
                </Alert.Heading>
                <p>This button will only re-fetch circuit data from the Clipsal API.</p>
                <p>
                  To re-synchronise circuit data with the third party API (i.e. Wattwatchers, Solar Analytics) use the
                  &quot;Re-sync Circuits&quot; button on the site devices page.
                </p>
                <Button
                  style={{ margin: '1rem 0', height: '50px', width: '200px' }}
                  size={'lg'}
                  variant="success"
                  type="button"
                  onClick={onReloadCircuitData}
                >
                  Reload Circuit Data <FiRefreshCw style={{ marginLeft: '0.25rem' }} size={18} />
                </Button>
              </Alert>
            </>
          )}
          {columns.length && <ContentTable />}
          <Loader loaded={state.isLoaded} />
        </div>
      </div>
      <br />
    </SlidingPane>
  );
}

const CONFIG = {
  columns: [
    {
      dataField: 'clipsal_monitors',
      text: 'Monitor',
      sortCaret: sortCaretFormatter,
      data_type: 'string',
      editable: true,
      inlineEditType: 'change_monitor_type',
    },
    { dataField: 'oem_circuit_id', text: 'Circuit ID', sort: true, data_type: 'string', editable: false },
    { dataField: 'site_id', text: 'Site ID', sort: true, data_type: 'string', editable: false },
    { dataField: 'circuit_name', text: 'Circuit Name', sort: true, data_type: 'string', editable: true },
    { dataField: 'device_manufacturer', text: 'Device Manufacturer', sort: true, data_type: 'string', editable: false },
    {
      dataField: 'controlled_load_yn',
      text: 'Controlled Load',
      sortCaret: sortCaretFormatter,
      data_type: 'string',
      editable: true,
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: 'Y',
            label: 'Y',
          },
          {
            value: 'N',
            label: 'N',
          },
        ],
      },
    },
    {
      dataField: 'controlled_load2_yn',
      text: 'Controlled Load 2',
      sortCaret: sortCaretFormatter,
      data_type: 'string',
      editable: true,
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: 'Y',
            label: 'Y',
          },
          {
            value: 'N',
            label: 'N',
          },
        ],
      },
    },
  ],
};

function sortCaretFormatter(order) {
  const orderToIcon = {
    asc: 'sort-up',
    desc: 'sort-down',
  };

  return <>{orderToIcon[order] ?? 'sort'}</>;
}
