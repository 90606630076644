import 'react-toastify/dist/ReactToastify.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { ChakraProvider } from '@chakra-ui/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faFlag, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { useAuthContext } from './context/AuthContext';
import Routes from './Routes';
import customTheme from './styles/theme';
import { displayAPIErrorMessage } from './common/utils-helper';
library.add(faFlag, faSort, faSortUp, faSortDown, faCogs);

const App = () => {
  const { isAuthenticated, authenticateUser, changeIsAuthenticated } = useAuthContext();

  useEffect(() => {
    try {
      authenticateUser();
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }, [isAuthenticated]);

  /* For legacy code support, some component still use this */
  const userHasAuthenticated = (authenticated: boolean) => {
    changeIsAuthenticated(authenticated);
  };

  /* For legacy code support, some component still use this */
  const childProps = {
    isAuthenticated,
    userHasAuthenticated,
  };

  return (
    <ChakraProvider theme={customTheme}>
      <Routes childProps={childProps} />
      <ToastContainer />
    </ChakraProvider>
  );
};

export default App;
