import { API } from 'aws-amplify';
import React, { Component } from 'react';
import Loader from 'react-loader';
import { toast } from 'react-toastify';

import { getTokenAndEmailFromSession } from '../../common/api-utils';
import { displayAPIErrorMessage } from '../../common/utils-helper';
import Sidebar from '../../components/Sidebar';
import Table, { HandleTableChangeProps, SortOrder } from '../../components/table/Table';
import TableSearch from '../../components/table/TableSearch';
import Header from '../../components/TopNav';

const INITIAL_STATE = {
  page: 1,
  data: [],
  offset: 0,
  sizePerPage: 10,
  totalSize: 0,
  orderBy: 'tariff_id',
  sortOrder: 'asc' as SortOrder,
  loaded: false,
  expandFetchData: [],
  selectedTariffType: '',
  isPaneOpen: false,
  searchData: [],
  expandedTariffId: 0,
  searchTerm: null,
  expandedTariffType: null,
  subTableData: {
    tou: [],
    flat: [],
    tiered: [],
  },
  isUploaded: null,
};

export default class Tariffs extends Component {
  state = INITIAL_STATE;

  setAsyncState = (newState) => this.setState(newState);

  /* setAsyncState = (newState) => new Promise((resolve) => this.setState(newState, resolve)); */

  async componentDidMount() {
    const { jwtToken } = await getTokenAndEmailFromSession();
    const { data, item_count: totalSize } = await this.fetchTariffs(jwtToken, this.state.offset);

    this.setState({
      ...this.state,
      data,
      totalSize,
      loaded: true,
    });
  }

  async fetchTariffs(token?, offset?, orderBy?, sortOrder?, searchValue?) {
    if (sortOrder === 'desc') orderBy = `-${orderBy}`;
    return API.get(
      'tariffs',
      '/tariff/tariffs' +
        `?limit=${this.state.sizePerPage}` +
        `&offset=${offset}` +
        `&ordering=${orderBy || 'tariff_id'}` +
        (searchValue ?? this.state.searchTerm ? `&search_term=${searchValue ?? this.state.searchTerm}` : ``),
      { headers: { 'Content-Type': 'application/json', Authorization: token } }
    );
  }

  async deleteTariff(token, tariffId) {
    return API.del('sites', `/tariff/tariffs/${tariffId}`, {
      headers: { 'Content-Type': 'application/json', Authorization: token },
    });
  }

  handleTableChange = async ({ page, sizePerPage, sortField, sortOrder }: HandleTableChangeProps) => {
    try {
      await this.setAsyncState({
        ...this.state,
        loaded: false,
      });

      const { jwtToken } = await getTokenAndEmailFromSession();
      const offset = (page - 1) * sizePerPage;
      const { data } = await this.fetchTariffs(jwtToken, offset, sortField, sortOrder);

      this.setState({
        ...this.state,
        page,
        offset,
        orderBy: sortField,
        sortOrder,
        data,
        sizePerPage,
        loaded: true,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  handleDeleteTariff = async (tariff) => {
    try {
      const tariffId = tariff.tariff_id;
      const name = tariff.tariff_plan_name;
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      await this.deleteTariff(token, tariffId);

      toast.success(`Tariff plan ${name} successfully deleted`, {
        autoClose: 5000,
      });

      this.setState({
        ...this.state,
        data: this.state.data.filter((item) => tariff.tariff_id !== item.tariff_id),
        totalSize: this.state.totalSize - 1,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  handleExpandFetch = async (type, row) => {
    const expandedTariffType = row.tariff_type.toLowerCase();
    const { jwtToken } = await getTokenAndEmailFromSession();

    const expandFetchData = await API.get(
      'tariff_rates',
      '/tariff/tariffs/' + row.tariff_id + `/${expandedTariffType}_rates`,
      {
        headers: { 'Content-Type': 'application/json', Authorization: jwtToken },
      }
    );

    this.setState({
      expandedSiteId: row.clipsal_solar_id,
      expandedTariffType,
      expandFetchData,
    });
  };

  handleTableSearch = async (searchTerm) => {
    try {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const args = [jwtToken, 0, this.state.orderBy, this.state.sortOrder];

      if (searchTerm || searchTerm === '') {
        args.push(searchTerm);
      }

      const { data, item_count: totalSize } = await this.fetchTariffs(...args);

      this.setState({
        ...this.state,
        data,
        totalSize,
        offset: 0,
        searchTerm: searchTerm || null,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  handleChangePageSize = async (e) => {
    try {
      await this.setAsyncState({
        ...this.state,
        loaded: false,
        sizePerPage: Number(e.currentTarget.value),
      });

      const { jwtToken } = await getTokenAndEmailFromSession();
      const { data } = await this.fetchTariffs(jwtToken, this.state.offset);

      this.setState({
        ...this.state,
        data,
        loaded: true,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  };

  render() {
    return (
      <div>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <div role="main" className="content-panel">
              <div className="content-section">
                <div className="content-head">
                  <div className="border-bottom-gray d-flex">
                    <span className="header-span">Tariffs</span>
                    <div className="data-brief pull-right text-right">
                      <div className="pull-right">
                        <p>Total Tariffs</p>
                        <h2>{this.state.totalSize}</h2>
                      </div>
                    </div>
                  </div>
                  <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#/tariffs">Clipsal</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Tariffs
                      </li>
                    </ol>
                  </nav>
                </div>

                <div>
                  <TableSearch onSearch={this.handleTableSearch} containerStyle={{ width: '25%', margin: '1rem 0' }} />
                  <Table
                    onChangePageSize={this.handleChangePageSize}
                    remote
                    expandType={this.state.expandedTariffType}
                    tableConfig={TARIFF_TABLE_CONFIG}
                    data={this.state.data}
                    page={this.state.page}
                    sortOrder={this.state.sortOrder}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    type="tariff"
                    onTableChange={this.handleTableChange}
                    onHandleAction={this.handleDeleteTariff}
                    onExpandFetch={this.handleExpandFetch}
                    expandFetchData={this.state.expandFetchData}
                  />
                  <Loader loaded={this.state.loaded} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TARIFF_TABLE_CONFIG = {
  keyField: 'tariff_id',
  expand: true,
  columns: [
    { dataField: 'tariff_id', text: 'Tariff ID', sort: true, data_type: 'int' },
    { dataField: 'tariff_plan_name', text: 'Plan Name', sort: true, data_type: 'string' },
    { dataField: 'tariff_type', text: 'Tariff Type', sort: true, data_type: 'string' },
    { dataField: 'distributor_id', text: 'Distributor ID', sort: true, data_type: 'int' },
    { dataField: 'retailer.retailer_name', text: 'Retailer', data_type: 'object', search: true, alias: 'r' },
    { dataField: 'state', text: 'State', sort: true, data_type: 'string' },
    { dataField: 'country', text: 'Country', sort: true, data_type: 'string' },
    {
      dataField: 'associated_sites',
      text: 'Associated Sites',
      data_type: 'string',
      formatterType: 'fetchFromObj',
      formatterDetails: { type: 'primitiveArrayToString' },
    },
    { dataField: 'row_updated', text: 'Updated On', sort: true, data_type: 'date' },
    {
      dataField: 'action',
      text: 'Action',
      formatterType: 'action',
      formatterDetails: [
        { name: 'Edit', action: '#/tariffs/:id/edit', params: [{ key: 'tariff', value: 'tariff_id' }] },
        {
          name: 'Delete',
          action: 'functioncall',
          value: 'delete_tariff',
          displayValue: 'tariff_plan_name',
          messageNeeded: true,
        },
      ],
    },
  ],
  expandConfig: {
    otherList: [
      { label: 'User Name', value: 'user_name' },
      { label: 'Create On', value: 'row_created' },
      { label: 'Updated On', value: 'row_updated' },
    ],
    recomendationList: [
      { label: 'Use It Or Loose It', value: 'use_it_loose_it' },
      { label: 'Tier Notification', value: 'tier_notification_yn' },
    ],
  },
};
