import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Loader from 'react-loader';
import { Prompt } from 'react-router';
import { toast } from 'react-toastify';

import { del, get, patch, post } from '../../common/api-utils';
import { getTokenAndEmailFromSession } from '../../common/api-utils';
import { displayAPIErrorMessage } from '../../common/utils-helper';
import ContentWrapper from '../../components/ContentWrapper';
import CommonTariffForm, {
  AGGREGATION_PERIODS,
  EMPTY_DISCOUNT_ROW,
  INITIAL_COMMON_FORM_VALUES,
} from './forms/CommonTariffForm';
import FlatTariffForm from './forms/FlatTariffForm';
import TieredTariffForm from './forms/TieredTariffForm';
import TOUTariffForm from './forms/TOUTariffForm';

const INITIAL_STATE = {
  isLoaded: false,
  isLoading: false,
  isEditing: false,
  retailers: [],
  tariffId: null,
  tariff: null,
  distributors: [],
  discounts: [],
  discountTypes: [],
  commonFormValues: INITIAL_COMMON_FORM_VALUES,
  aggregationPeriod: '', // Only applies for tiered rates
};

const TARIFF_TYPES = ['Flat', 'Tiered', 'TOU'];

export default function Tariff(props) {
  const [state, setState] = useState(INITIAL_STATE);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const formRefValues = useRef(null);

  function getTariffTypeForm() {
    const type = state.commonFormValues.type;

    if (!state.tariffId) {
      return <></>;
    }

    const subComponentProps = { tariffId: state.tariffId };

    const typeToComponent = {
      tou: <TOUTariffForm {...subComponentProps} />,
      tiered: <TieredTariffForm aggregationPeriod={state.aggregationPeriod} {...subComponentProps} />,
      flat: <FlatTariffForm {...subComponentProps} />,
    };

    return typeToComponent[type.toLowerCase()];
  }

  async function handleCommonFormSubmit(commonFormValues) {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const { jwtToken: token, email } = await getTokenAndEmailFromSession();
      const tariffModel = buildTariffModelFromFormValues(commonFormValues, state.distributors, email);

      if (state.isEditing) {
        tariffModel.tariff_id = state.tariffId;
        await patch('tariffs', `/tariff/tariffs/${state.tariffId}`, tariffModel, token);

        const discountData = commonFormValues.discountFormData
          .filter((data) => data.discountType && data.value) // Filter any empty rows not caught by validation
          .map((discountData) => ({
            row_id: discountData.id,
            discount_type_id: Number(discountData.discountType),
            unit: discountData.unit,
            discount: discountData.value,
            tariff_id: state.tariffId,
          }));

        // Update existing tariffs, create new ones (ones without an id)
        for await (const discount of discountData) {
          if (discount.row_id) {
            await patch('discounts', `/tariff/tariffs/${state.tariffId}/discounts/${discount.row_id}`, discount, token);
          } else {
            const newDiscount = { ...discount };
            delete newDiscount.row_id;
            await post('discounts', `/tariff/tariffs/${state.tariffId}/discounts`, newDiscount, token);
          }
        }

        // Delete removed discounts
        const deletedDiscounts = state.discounts.filter(
          (discount) => !discountData.find((d) => d.row_id === discount.row_id)
        );

        for await (const discount of deletedDiscounts) {
          await del('discounts', `/tariff/tariffs/${state.tariffId}/discounts/${discount.row_id}`, token);
        }

        // Re-fetch discounts, apply them to form
        const discounts = await get('discounts', `/tariff/tariffs/${state.tariffId}/discounts`, token);

        let newDiscountFormData = discountData;

        if (discounts.length) {
          newDiscountFormData = discounts.map((discount) => ({
            id: discount.row_id,
            discountType: discount.discount_type_id,
            unit: discount.unit,
            value: discount.discount,
          }));
        }
        const newCommonFormValues = {
          ...commonFormValues,
          discountData: newDiscountFormData,
        };
        // @TODO: test that ids are correctly injected into form?
        setState({
          ...state,
          commonFormValues: newCommonFormValues,
        });
        setHasUnsavedChanges(false);
      } else {
        delete tariffModel.tariff_id;
        const { tariff_id: tariffId } = await post('tariffs', `/tariff/tariffs`, tariffModel, token);

        const discountData = commonFormValues.discountFormData
          .filter((data) => data.discountType && data.value) // Filter any empty rows not caught by validation
          .map((discountData) => ({
            discount_type_id: Number(discountData.discountType),
            unit: discountData.unit,
            discount: discountData.value,
            tariff_id: tariffId,
          }));

        // All discounts are new if we're creating a new tariff
        for await (const discount of discountData) {
          await post('discounts', `/tariff/tariffs/${tariffId}/discounts`, discount, token);
        }

        setState({
          ...state,
          isEditing: true,
          commonFormValues,
          tariffId,
        });

        setHasUnsavedChanges(false);

        props.history.push(`/tariffs/${tariffId}/edit`);
      }
      setState((prevState) => ({ ...prevState, isLoading: false }));
      toast.success('👍 Successfully saved tariff');
    } catch (e) {
      displayAPIErrorMessage(e);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }

  function handleChangeAggregationPeriod(aggregationPeriodId) {
    const aggregationPeriod = AGGREGATION_PERIODS?.[aggregationPeriodId - 1] || '';

    setState({
      ...state,
      aggregationPeriod,
    });
  }

  useEffect(() => {
    /* Get Id from the params */
    const tariffId = Number(props.match.params.id);
    setState((prevState) => ({ ...prevState, tariffId }));

    async function fetchAPI() {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      const distributors = await get('distributors', '/tariff/distributors', token);
      const discountTypes = await get('discounts', `/tariff/discounttypes`, token);
      const retailers = await get('retailers', '/tariff/retailers', token);

      let newState = {
        ...state,
        retailers,
        distributors,
        discountTypes,
        isLoaded: true,
      };

      if (tariffId) {
        const tariff = await get('tariff', `/tariff/tariffs/${tariffId}`, token);
        const discounts = await get('discounts', `/tariff/tariffs/${tariffId}/discounts`, token);
        const commonFormValues = extractFormValuesFromTariffModel(tariff, discounts, distributors);

        newState = {
          ...newState,
          commonFormValues,
          discounts,
          aggregationPeriod: AGGREGATION_PERIODS[commonFormValues.aggregationPeriod - 1],
          tariff,
          isEditing: true,
          tariffId: tariff.tariff_id,
        };
      }

      setState(newState);
    }

    if (!state.isLoaded) {
      fetchAPI();
    }
  }, [props.match.params.id]);

  const initialTariffData = {
    aggregationPeriod: state.commonFormValues.aggregationPeriod,
    controlledLoad1: state.commonFormValues.controlledLoad1,
    controlledLoad1Rate: state.commonFormValues.controlledLoad1Rate,
    controlledLoad1SupplyCharge: state.commonFormValues.controlledLoad1SupplyCharge,
    controlledLoad2: state.commonFormValues.controlledLoad2,
    controlledLoad2Rate: state.commonFormValues.controlledLoad2Rate,
    controlledLoad2SupplyCharge: state.commonFormValues.controlledLoad2SupplyCharge,
    description: state.commonFormValues.description,
    discountFormData: state.commonFormValues.discountFormData,
    distributor: state.commonFormValues.distributor,
    name: state.commonFormValues.name,
    retailer: state.commonFormValues.retailer,
    state: state.commonFormValues.state,
    supplyCharge: state.commonFormValues.supplyCharge,
    type: state.commonFormValues.type,
  };

  useEffect(() => {
    function handleWindowUnload(e) {
      if (hasUnsavedChanges && !isEqual(initialTariffData, formRefValues.current.values)) {
        e.preventDefault();
        e.returnValue = '';
        return;
      }

      delete e['returnValue'];
    }

    window.addEventListener('beforeunload', handleWindowUnload);

    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <>
      <Prompt
        when={hasUnsavedChanges}
        message="You have unsaved changes in the tariff, are you sure you want to leave?"
      />

      <ContentWrapper
        breadcrumbs={[{ title: 'Tariffs', url: '#/tariffs' }, { title: `${state.isEditing ? 'Edit' : 'Add'} Tariff` }]}
        title={`${state.isEditing ? 'Edit' : 'Add'} Tariff`}
      >
        {state.isLoaded ? (
          <>
            <div
              style={{
                padding: '3rem',
                marginBottom: '2rem',
                width: '80%',
              }}
            >
              <CommonTariffForm
                isLoading={state.isLoading}
                isEditing={state.isEditing}
                hasUnsavedChanges={hasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
                formRefValues={formRefValues}
                onAggregationPeriodIdChange={handleChangeAggregationPeriod}
                discountTypes={state.discountTypes}
                initialFormValues={state.commonFormValues}
                distributors={state.distributors}
                retailers={state.retailers}
                types={TARIFF_TYPES}
                onCommonFormSubmit={handleCommonFormSubmit}
              />
              <hr />

              {state.isLoaded && <div style={{ margin: '2rem 0' }}>{getTariffTypeForm()}</div>}
            </div>
          </>
        ) : (
          <Loader loaded={false} />
        )}
        <Loader loaded={!state.isLoading} />
      </ContentWrapper>
    </>
  );
}

// @TODO: probably just worth mapping the model to form state?
function extractFormValuesFromTariffModel(tariff, discounts, distributors) {
  const distributor = distributors.find((distributor) => distributor.id === tariff.distributor_id);

  let discountFormData = [EMPTY_DISCOUNT_ROW];

  if (discounts.length) {
    discountFormData = discounts.map((discount) => ({
      id: discount.row_id,
      discountType: discount.discount_type_id,
      unit: discount.unit,
      value: discount.discount,
    }));
  }

  return {
    ...INITIAL_COMMON_FORM_VALUES,
    name: tariff.tariff_plan_name ?? '',
    description: tariff.tariff_description ?? '',
    state: tariff.state ?? '',
    distributor: distributor?.id ?? '',
    type: tariff.tariff_type ?? '',
    retailer: tariff.retailer.retailer_id ?? '',
    supplyCharge: tariff.tariff_supply_charge_cents_per_day ?? '',
    aggregationPeriod: tariff.aggregation_period_id ?? '',
    controlledLoad1: tariff.controlled_load_yn === 'Y',
    controlledLoad1Rate: tariff.controlled_load_rate_per_kwh_in_cents ?? '',
    controlledLoad1SupplyCharge: tariff.controlled_load_supply_charge_cents_per_day ?? '',
    controlledLoad2: tariff.controlled_load2_yn === 'Y',
    controlledLoad2Rate: tariff.controlled_load2_rate_per_kwh_in_cents ?? '',
    controlledLoad2SupplyCharge: tariff.controlled_load2_supply_charge_cents_per_day ?? '',
    discountFormData,
  };
}

function buildTariffModelFromFormValues(tariffFormValues, distributors, createdBy) {
  const distributor = distributors.find(
    (distributor) => Number(distributor.id) === Number(tariffFormValues.distributor)
  );

  return {
    tariff_plan_name: tariffFormValues.name,
    tariff_description: tariffFormValues.description,
    state: tariffFormValues.state,
    source_id: 1,
    aggregation_period_id: Number(tariffFormValues.aggregationPeriod) || null,
    created_by: createdBy,
    tariff_id: null,
    distributor_id: distributor.id,
    tariff_type: tariffFormValues.type,
    retailer_id: Number(tariffFormValues.retailer),
    tariff_supply_charge_cents_per_day: tariffFormValues.supplyCharge,
    controlled_load_yn: tariffFormValues.controlledLoad1 ? 'Y' : 'N',
    controlled_load_rate_per_kwh_in_cents: tariffFormValues.controlledLoad1Rate || 0,
    controlled_load_supply_charge_cents_per_day: tariffFormValues.controlledLoad1SupplyCharge || 0,
    controlled_load2_yn: tariffFormValues.controlledLoad2 ? 'Y' : 'N',
    controlled_load2_rate_per_kwh_in_cents: tariffFormValues.controlledLoad2Rate || 0,
    controlled_load2_supply_charge_cents_per_day: tariffFormValues.controlledLoad2SupplyCharge || 0,
  };
}
