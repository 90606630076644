import { DeviceMetadata, DeviceType, Manufacturer } from '../../../api/api-device-metadata';
import { get, getTokenAndEmailFromSession } from '../../../common/api-utils';

export const fetchManufacturers = async (deviceType: DeviceType): Promise<Manufacturer[]> => {
  const { jwtToken } = await getTokenAndEmailFromSession();
  const { data }: { data: Manufacturer[] } = await get(
    'site-devices',
    `/device/manufacturers?device_type=${deviceType}`,
    jwtToken
  );
  return data;
};

export const fetchModelsByManufacturerId = async (
  manufacturerId: string,
  deviceType: DeviceType
): Promise<Manufacturer[]> => {
  const { jwtToken } = await getTokenAndEmailFromSession();
  const { data: models }: { data: DeviceMetadata[] } = await get(
    'site-devices',
    `/device/device_metadata?device_type=${deviceType}&manufacturer_id=${manufacturerId}`,
    jwtToken
  );
  return models.map((model) => ({
    manufacturer_id: Number(model.device_metadata_id),
    manufacturer_name: model.model,
  }));
};
