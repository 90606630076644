import { API } from 'aws-amplify';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import Select from 'react-select';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import CenteredLoader from '../../../components/CenteredLoader';

import { getTokenAndEmailFromSession } from '../../../common/api-utils';
import { displayAPIErrorMessage, formatDate, formValidation } from '../../../common/utils-helper';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const INITIAL_FORM_STATE = {
  retailerOptions: [],
  retailer: null,
  start_date: null,
  end_date: null,
  total_amount: null,
  consumption: null,
};

const INITIAL_STATE = {
  isLoaded: false,
  fetchError: null,
  currentBillId: null,
  currentBillBase64: null,
  hoveredBillIndex: null,
  formData: INITIAL_FORM_STATE,
};

const VALIDATION_MODEL = [
  { value: 'retailer', label: 'Retailer', required: true },
  { value: 'start_date', label: 'Start date', required: true },
  { value: 'end_date', label: 'End date', required: true },
  { value: 'total_amount', label: 'Total amount', required: true },
  { value: 'consumption', label: 'Consumption', required: true },
];

/**
 * - List bills, allow edit of existing bill data
 */
export default function BillsSlidingPane({ onRequestClose, siteId, bills, onUpdateBills, initiallySelectedBillId }) {
  const [state, setState] = useState(INITIAL_STATE);
  const [numPages, setNumPages] = useState(null);
  const ref = useRef(null);
  const handleDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          const result = String(reader.result).replace('data:application/pdf;base64,', '');

          setState({
            ...state,
            formData: { ...state.formData, retailerOptions: state.formData.retailerOptions },
            currentBillId: null,
            currentBillBase64: result,
          });
        };

        reader.readAsDataURL(file);
      });
    },
    [state]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop, accept: 'application/pdf', maxFiles: 1 });

  useEffect(() => {
    async function fetchBillData() {
      try {
        const { jwtToken: token } = await getTokenAndEmailFromSession();
        const options = { headers: { 'Content-Type': 'application/json', Authorization: token } };
        const retailers = await API.get('retailers', '/tariff/retailers', options);
        const retailerOptions = retailers.map((retailer) => ({
          label: retailer.retailer_name,
          value: retailer.retailer_id,
        }));

        if (initiallySelectedBillId) {
          const bill = bills?.find((stateBill) => stateBill.id === initiallySelectedBillId);
          const { base64_file: currentBillBase64 } = await API.get(
            'bill_view',
            `/site/bills/${initiallySelectedBillId}/view`,
            {
              headers: { 'Content-Type': 'application/json', Authorization: token },
            }
          );
          const { start_date, end_date, total_amount, consumption, id } = bill;

          setState({
            ...state,
            formData: {
              ...state.formData,
              retailerOptions,
              retailer: retailerOptions.find((option) => option.value !== bill.retailer_id),
              start_date: new Date(start_date),
              end_date: new Date(end_date),
              total_amount,
              consumption,
            },
            currentBillId: id,
            currentBillBase64,
            isLoaded: true,
          });
        } else {
          setState({
            ...state,
            formData: {
              ...state.formData,
              retailerOptions,
            },
            isLoaded: true,
          });
        }
      } catch (e) {
        displayAPIErrorMessage(e);
        setState({
          ...state,
          fetchError: e.message,
        });
      }
    }

    if (!state.isLoaded) {
      fetchBillData();
    }
  }, []);

  function buildBillModel() {
    return {
      id: Number(state.currentBillId),
      clipsal_solar_id: siteId,
      retailer_id: state.formData.retailer.value,
      type: 'electricity',
      start_date: formatDate(state.formData.start_date),
      end_date: formatDate(state.formData.end_date),
      total_amount: state.formData.total_amount,
      consumption: state.formData.consumption,
      currency: 'AUD',
      doc_type: 'searchable',
      source: 'manual',
    };
  }

  function handleSelectBill(billId: number) {
    return async () => {
      // @TODO: Check if formData empty

      const { jwtToken: token } = await getTokenAndEmailFromSession();
      const bill = bills.find((stateBill) => stateBill.id === billId);
      const { base64_file: currentBillBase64 } = await API.get('bill_view', `/site/bills/${billId}/view`, {
        headers: { 'Content-Type': 'application/json', Authorization: token },
      });
      const { start_date, end_date, total_amount, consumption, id } = bill;
      setState({
        ...state,
        formData: {
          ...state.formData,
          retailer: state.formData.retailerOptions.find((option) => option.value === bill.retailer_id),
          start_date: new Date(start_date),
          end_date: new Date(end_date),
          total_amount,
          consumption,
        },
        currentBillId: id,
        currentBillBase64,
      });
    };
  }

  async function handleSubmitForm(e) {
    e.preventDefault();

    // This validation could be greatly improved once the forms are overhauled.
    const errorMessage = formValidation(state.formData, VALIDATION_MODEL);
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 5000 });
      return;
    }

    if (!state.currentBillBase64) {
      toast.error('Ensure a bill is uploaded before submitting', { autoClose: 5000 });
      return;
    }

    try {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      const options = {
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: buildBillModel(),
      };

      let message;
      if (state.currentBillId) {
        // We're editing
        await API.patch('bill', `/site/bills/${state.currentBillId}`, options);
        await API.post('bill', `/site/bills/${state.currentBillId}/upload`, {
          ...options,
          body: state.currentBillBase64,
        });
        message = 'Successfully updated bill.';
      } else {
        const body = { ...options.body };
        delete body.id;
        const newBill = await API.post('bills', `/site/bills`, { ...options, body });
        await API.post('bill', `/site/bills/${newBill.id}/upload`, { ...options, body: state.currentBillBase64 });
        message = 'Successfully uploaded new bill.';
        setState({
          ...state,
          currentBillId: newBill.id,
        });

        onUpdateBills([...bills, newBill]);
      }

      toast.success(message, {
        autoClose: 5000,
      });
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  function handleInputChange(e) {
    setState({
      ...state,
      formData: {
        ...state.formData,
        [e.currentTarget.id]: e.currentTarget.value,
      },
    });
  }

  function handleDatePickerChange(fieldName) {
    return (date) => {
      setState({
        ...state,
        formData: {
          ...state.formData,
          [fieldName]: date,
        },
      });
    };
  }

  return (
    <SlidingPane
      isOpen
      title={'Browse Bills for Site'}
      onRequestClose={() => {
        // @TODO: inform user of unsaved changes
        // triggered on "<" on left top click or on outside click
        onRequestClose();
      }}
    >
      {state.isLoaded ? (
        <div style={getContainerStyle()}>
          <div style={{ backgroundColor: '#0d1d25', color: 'white', padding: '0.5rem' }}>
            <div style={getDropzoneStyle()} {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag &apos;n&apos; drop PDF bill here, or click to browse files</p>
              <p>Only .pdf files will be accepted.</p>
              <p>Note that uploading a bill file will clear the current bill form.</p>
            </div>

            <div style={{ overflow: 'auto' }}>
              {bills?.map((bill, billIndex) => (
                <div
                  key={`bill-${billIndex}`}
                  onClick={handleSelectBill(bill.id)}
                  onMouseEnter={() => setState({ ...state, hoveredBillIndex: billIndex })}
                  onMouseLeave={() => setState({ ...state, hoveredBillIndex: null })}
                  style={getBillListItemStyle(state.hoveredBillIndex === billIndex || state.currentBillId === bill.id)}
                >
                  <h3>{bill.retailer.retailer_name}</h3>
                  <div>{getBillDateRange(bill)}</div>
                </div>
              ))}
            </div>
          </div>

          <div>
            {/* Form container */}
            <form onSubmit={handleSubmitForm}>
              <div style={getFormContainerStyle()}>
                <div>
                  <label>Select retailer</label>
                  <Select
                    onChange={(selectedRetailer) => {
                      setState({
                        ...state,
                        formData: {
                          ...state.formData,
                          retailer: selectedRetailer,
                        },
                      });
                    }}
                    id="retailer"
                    options={state.formData.retailerOptions}
                    placeholder="Select retailer"
                    value={state.formData.retailer ?? ''}
                  />
                </div>

                <div>
                  <label className="label-zindex">Start date</label>
                  <DatePicker
                    className="custom_datepicker"
                    id="start_date"
                    placeholder="Start date"
                    name="start_date"
                    dateFormat="dd-MM-yyyy"
                    autoComplete="off"
                    selected={state.formData.start_date ?? ''}
                    onChange={handleDatePickerChange('start_date')}
                  />
                </div>

                <div>
                  <label className="label-zindex">End date</label>
                  <DatePicker
                    className="custom_datepicker"
                    id="end_date"
                    placeholder="End date"
                    name="end_date"
                    dateFormat="dd-MM-yyyy"
                    autoComplete="off"
                    selected={state.formData.end_date ?? ''}
                    onChange={handleDatePickerChange('end_date')}
                  />
                </div>

                <div>
                  <label className="label-zindex">Total bill cost ($)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="total_amount"
                    placeholder="Total bill cost ($)"
                    value={state.formData.total_amount ?? ''}
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label className="label-zindex">Total bill consumption (kWh)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="consumption"
                    placeholder="Total consumption (kWh)"
                    value={state.formData.consumption ?? ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <button style={{ margin: '2rem' }} className="btn btn-upload" type="submit">
                <FontAwesomeIcon icon={faSave} className="btn-img-pos" />
                Save
              </button>
            </form>

            {state.currentBillBase64 ? (
              <div style={{ background: 'grey', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Document
                  ref={ref}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  file={validateBase64PDF(state.currentBillBase64)}
                >
                  {[...Array(numPages)]
                    .map((x, i) => i + 1)
                    .map((page, i) => (
                      <div style={{ margin: '50px 0' }} key={`pdf-page-${i}`}>
                        <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 600, color: 'white' }}>
                          Page {page}
                        </div>
                        <Page pageNumber={page} />
                      </div>
                    ))}
                </Document>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4>Upload a bill in the top-right to view its PDF here</h4>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {state.fetchError ? (
            <div>
              <h3>Something went wrong. Try again or contact an administrator.</h3>
            </div>
          ) : (
            <CenteredLoader />
          )}
        </div>
      )}
    </SlidingPane>
  );
}

function getBillDateRange(bill) {
  return `${bill.start_date} - ${bill.end_date}`;
}

function validateBase64PDF(base64PDF) {
  if (!base64PDF.includes('data:application/pdf;base64,')) {
    return `data:application/pdf;base64,${base64PDF}`;
  }

  return base64PDF;
}

const getBillListItemStyle = (isHovered) => ({
  margin: '1rem',
  backgroundColor: isHovered ? 'white' : undefined,
  color: isHovered ? 'black' : undefined,
  cursor: 'pointer',
  border: '1px solid green',
});

const getContainerStyle = () => ({
  display: 'grid',
  height: '100%',
  gridTemplateColumns: '0.3fr 0.7fr',
});

const getFormContainerStyle = () => ({
  display: 'grid',
  gridGap: '0.5rem',
  gridTemplateColumns: 'repeat(4, 1fr)',
  padding: '2rem',
});

const getDropzoneStyle = () => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px',
  border: '2px dotted white',
  margin: '0.5rem',
});
