import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { displayAPIErrorMessage } from '../../common/utils-helper';

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-z]/g, 'Password must have at least one lower-case letter.')
    .matches(/[A-Z]/g, 'Password must have at least one upper-case letter.')
    .matches(/\d/g, 'Password must have at least one number.')
    .matches(/[^A-Za-z0-9]/g, 'Password must have at least one special character (e.g. !@#$%^%^&...).')
    .required('This field is required'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Does not match!')
    .required('This field is required'),
});

const INITIAL_STATE = {
  isLoaded: false,
  isError: false,
  cognitoUser: null,
};

const INITIAL_FORM_VALUES = {
  newPassword: '',
  confirmNewPassword: '',
};

export default function CreateAccount(props) {
  const [state, setState] = useState(INITIAL_STATE);
  const { oldUsername, oldPassword } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  async function handleSubmit(values, actions) {
    actions.setSubmitting(true);

    try {
      await Auth.completeNewPassword(state.cognitoUser, values.newPassword, null);

      actions.setSubmitting(false);
      toast.success(`Successfully changed password.`);

      props.history.push('/');
    } catch (e) {
      displayAPIErrorMessage(e);
      actions.setSubmitting(false);
    }
  }

  useEffect(() => {
    async function fetchAPI() {
      if (!oldUsername || !oldPassword) {
        setState({
          ...state,
          isError: true,
        });
      } else {
        try {
          const cognitoUser = await Auth.signIn(oldUsername as string, oldPassword as string);

          setState({ ...state, cognitoUser, isLoaded: true });
        } catch (e) {
          displayAPIErrorMessage(e);
          setState({ ...state, isError: true });
        }
      }
    }

    fetchAPI();
  }, [oldUsername, oldPassword]);

  if (state.isError) {
    return (
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h2>Something went wrong. Please try again.</h2>
      </div>
    );
  }

  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {state.isLoaded ? (
        <Card style={{ width: '40%' }}>
          <Card.Header as="h1">Create a new password</Card.Header>
          <Card.Body>
            <h3>Reset Password</h3>
            <Formik
              validateOnChange
              validationSchema={schema}
              onSubmit={handleSubmit}
              initialValues={INITIAL_FORM_VALUES}
            >
              {({ isSubmitting, handleSubmit, handleChange, values, touched, errors }: any) => {
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="newPassword">
                        <Form.Label>New password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter new password"
                          name="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                          isInvalid={touched.newPassword && !!errors.newPassword}
                          isValid={touched.newPassword && !errors.newPassword}
                        />
                        <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="confirmNewPassword">
                        <Form.Label>Confirm new password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm new password"
                          name="confirmNewPassword"
                          value={values.confirmNewPassword}
                          onChange={handleChange}
                          isInvalid={touched.confirmNewPassword && !!errors.confirmNewPassword}
                          isValid={touched.confirmNewPassword && !errors.confirmNewPassword}
                        />
                        <Form.Control.Feedback type="invalid">{errors.confirmNewPassword}</Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Button
                      style={{ margin: '2rem 0', height: '50px', width: '100%' }}
                      size={'lg'}
                      variant="success"
                      type="submit"
                    >
                      Save password {isSubmitting && <Spinner animation="border" variant="primary" />}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      ) : (
        <Spinner style={{ width: '100px', height: '100px' }} animation="border" variant="success" />
      )}
    </div>
  );
}
