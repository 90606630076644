import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function TableConfirmationBox({ onConfirmAction, onCancelAction, confirmType, itemName }) {
  // @TODO: further formatting for confirm type may be necessary at some point to make this look nicer
  const message = `Are you sure you want to ${confirmType.includes('delete') ? 'delete' : confirmType} ${itemName}?`;

  return (
    <div className="overlay">
      <div className="confirmbox">
        <div className="innerbox">{message}</div>
        <div className="buttnbox">
          <button
            data-testid="table-confimation-yes"
            className="btn btn-upload"
            type="button"
            onClick={() => {
              onConfirmAction(confirmType);
            }}
          >
            <FontAwesomeIcon className="icon-btn-pos" icon={faCheck} />
            Yes
          </button>

          <span>&nbsp;&nbsp;</span>

          <button
            className="btn btn-upload"
            type="button"
            onClick={() => onCancelAction()}
            data-testid="table-confimation-no"
          >
            <FontAwesomeIcon className="icon-btn-pos" icon={faTimes} />
            No
          </button>
        </div>
      </div>
    </div>
  );
}
