import { FieldArray, Formik } from 'formik';
import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

export const AGGREGATION_PERIODS = ['Day', 'Month', 'Quarter', 'Year'];

export const EMPTY_DISCOUNT_ROW = { id: null, discountType: '', unit: '%', value: 0 };

export const INITIAL_COMMON_FORM_VALUES = {
  name: '',
  description: '',
  state: '',
  distributor: '',
  type: '',
  retailer: '',
  supplyCharge: '',
  aggregationPeriod: '',
  controlledLoad1: false,
  controlledLoad1Rate: '',
  controlledLoad1SupplyCharge: '',
  controlledLoad2: false,
  controlledLoad2Rate: '',

  controlledLoad2SupplyCharge: '',
  discountFormData: [EMPTY_DISCOUNT_ROW],
};

const schema = yup.object().shape({
  name: yup.string().required('*Name is required'),
  description: yup.string().required('*Description is required'),
  state: yup.string().required('*State is required'),
  distributor: yup.string().required('*Distributor is required'),
  type: yup.string().required('*Type is required'),
  retailer: yup.string().required('*Retailer is required'),
  supplyCharge: yup.string().required('*Supply Charge is required'),
  /* Field Array valdiation for optional but dependent fields */
  /* Value and Discount Type can be both empty or both filled */
  discountFormData: yup.array().of(
    yup.object().shape(
      {
        value: yup.number().when(['discountType'], {
          is: (discountType) => !!discountType,
          then: yup.number().min(0, '*Discount value is required'),
          otherwise: yup.number(),
        }),
        discountType: yup.mixed().when(['value'], {
          is: (value) => value > 0,
          then: yup.mixed().required('*Discount type is required'),
          otherwise: yup.mixed(),
        }),
      },
      [['discountType', 'value']]
    )
  ),
});

type Props = {
  initialFormValues: typeof INITIAL_COMMON_FORM_VALUES;
  onCommonFormSubmit: any;
  onAggregationPeriodIdChange: any;
  distributors: any;
  types: any;
  retailers: any;
  isEditing: any;
  discountTypes: any;
  setHasUnsavedChanges: any;
  formRefValues: any;
  isLoading: any;
};

export default function CommonTariffForm({
  initialFormValues,
  onCommonFormSubmit,
  onAggregationPeriodIdChange,
  distributors,
  types,
  retailers,
  isEditing,
  discountTypes,
  setHasUnsavedChanges,
  formRefValues,
  isLoading,
}: Props) {
  return (
    <Formik
      innerRef={formRefValues}
      validateOnChange
      validationSchema={schema}
      onSubmit={onCommonFormSubmit}
      initialValues={initialFormValues}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => {
        return (
          <Form
            style={{ marginBottom: '2rem' }}
            onChange={() => setHasUnsavedChanges(true)}
            noValidate
            onSubmit={handleSubmit}
          >
            <h2>Basic Information</h2>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="name">
                <Form.Label>Tariff name</Form.Label>
                <Form.Control
                  data-testid="tariff-name"
                  type="text"
                  placeholder="Enter tariff name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={touched.name && !!errors.name}
                  isValid={touched.name && !errors.name}
                />
                <Form.Control.Feedback type="invalid" data-testid="tariff-name-error">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  data-testid="tariff-description"
                  type="text"
                  placeholder="Enter tariff description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  isInvalid={touched.description && !!errors.description}
                  isValid={touched.description && !errors.description}
                />
                <Form.Control.Feedback type="invalid" data-testid="tariff-description-error">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  data-testid="state"
                  as="select"
                  name="state"
                  placeholder="Enter tariff state"
                  value={values.state || 'Choose a state'}
                  onChange={handleChange}
                  isInvalid={touched.state && !!errors.state}
                  isValid={touched.state && !errors.state}
                >
                  <option>Choose a state</option>
                  {STATES.map((state, stateIndex) => (
                    <option key={`state-${stateIndex}`}>{state}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid" data-testid="state-error">
                  {errors.state}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="4" controlId="distributor">
                <Form.Label>Distributor</Form.Label>
                <Form.Control
                  data-testid="distributor"
                  as="select"
                  name="distributor"
                  value={values.distributor}
                  onChange={handleChange}
                  isInvalid={touched.distributor && !!errors.distributor}
                  isValid={touched.distributor && !errors.distributor}
                >
                  <option value={''}>Choose a distributor</option>
                  {distributors.map(({ id, distributor_name }, distributorIndex) => (
                    <option value={id} key={distributorIndex}>
                      {distributor_name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid" data-testid="distributor-error">
                  {errors.distributor}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="retailer">
                <Form.Label>Retailer</Form.Label>
                <Form.Control
                  data-testid="retailer"
                  as="select"
                  name="retailer"
                  value={values.retailer || 'Choose a retailer'}
                  onChange={handleChange}
                  isInvalid={touched.retailer && !!errors.retailer}
                  isValid={touched.retailer && !errors.retailer}
                >
                  <option>Choose a retailer</option>
                  {retailers.map(({ retailer_name, retailer_id }, retailerIndex) => (
                    <option value={retailer_id} key={retailerIndex}>
                      {retailer_name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid" data-testid="retailer-error">
                  {errors.retailer}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="type">
                <Form.Label>Tariff Type</Form.Label>
                <Form.Control
                  data-testid="tariff-type"
                  as="select"
                  name="type"
                  value={values.type || 'Choose a tariff type'}
                  onChange={handleChange}
                  disabled={isEditing}
                  isInvalid={touched.type && !!errors.type}
                  isValid={touched.type && !errors.type}
                >
                  <option>Choose a tariff type</option>
                  {types.map((type, index) => (
                    <option key={`type-${index}`}>{type}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid" data-testid="tariff-type-error">
                  {errors.type}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" controlId="supplyCharge">
                <Form.Label>Supply charge (cents per day)</Form.Label>
                <Form.Control
                  data-testid="supply-charge"
                  type="number"
                  placeholder="Enter supply charge"
                  name="supplyCharge"
                  value={values.supplyCharge}
                  onChange={handleChange}
                  isInvalid={touched.supplyCharge && !!errors.supplyCharge}
                  isValid={touched.supplyCharge && !errors.supplyCharge}
                />
                <Form.Control.Feedback type="invalid" data-testid="supply-charge-error">
                  {errors.supplyCharge}
                </Form.Control.Feedback>
              </Form.Group>

              {values.type.toLowerCase() === 'tiered' && (
                <Form.Group as={Col} md="6" controlId="aggregationPeriod">
                  <Form.Label>Aggregation period</Form.Label>
                  <Form.Control
                    data-testid="tiered-tariff-aggregation-period"
                    as="select"
                    name="aggregationPeriod"
                    value={values.aggregationPeriod || 'Choose an aggregation period'}
                    onChange={(e) => {
                      onAggregationPeriodIdChange(e.currentTarget.value);
                      handleChange(e);
                    }}
                    isInvalid={touched.aggregationPeriod && !!errors.aggregationPeriod}
                    isValid={touched.aggregationPeriod && !errors.aggregationPeriod}
                  >
                    <option>Choose an aggregation period</option>
                    {AGGREGATION_PERIODS.map((period, index) => (
                      <option value={index + 1} key={`aggregation-period-${index}`}>
                        {period}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                </Form.Group>
              )}
            </Form.Row>

            <hr />

            <h2>Tariff values</h2>
            <div style={{ border: '1px solid black', borderRadius: '10px', margin: '1rem 0', padding: '1rem' }}>
              <h3>Does this tariff include controlled load?</h3>
              <Form.Row>
                <Form.Group as={Col} md={4} controlId="controlledLoad1">
                  <Form.Check
                    data-testid="includes-controlled-load1"
                    checked={values.controlledLoad1}
                    onChange={handleChange}
                    type="checkbox"
                    label="Tariff includes controlled load"
                  />
                </Form.Group>

                {values.controlledLoad1 && (
                  <>
                    <Form.Group as={Col} md="4" controlId="controlledLoad1Rate">
                      <Form.Label>Controlled load rate (cents per kWh)</Form.Label>
                      <Form.Control
                        data-testid="controlled-load1-rate"
                        type="number"
                        placeholder="Enter controlled load 1"
                        name="controlledLoad1Rate"
                        value={values.controlledLoad1Rate}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="controlledLoad1SupplyCharge">
                      <Form.Label>Controlled load supply charge (cents per day)</Form.Label>
                      <Form.Control
                        data-testid="controlled-load1-supply-charge"
                        type="number"
                        placeholder="Enter controlled load supply charge"
                        name="controlledLoad1SupplyCharge"
                        value={values.controlledLoad1SupplyCharge}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
              </Form.Row>
            </div>

            <div style={{ border: '1px solid black', borderRadius: '10px', margin: '1rem 0', padding: '1rem' }}>
              <h3>Does this tariff include controlled load 2?</h3>
              <Form.Row>
                <Form.Group as={Col} md={4} controlId="controlledLoad2">
                  <Form.Check
                    data-testid="includes-controlled-load2"
                    checked={values.controlledLoad2}
                    onChange={handleChange}
                    type="checkbox"
                    label="Tariff includes controlled load 2"
                  />
                </Form.Group>

                {values.controlledLoad2 && (
                  <>
                    <Form.Group as={Col} md="4" controlId="controlledLoad2Rate">
                      <Form.Label>Controlled load 2 rate (cents per kWh)</Form.Label>
                      <Form.Control
                        data-testid="controlled-load2-rate"
                        type="number"
                        placeholder="Enter controlled load 1"
                        name="controlledLoad2Rate"
                        value={values.controlledLoad2Rate}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="controlledLoad2SupplyCharge">
                      <Form.Label>Controlled load 2 supply charge (cents per day)</Form.Label>
                      <Form.Control
                        data-testid="controlled-load2-supply-charge"
                        type="number"
                        placeholder="Enter controlled load supply charge"
                        name="controlledLoad2SupplyCharge"
                        value={values.controlledLoad2SupplyCharge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Form.Row>
            </div>

            <hr />

            <DiscountsTable
              discountTypes={discountTypes}
              handleChange={handleChange}
              values={values}
              touched={touched}
              errors={errors}
            />

            <Button
              disabled={isLoading}
              style={{ height: '50px', width: '300px' }}
              size={'lg'}
              variant="success"
              type="submit"
              data-testid="submit-button"
            >
              {isEditing ? 'Save' : 'Create'} Tariff
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

const UNITS = ['%', '$AUD/day'];

function DiscountsTable({ handleChange, values, touched, errors, discountTypes }) {
  const touchedAtIndex = (index, type) => touched?.discountFormData?.[index]?.[type];
  const errorsAtIndex = (index, type) => errors?.discountFormData?.[index]?.[type];
  return (
    <div style={{ margin: '2rem 0' }}>
      <h2>Discounts</h2>
      <FieldArray
        name="discountFormData"
        render={(arrayHelpers) => (
          <>
            <div style={{ width: '100%' }}>
              {values.discountFormData.map((discountRow, index) => (
                <Form.Row key={index} data-testid={`discount-form-${index}`}>
                  <Form.Group as={Col} md="3" controlId="discountType">
                    <Form.Label>Discount type</Form.Label>
                    <Form.Control
                      data-testid={`discount-type-${index}`}
                      as="select"
                      name={`discountFormData.${index}.discountType`}
                      value={values.discountFormData[index].discountType || ''}
                      onChange={handleChange}
                      isInvalid={touchedAtIndex(index, 'discountType') && errorsAtIndex(index, 'discountType')}
                      isValid={touchedAtIndex(index, 'discountType') && !errorsAtIndex(index, 'discountType')}
                    >
                      <option value="">Choose a discount type</option>
                      {discountTypes.map(({ discount_type, discount_type_id }, discountTypeIndex) => (
                        <option value={discount_type_id} key={`discount-type-${discountTypeIndex}`}>
                          {discount_type}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errorsAtIndex(index, 'discountType')}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="unit">
                    <Form.Label>Unit</Form.Label>
                    <Form.Control
                      data-testid={`discount-unit-${index}`}
                      as="select"
                      name={`discountFormData.${index}.unit`}
                      value={values.discountFormData[index].unit || 'Choose a unit'}
                      onChange={handleChange}
                    >
                      <option>Choose a unit</option>
                      {UNITS.map((unit, unitIndex) => (
                        <option key={`unit-${unitIndex}`}>{unit}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="value">
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      data-testid={`discount-value-${index}`}
                      type="number"
                      placeholder="Value"
                      name={`discountFormData.${index}.value`}
                      value={values.discountFormData[index].value}
                      onChange={handleChange}
                      isInvalid={touchedAtIndex(index, 'value') && errorsAtIndex(index, 'value')}
                      isValid={touchedAtIndex(index, 'value') && !errorsAtIndex(index, 'value')}
                    />
                    <Form.Control.Feedback type="invalid">{errorsAtIndex(index, 'value')}</Form.Control.Feedback>
                  </Form.Group>

                  <Col md="3">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      <Button
                        data-testid={`remove-discount-${index}`}
                        size={'lg'}
                        variant="outline-danger"
                        type="button"
                        style={getButtonStyle()}
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  </Col>
                </Form.Row>
              ))}
            </div>

            <Button
              data-testid={'add-discount'}
              size={'lg'}
              variant="outline-success"
              type="button"
              style={getButtonStyle()}
              onClick={() => arrayHelpers.push(EMPTY_DISCOUNT_ROW)}
            >
              Add
            </Button>
          </>
        )}
      />
    </div>
  );
}

const getButtonStyle = () => ({
  margin: '0 1rem',
  height: '50px',
  width: '100px',
});

const STATES = ['NSW', 'QLD', 'ACT', 'SA', 'WA', 'TAS', 'VIC', 'NT'];
