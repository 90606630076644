import { Box } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/layout';
import { Spinner, Table } from 'react-bootstrap';
import React from 'react';
import { ChannelDataTuple } from '../../../api/api-ww-meter';
import config from '../../../components/chart-colors.config';
import { DataTypeChartSeriesConfig } from './chart-data-transformers';

type Props = {
  channels: ChannelDataTuple;
  voltageData: DataTypeChartSeriesConfig;
  currentData: DataTypeChartSeriesConfig;
  powerRealData: DataTypeChartSeriesConfig;
  powerReactiveData: DataTypeChartSeriesConfig;
  powerFactorData: DataTypeChartSeriesConfig;
  onToggleLine: (channelIndex: number) => void;
  isChartDataAvailable: boolean;
  hiddenIndexes: number[];
  latestPolledTimestamp: string;
};

export default function LatestDataTable({
  voltageData,
  currentData,
  powerRealData,
  powerReactiveData,
  powerFactorData,
  onToggleLine,
  isChartDataAvailable,
  hiddenIndexes,
  channels,
  latestPolledTimestamp,
}: Props) {
  return (
    <>
      {isChartDataAvailable ? (
        <Box style={{ width: '75%' }}>
          <Flex align="center" my={4}>
            <h3>Latest Data (polled at {latestPolledTimestamp})</h3>
            <h3 style={{ color: 'red', marginLeft: '1rem' }}>
              <Spinner variant="danger" animation="grow" /> Live
            </h3>
          </Flex>

          <div>
            <Table striped hover size="sm">
              <thead>
                <tr>
                  <td>Channel</td>
                  <td>Voltage (Volts)</td>
                  <td>Current (Amps)</td>
                  <td>Power (Real) (kW)</td>
                  <td>Power (Reactive) (kW)</td>
                  <td>Power Factor</td>
                </tr>
              </thead>
              <tbody>
                {/* Each channel (circuit) has its own row */}
                {channels.map((channel, index) => {
                  const latestVoltage = voltageData.data[index].data[voltageData.data[index].data.length - 1]?.y;
                  const latestCurrent = currentData.data[index].data[currentData.data[index].data.length - 1]?.y;
                  const latestPowerReal = powerRealData.data[index].data[powerRealData.data[index].data.length - 1]?.y;
                  const latestPowerReactive =
                    powerReactiveData.data[index].data[powerReactiveData.data[index].data.length - 1]?.y;
                  const latestPowerFactor =
                    powerFactorData.data[index].data[powerFactorData.data[index].data.length - 1]?.y;

                  return (
                    <tr
                      style={{ cursor: 'pointer', opacity: hiddenIndexes.includes(index) ? 0.3 : 1 }}
                      onClick={() => {
                        onToggleLine(index);
                      }}
                      key={`live-table-row-${index}`}
                    >
                      <td style={{ display: 'flex' }}>
                        <div
                          style={{
                            height: '20px',
                            width: '20px',
                            background: config[index],
                            borderRadius: '5px',
                            marginRight: '0.25rem',
                          }}
                        />{' '}
                        {channel.label} (CH {index + 1})
                      </td>
                      <td>{latestVoltage}</td>
                      <td>{latestCurrent}</td>
                      <td>{latestPowerReal}</td>
                      <td>{latestPowerReactive}</td>
                      <td>{latestPowerFactor ?? 'N/A'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Box>
      ) : (
        ''
      )}
    </>
  );
}
