export const SIZE_PER_PAGE = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 75, value: 75 },
  { label: 100, value: 100 },
];

export const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
