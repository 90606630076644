import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import Loader from 'react-loader';
import { Prompt } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { get, getTokenAndEmailFromSession, post, put } from '../../../common/api-utils';
import { MONTHS } from '../../../common/constants';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import NestedTOUTariffTable, { getAllSeasonsRates } from '../../../components/table/tariff/NestedTOUTariffTable';
import TEMPLATES from '../tariff-templates/tou-tariff-rate-templates.json';
import EditableTOURateAssignmentTable from './EditableTOURateAssignmentTable';

// Defined as a function to prevent duplicate object references to this array.
const getNull24HourArray = () => Array.from(Array(24)).map(() => null);

const schema = yup.object().shape({
  selectedSeasonIndex: yup.string().required(),
  startMonth: yup.string().required(),
  endMonth: yup.string().required(),
});

const EMPTY_SEASON_TEMPLATE = {
  start_month: null,
  end_month: null,
  rates: [],
  applied_rates: {
    MON: getNull24HourArray(),
    TUE: getNull24HourArray(),
    WED: getNull24HourArray(),
    THU: getNull24HourArray(),
    FRI: getNull24HourArray(),
    SAT: getNull24HourArray(),
    SUN: getNull24HourArray(),
  },
};

const INITIAL_STATE = {
  isLoaded: false,
  isSaved: true,
  seasons: [],
  selectedSeason: null,
  allRates: [],
  initialFormValues: {
    selectedSeasonIndex: null,
    rateTemplateIndex: null,
    startMonth: null,
    endMonth: null,
  },
};

export default function TOUTariffForm({ tariffId }) {
  const [state, setState] = useState(INITIAL_STATE);
  const [hasUnsavedRateChanges, setHasUnsavedRateChanges] = useState(false);
  const formRefValues = useRef(null);

  useEffect(() => {
    async function fetchAPI() {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      const seasons = await get('seasons', `/tariff/tariffs/${tariffId}/tou_rates`, token);

      let newState = {
        ...state,
        isLoaded: true,
      };

      if (seasons && seasons.length) {
        newState = {
          ...newState,
          seasons,
          selectedSeason: seasons[0],
          allRates: getAllSeasonsRates(seasons),
          initialFormValues: {
            ...state.initialFormValues,
            selectedSeasonIndex: 0,
            startMonth: seasons[0].start_month,
            endMonth: seasons[0].end_month,
          },
        };
      }

      setState(newState);
    }

    if (!state.isLoaded && tariffId) {
      fetchAPI();
    }

    // Add a close listener on mount
    const handleWindowUnload = (e) => {
      if (!state.isSaved) {
        e.preventDefault();
        const confirmationMessage = '';
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleWindowUnload);

    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [state.isSaved]);

  const initialRateData = {
    endMonth: state.initialFormValues.endMonth,
    rateTemplateIndex: state.initialFormValues.rateTemplateIndex,
    selectedSeasonIndex: state.initialFormValues.selectedSeasonIndex,
    startMonth: state.initialFormValues.startMonth,
  };

  useEffect(() => {
    function handleWindowUnload(e) {
      if (hasUnsavedRateChanges && !isEqual(initialRateData, formRefValues.current.values)) {
        e.preventDefault();
        e.returnValue = '';
        return;
      }

      delete e['returnValue'];
    }

    window.addEventListener('beforeunload', handleWindowUnload);

    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [hasUnsavedRateChanges]);

  async function handleSubmit() {
    const { jwtToken: token } = await getTokenAndEmailFromSession();

    if (!state.selectedSeason) {
      toast.error('🚫 There are no seasons to save. Click "Create new season" to add a new one for this tariff.');
      return;
    }

    if (!validateSeasonAppliedRates(state.seasons)) {
      // @TODO: improve visual validation (later) -- highlight table in red through state
      toast.error('🚫 All days and hourly periods in a season must have an associated rate!');
      return;
    }

    if (!validateAllSeasons(state.seasons)) {
      toast.error(
        '🚫 There are missing periods in the year for the provided seasons. Please make sure' +
          ' the entire year (months 1-12) are covered.'
      );
      return;
    }

    if (!validateOverlappingSeasons(state.seasons)) {
      toast.error(
        '🚫 There are overlapping periods for the provided seasons. Please make sure ' +
          'to not overlap periods between seasons.'
      );
      return;
    }

    try {
      await put('rates', `/tariff/tariffs/${tariffId}/tou_rates`, state.seasons, token);

      toast.success('👍 Successfully saved TOU rate and associated seasons.');

      setState({
        ...state,
        isSaved: true,
      });
      setHasUnsavedRateChanges(false);
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  function handleAddSeason() {
    const seasons = [...state.seasons, EMPTY_SEASON_TEMPLATE];

    setState({
      ...state,
      seasons: [...state.seasons, EMPTY_SEASON_TEMPLATE],
      selectedSeason: seasons[seasons.length - 1],
      initialFormValues: {
        selectedSeasonIndex: seasons.length - 1,
        startMonth: null,
        endMonth: null,
        rateTemplateIndex: null,
      },
      isSaved: false,
    });
  }

  function handleDeleteSeason(selectedSeasonIndex) {
    const isConfirmed = window.confirm('Are you sure you want to delete this season?');

    if (isConfirmed) {
      const seasons = state.seasons.filter((season, seasonIndex) => seasonIndex !== Number(selectedSeasonIndex));

      setState({
        ...state,
        seasons,
        isSaved: false,
        selectedSeason: seasons.length ? seasons[0] : null,
        initialFormValues: {
          selectedSeasonIndex: seasons.length ? 0 : null,
          startMonth: seasons.length ? seasons[0].start_month : null,
          endMonth: seasons.length ? seasons[0].end_month : null,
          rateTemplateIndex: null,
        },
      });
      toast.success('👍 Season successfully deleted.');
    }
  }

  function handleUpdateSeason(updatedSeason, values) {
    const { selectedSeasonIndex } = values;
    // Clone seasons in state so we don't mutate state directly
    const clonedSeasonArray = cloneDeep(state.seasons);
    clonedSeasonArray[selectedSeasonIndex] = updatedSeason;

    setState({
      ...state,
      isSaved: false,
      selectedSeason: updatedSeason,
      seasons: clonedSeasonArray,
    });
  }

  function handleChangeSeason(e) {
    const seasonIndex = Number(e.currentTarget.value);
    const selectedSeason = state.seasons.find((_, stateSeasonIndex) => stateSeasonIndex === seasonIndex);

    setState({
      ...state,
      selectedSeason,
      initialFormValues: {
        ...state.initialFormValues,
        selectedSeasonIndex: seasonIndex,
        startMonth: selectedSeason.start_month,
        endMonth: selectedSeason.end_month,
      },
    });
  }

  if (!state.isLoaded) {
    return <Loader loaded={false} />;
  }

  return (
    <>
      <Prompt
        when={hasUnsavedRateChanges}
        message="You have unsaved changes in the rate, are you sure you want to leave?"
      />
      <Formik
        innerRef={formRefValues}
        validationSchema={schema}
        validateOnChange
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={state.initialFormValues}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => {
          return (
            <Form noValidate onSubmit={handleSubmit} onChange={() => setHasUnsavedRateChanges(true)}>
              <div>
                {/* Rate tariff-templates */}
                <div style={{ padding: '1rem', marginBottom: '2rem', border: '1px solid grey', borderRadius: '10px' }}>
                  <h3>Tariff Rate Templates</h3>

                  <Alert variant="warning">
                    <div>
                      Use the below templates to automatically fill the TOU rates below. You can modify the rates after
                      loading a template as you please.
                    </div>
                  </Alert>
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="rateTemplateIndex">
                      <Form.Label>Rate Template</Form.Label>
                      <Form.Control
                        data-testid="tou-select-template"
                        as="select"
                        name="rateTemplateIndex"
                        value={values.rateTemplateIndex ?? 'Choose a template'}
                        onChange={async (e) => {
                          handleChange(e);

                          async function applyTemplate(templateIndex) {
                            // @TODO: show a loading screen while the async work is done
                            const rateTemplate = TEMPLATES[templateIndex];
                            const seasons = await buildTOURatesFromTemplate(rateTemplate);

                            if (seasons && Array.isArray(seasons)) {
                              setState({
                                ...state,
                                seasons,
                                selectedSeason: seasons[0],
                                allRates: getAllSeasonsRates(seasons),
                                initialFormValues: {
                                  ...state.initialFormValues,
                                  selectedSeasonIndex: 0,
                                  startMonth: seasons[0].start_month,
                                  endMonth: seasons[0].end_month,
                                },
                              });
                            }
                          }

                          if (!state.isSaved) {
                            // Inform the user of unsaved changes before applying a new template.
                            const result = window.confirm(
                              'You have unsaved changes to your current tariff rates. Are you sure you wish to discard them?'
                            );

                            if (result) {
                              await applyTemplate(e.currentTarget.value);
                            }
                          } else {
                            await applyTemplate(e.currentTarget.value);
                          }
                        }}
                      >
                        <option>Choose a template</option>
                        {TEMPLATES.map((template, templateIndex) => (
                          <option value={templateIndex} key={`template-value-${templateIndex}`}>
                            {template.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                </div>

                <h2>Select a season to start assigning rates</h2>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="selectedSeasonIndex">
                    <Form.Label>Season</Form.Label>
                    <Form.Control
                      data-testid="tou-select-season"
                      as="select"
                      name="selectedSeasonIndex"
                      value={values.selectedSeasonIndex}
                      isInvalid={touched.selectedSeasonIndex && !!errors.selectedSeasonIndex}
                      isValid={touched.selectedSeasonIndex && !errors.selectedSeasonIndex}
                      onChange={(e) => {
                        handleChangeSeason(e);
                        handleChange(e);
                      }}
                    >
                      <option value={null}>Choose a season</option>
                      {state.seasons.map((season, seasonIndex) => (
                        <option value={seasonIndex} key={`season-value-${seasonIndex}`}>
                          {`Season ${seasonIndex + 1}`}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="startMonth">
                    <Form.Label>Start month</Form.Label>
                    <Form.Control
                      data-testid="tou-select-start-month"
                      as="select"
                      name="startMonth"
                      isInvalid={touched.startMonth && !!errors.startMonth}
                      isValid={touched.startMonth && !errors.startMonth}
                      value={values.startMonth || 'Choose a start month'}
                      onChange={(e) => {
                        const { selectedSeason: season } = state;

                        if (season) {
                          const updatedSeason = {
                            ...season,
                            start_month: e.currentTarget.value,
                          };

                          const seasons = cloneDeep(state.seasons);
                          seasons[values.selectedSeasonIndex] = updatedSeason;

                          setState({
                            ...state,
                            seasons,
                            selectedSeason: updatedSeason,
                            isSaved: false,
                          });

                          handleChange(e);
                        }
                      }}
                    >
                      <option>Choose a start month</option>
                      {MONTHS.map((month, monthIndex) => (
                        <option value={monthIndex + 1} key={`month-value-${monthIndex}`}>
                          {`${month} (${monthIndex + 1})`}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="endMonth">
                    <Form.Label>End month</Form.Label>
                    <Form.Control
                      data-testid="tou-select-end-month"
                      as="select"
                      name="endMonth"
                      isInvalid={touched.endMonth && !!errors.endMonth}
                      isValid={touched.endMonth && !errors.endMonth}
                      value={values.endMonth || 'Choose an end month'}
                      onChange={(e) => {
                        const { selectedSeason: season } = state;

                        if (season) {
                          const updatedSeason = {
                            ...season,
                            end_month: e.currentTarget.value,
                          };

                          const seasons = cloneDeep(state.seasons);
                          seasons[values.selectedSeasonIndex] = updatedSeason;

                          setState({
                            ...state,
                            seasons,
                            selectedSeason: updatedSeason,
                            isSaved: false,
                          });

                          handleChange(e);
                        }
                      }}
                    >
                      <option>Choose an end month</option>
                      {MONTHS.map((month, monthIndex) => (
                        <option value={monthIndex + 1} key={`month-value-${monthIndex}`}>
                          {`${month} (${monthIndex + 1})`}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <div
                  style={{
                    marginBottom: '2rem',
                  }}
                >
                  <Button
                    onClick={handleAddSeason}
                    size={'lg'}
                    variant="secondary"
                    type="button"
                    style={{ height: '40px', width: '150px', margin: '0 0.5rem' }}
                  >
                    Create New Season
                  </Button>

                  <Button
                    onClick={() => handleDeleteSeason(values.selectedSeasonIndex)}
                    size={'lg'}
                    variant="danger"
                    type="button"
                    style={{ height: '40px', width: '150px', margin: '0 0.5rem' }}
                  >
                    Delete Season
                  </Button>
                </div>
              </div>

              <h2>Current Season Rates (click and drag to assign a rate value)</h2>
              <EditableTOURateAssignmentTable
                hasUnsavedRateChanges={hasUnsavedRateChanges}
                setHasUnsavedRateChanges={setHasUnsavedRateChanges}
                onUpdateSeason={(updatedSeason) => {
                  handleUpdateSeason(updatedSeason, values);
                }}
                onUpdateRates={(newRates) => {
                  setState({
                    ...state,
                    allRates: newRates,
                  });
                }}
                season={state.selectedSeason}
                allRates={state.allRates}
              />

              <Button
                size={'lg'}
                variant="primary"
                type="submit"
                style={{ height: '40px', width: '150px', margin: '2rem 0' }}
                data-testid="submit-tou-tariff-button"
              >
                Save
              </Button>
            </Form>
          );
        }}
      </Formik>

      <h2>View Existing Seasons</h2>
      <NestedTOUTariffTable selectedSeason={state.selectedSeason} seasons={state.seasons} />
    </>
  );
}

/**
 * Breaks down season to range such that it can be compared to other seasons.
 *
 * @param seasons season to get range from
 * @returns array of ranges for the season
 */
const getSeasonRanges = ({ start_month, end_month }) => {
  const startMonth = Number(start_month);
  const endMonth = Number(end_month);
  const ranges = [];
  if (startMonth > endMonth) {
    ranges.push([startMonth, 12], [1, endMonth]);
  } else {
    ranges.push([startMonth, endMonth]);
  }

  return ranges;
};

/**
 * Validates whether seasons overlap each other. If any season overlaps another, it's invalid.
 *
 * @param seasons The array of seasons to validate
 * @returns {boolean}
 */
function validateOverlappingSeasons(seasons) {
  // get ranges for all seasons
  const ranges = seasons.reduce((acc, season) => [...acc, ...getSeasonRanges(season)], []);

  // compare each range to all other ranges
  const hasOverlappingRanges = ranges.some((rangeA, indexA) => {
    const [startMonthA, endMonthA] = rangeA;

    // compare this range to all exisitng ranges
    return ranges.some((rangeB, indexB) => {
      // do not compare to self
      if (indexA === indexB) return false;

      const [startMonthB, endMonthB] = rangeB;

      // checks if two ranges overlap
      return Math.max(startMonthA, startMonthB) <= Math.min(endMonthA, endMonthB);
    });
  });

  // if any range overlaps, the seasons are invalid
  return !hasOverlappingRanges;
}

/**
 * Validates the applied tariff id arrays on a provided season, checking that none are `null`.
 *
 * @param seasons The array of seasons to validate
 * @returns {boolean}
 */
function validateSeasonAppliedRates(seasons) {
  return seasons.every((season) => {
    const appliedRates = season.applied_rates;

    // If any applied rate array entry is nullish, it's invalid.
    return Object.values(appliedRates).every((appliedRateArray) => !(appliedRateArray as any).includes(null));
  });
}

/**
 * Validates all seasons for this tariff's rates.
 * Checks that all months ranging 1-12 have been covered by a season.
 *
 * @example
 * Pseudocode:
 * VALID: [ S1: 3-4, S2: 5-2 ]
 * INVALID: [ S1: 3-4, S2: 8-2 ] (months 5-6 not covered)
 *
 * @param seasons
 * @returns {boolean}
 */
function validateAllSeasons(seasons) {
  let monthsNotCovered = Array.from(Array(12)).map((_, i) => i + 1);

  seasons.forEach((season) => {
    const { start_month, end_month } = season;
    const startMonth = Number(start_month);
    const endMonth = Number(end_month);

    // Use a predicate to filter out months which are covered by this range
    let comparatorFn = (monthNum) => monthNum < startMonth || monthNum > endMonth;

    if (endMonth < startMonth) {
      comparatorFn = (monthNum) => monthNum < startMonth && monthNum > endMonth;
    }

    // Filter array to remove items between these month values
    monthsNotCovered = monthsNotCovered.filter(comparatorFn);
  });

  // If we get here, and there's any items left in our original months not covered array, there's an error.
  return !monthsNotCovered.length;
}

/**
 * Uses the TOU tariff rate template syntax to construct new rates and applied rates on seasons. Contains several
 * function definitions, most of which are passed to array higher-order functions.
 *
 * The syntax of the rate template is as follows:
 * - Each template contains properties:
 *   - name: The name to display in the rate template drop down input
 *   - rateTemplates: An array of objects including import/export rates for each. These are saved to the back-end when
 *                    a rate template is selected.
 *   - seasons: An array of season objects, similar to the structure returned from the API. The main difference is that
 *              season templates' applied rates array uses index references to the respective `rateTemplates` objects,
 *              as there is no way to refer to them by ID until the rates are saved. Once saved, indexes are replaced by
 *              the ID of the object at that index.
 *
 * @TODO: create interface for rate template structure when TypeScript is introduced
 *
 * @param rateTemplate The rate template to be converted to a real rate/season object.
 * @returns {Promise<{seasons: *, rates: *}>}
 */
async function buildTOURatesFromTemplate(rateTemplate) {
  const { rateTemplates, seasons } = rateTemplate;

  const ratesToSave = rateTemplates.map((template) => {
    return {
      name: '',
      created_by: 'N/A',
      import_rate_cents_per_kwh: template.import_rate,
      export_rate_cents_per_kwh: template.export_rate,
    };
  });

  try {
    const { jwtToken } = await getTokenAndEmailFromSession();
    // Save rates to the API so we can access their IDs
    const savedRates = await post('rates', `/tariff/rates`, ratesToSave, jwtToken);

    const savedRatesInCorrectStructure = savedRates.map(
      ({ id, import_rate_cents_per_kwh, export_rate_cents_per_kwh }) => ({
        id,
        import_rate: import_rate_cents_per_kwh,
        export_rate: export_rate_cents_per_kwh,
      })
    );

    // Replaces indexes in the rate template with IDs for those rates.
    function replaceIndexesWithIDs(rateValue) {
      const existsAsIndexRef = savedRates[rateValue];

      if (existsAsIndexRef) {
        return existsAsIndexRef.id;
      }

      return rateValue;
    }

    // Passed to the mapped applied rate entries. Returns a tuple where element [1] is the correctly formatted applied rates.
    function convertAppliedRateIndexesToIDs([dayOfWeek, rateReferenceArray]) {
      return [dayOfWeek, rateReferenceArray.map(replaceIndexesWithIDs)];
    }

    // Highest level function to map each season's applied rates to their correct values.
    function mapSeasonRatesToIDs(season) {
      const replacedAppliedRates = Object.entries(season.applied_rates).map(convertAppliedRateIndexesToIDs);
      return {
        ...season,
        rates: savedRatesInCorrectStructure,
        applied_rates: Object.fromEntries(replacedAppliedRates),
      };
    }

    // For each `applied_rates` array element, find and replace its old value (index position) with the proper value (id)
    return seasons.map(mapSeasonRatesToIDs);
  } catch (e) {
    displayAPIErrorMessage(e);
  }
}
