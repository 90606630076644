import { Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import Loader from 'react-loader';
import { Prompt } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { get, getTokenAndEmailFromSession, put } from '../../../common/api-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';

const schema = yup.object().shape({
  import: yup.number().required(),
  export: yup.number().required(),
});

const INITIAL_STATE = {
  isLoaded: false,
  initialFormValues: {
    import: '',
    export: '',
  },
};

export default function FlatTariffForm({ tariffId }) {
  const [state, setState] = useState(INITIAL_STATE);
  const [hasUnsavedRateChanges, setHasUnsavedRateChanges] = useState(false);
  const formRefValues = useRef(null);

  useEffect(() => {
    async function fetchAPI() {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      const rates = await get('rates', `/tariff/tariffs/${tariffId}/flat_rates`, token);

      setState({
        ...state,
        isLoaded: true,
        initialFormValues: {
          import: rates?.[0]?.import_rate ?? '',
          export: rates?.[0]?.export_rate ?? '',
        },
      });
    }

    if (!state.isLoaded && tariffId) {
      fetchAPI();
    }
  }, []);

  const initialRateData = {
    export: state.initialFormValues.export,
    import: state.initialFormValues.import,
  };

  useEffect(() => {
    function handleWindowUnload(e) {
      if (hasUnsavedRateChanges && !isEqual(initialRateData, formRefValues.current.values)) {
        e.preventDefault();
        e.returnValue = '';
        return;
      }

      delete e['returnValue'];
    }

    window.addEventListener('beforeunload', handleWindowUnload);

    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [hasUnsavedRateChanges]);

  async function handleSubmit(values) {
    try {
      const { jwtToken: token } = await getTokenAndEmailFromSession();
      const body = [
        {
          export_rate: values.export,
          import_rate: values.import,
          start_month: 1,
          end_month: 12,
        },
      ];

      await put('rates', `/tariff/tariffs/${tariffId}/flat_rates`, body, token);
      setHasUnsavedRateChanges(false);
      toast.success('👍 Successfully submitted Flat Rate configuration.');
    } catch (e) {
      displayAPIErrorMessage(e);
    }
  }

  if (!state.isLoaded) {
    return <Loader loaded={state.isLoaded} />;
  }
  return (
    <>
      <h2>Flat Rate Configuration</h2>
      <FlatRateForm
        rateFormRefValues={formRefValues}
        onSubmit={handleSubmit}
        hasUnsavedRateChanges={hasUnsavedRateChanges}
        setHasUnsavedRateChanges={setHasUnsavedRateChanges}
        initialFormValues={state.initialFormValues}
      />
    </>
  );
}

export function FlatRateForm({
  onSubmit,
  initialFormValues,
  hasUnsavedRateChanges,
  setHasUnsavedRateChanges,
  rateFormRefValues = null,
}) {
  return (
    <>
      <Prompt
        when={hasUnsavedRateChanges}
        message="You have unsaved changes in the rate, are you sure you want to leave?"
      />
      <Formik
        innerRef={rateFormRefValues}
        validateOnChange
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialFormValues}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => {
          return (
            <Form noValidate onSubmit={handleSubmit} onChange={() => setHasUnsavedRateChanges(true)}>
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="import">
                  <Form.Label>Import rate (cents per kWh)</Form.Label>
                  <Form.Control
                    data-testid="flat-import-rate"
                    type="number"
                    placeholder="Enter import rate"
                    name="import"
                    value={values.import}
                    onChange={handleChange}
                    isInvalid={touched.import && !!errors.import}
                    isValid={touched.import && !errors.import}
                  />
                  <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="export">
                  <Form.Label>Export rate (cents per kWh)</Form.Label>
                  <Form.Control
                    data-testid="flat-export-rate"
                    type="number"
                    placeholder="Enter export rate (cents per kWh)"
                    name="export"
                    value={values.export}
                    onChange={handleChange}
                    isInvalid={touched.export && !!errors.export}
                    isValid={touched.export && !errors.export}
                  />
                  <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Button
                style={{ margin: '2rem 0', height: '50px', width: '300px' }}
                size={'lg'}
                variant="success"
                type="submit"
                data-testid="submit-tariff-button"
              >
                Save Rate
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
