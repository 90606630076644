import { TIMEZONES } from 'clipsal-cortex-utils/src/constants/timezone-states';

const ALLOWED_TZS = [
  'Australia/Sydney',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Melbourne',
  'Australia/Brisbane',
  'Australia/Perth',
  'America/Los_Angeles',
];

export const FORMATTED_TIMEZONES = TIMEZONES.filter((timezone) => ALLOWED_TZS.includes(timezone));

export const FORMATTED_TIMEZONES_FOR_SELECT = FORMATTED_TIMEZONES.map((timezone) => ({
  value: timezone,
  label: timezone,
}));

export const ALLOWED_COUNTRIES = ['Australia', 'United States'];
