import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/accordion';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { InstalledDevice } from '../../../api/api-device';
import { del, getTokenAndEmailFromSession } from '../../../common/api-utils';
import { displayAPIErrorMessage } from '../../../common/utils-helper';
import ModelManufacturerSiteIdentifierFields from './ModelManufacturerSiteIdentifierFields';
import { EvChargerFormData, CommonFieldListProps } from './site-devices-types';

export const EMPTY_EV_CHARGER_TEMPLATE: EvChargerFormData = {
  evChargerId: null,
  manufacturer: '',
  model: '',
  siteIdentifier: '',
  serialNumber: '',
};

type EvChargerFormProps = CommonFieldListProps;

const EvChargerForm = (props: EvChargerFormProps) => {
  const { control, siteId, errors, register } = props;
  const { fields, append, remove } = useFieldArray({
    name: `evChargers`,
    control,
  });
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  useEffect(() => {
    setExpandedIndex(fields.length - 1);
  }, [fields.length]);

  async function handleDeleteEvCharger(evChargerId: number, index: number) {
    if (window.confirm('Are you sure you want to delete this ev charger?')) {
      if (evChargerId) {
        try {
          const { jwtToken } = await getTokenAndEmailFromSession();
          await del<InstalledDevice>('site-devices', `/site/sites/${siteId}/devices/${evChargerId}`, jwtToken);
        } catch (e) {
          displayAPIErrorMessage(e);
        }
      }
      remove(index);
    }
  }

  return (
    <Box>
      <Accordion
        defaultIndex={0}
        allowToggle
        index={expandedIndex}
        onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}
      >
        {fields.map((field, index) => {
          const errorsAtIndex = errors?.evChargers?.[index];
          return (
            <AccordionItem key={`${field.id}-${index}`} index={index}>
              <AccordionButton role={'button'} as={Box} py={4}>
                <Flex w={'100%'} justify={'space-between'} align={'center'}>
                  <Heading>EvCharger {index + 1}</Heading>
                  <Flex align={'center'}>
                    <Button
                      style={{ fontSize: '12px' }}
                      type="button"
                      variant={'ghost'}
                      onClick={() => handleDeleteEvCharger(field.evChargerId, index)}
                      size={'xs'}
                      mr={2}
                      colorScheme="red"
                      aria-label="Delete EV Charger"
                    >
                      Remove
                    </Button>
                    <AccordionIcon w={8} h={8} mr={3} />
                  </Flex>
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <ModelManufacturerSiteIdentifierFields
                  {...{ ...props, index, field, deviceType: 'EV_CHARGER', fieldKey: 'evChargers' }}
                />
                <FormControl isInvalid={!!errorsAtIndex?.serialNumber} mt={3}>
                  <FormLabel>Serial Number</FormLabel>
                  <Input
                    {...register(`evChargers.${index}.serialNumber` as const)}
                    py={'20px'}
                    placeholder="Enter Serial Number"
                  />
                  <FormErrorMessage>{errorsAtIndex?.serialNumber?.message}</FormErrorMessage>
                </FormControl>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      {!fields.length && (
        <Alert status="info" my={4} rounded={4}>
          <AlertIcon w={8} h={8} />
          <AlertDescription py={4}>No EV Charger! Please click &quot;Add EV Charger&quot; to add one.</AlertDescription>
        </Alert>
      )}
      <Button py={8} my={7} onClick={() => append(EMPTY_EV_CHARGER_TEMPLATE)} type="button">
        Add EV Charger
      </Button>
    </Box>
  );
};

export default EvChargerForm;
