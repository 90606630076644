import React, { useEffect, useState } from 'react';
import { Alert, Button, Collapse, Tab, Tabs } from 'react-bootstrap';
import Loader from 'react-loader';
import { toast } from 'react-toastify';

import { get, getTokenAndEmailFromSession, post } from '../../../common/api-utils';
import { calculateDifferenceBetweenDates, formatDate } from '../../../common/utils-helper';
import BarChart from '../../../components/BarChart';
import Sidebar from '../../../components/Sidebar';
import config from '../../../components/table/tariff/tou-rate-colours.config';
import Header from '../../../components/TopNav';
import DataCorrectionForm, { INITIAL_FORM_VALUES } from './DataCorrectionForm';

const INITIAL_STATE = {
  selectedTabIndex: 'energyUsage',
  isAlertVisible: true,
  siteSelectValues: [],
  isLoaded: false,
  formValues: INITIAL_FORM_VALUES,
  chartData: [],
  barConfig: [],
  xAxisKey: 'reading_datetime',
  isChartLoaded: false,
  isFetching: false,
};

const TAB_CONFIG = [
  {
    eventKey: 'energyUsage',
    tabTitle: 'Energy Usage',
    title: 'Energy Usage Data Correction',
  },
  {
    eventKey: 'costs',
    tabTitle: 'Costs',
    title: 'Costs Data Correction',
  },
  {
    eventKey: 'savings',
    tabTitle: 'Savings',
    title: 'Savings Data Correction',
  },
  {
    eventKey: 'batteryData',
    tabTitle: 'Battery',
    title: 'Battery Data Correction',
  },
];

function getInitialState() {
  const hasViewedAlert = localStorage.getItem('hasClosedDataCorrectionAlert');

  if (hasViewedAlert) {
    return { ...INITIAL_STATE, isAlertVisible: false };
  }

  return INITIAL_STATE;
}

export default function DataCorrection() {
  const [state, setState] = useState(getInitialState());

  useEffect(() => {
    async function fetchAPI() {
      const { jwtToken: token } = await getTokenAndEmailFromSession();

      const { data } = await get('sites', `/site/sites?limit=50&offset=0`, token);

      setState({
        ...state,
        isLoaded: true,
        siteSelectValues: data.map((site) => ({
          label: `${site.site_name} (ID: ${site.clipsal_solar_id})`,
          value: site.clipsal_solar_id,
        })),
      });
    }

    if (!state.isLoaded) {
      fetchAPI();
    }
  }, []);

  useEffect(() => {
    if (
      !!state.formValues.startDate &&
      !!state.formValues.endDate &&
      state.formValues.startDate <= state.formValues.endDate &&
      !!state.formValues.site?.value
    ) {
      handleRefresh(state.formValues.site.value, state.formValues.startDate, state.formValues.endDate);
    }
  }, [state.formValues.startDate, state.formValues.endDate, state.formValues.site?.value, state.selectedTabIndex]);

  async function handleSubmit({ site, startDate, endDate }) {
    const type = state.selectedTabIndex;

    if (!site) {
      toast.error('Please select a site.');
      return;
    }

    if (startDate > endDate) {
      toast.error('End date must not be before start date.');
      return;
    }

    const { jwtToken: token } = await getTokenAndEmailFromSession();

    const returnDataTimeInterval = getTimeIntervalTypeFromDateRange(startDate, endDate);

    await post(
      'data_correction',
      `/site/sites/${site.value}/data_correction` +
        `?type=${type}&start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`,
      { start_date: formatDate(startDate), end_date: formatDate(endDate) },
      token
    );

    toast.success('Successfully submitted data correction!');

    await getChartData(site.value, type, startDate, endDate, token, returnDataTimeInterval);
  }

  async function handleBackfillHistory(siteId) {
    const type = state.selectedTabIndex;

    if (!siteId) {
      toast.error('Please select a site.');
      return;
    }

    const { jwtToken: token } = await getTokenAndEmailFromSession();
    await post('data_correction', `/site/sites/${siteId}/backfill_history?type=${type}`, {}, token);

    toast.success(
      `Successfully submitted historical site backfill for ${type}. Please wait some time and refresh the chart.`
    );
  }

  async function getChartData(siteId, type, startDate, endDate, token, interval) {
    const typeToEndPoint = {
      energyUsage: 'usage',
      costs: 'costs',
      savings: 'savings',
      batteryData: 'battery_data',
    };

    // Usage contains time intervals, the others don't.
    const xAxisKey = type === 'energyUsage' ? 'reading_datetime' : 'reading_date';

    const endPoint = typeToEndPoint[type];

    const chartData = await get(
      endPoint,
      `/site/sites/${siteId}/${endPoint}` +
        `?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}&interval=${interval}`,
      token
    );

    if (chartData.length) {
      const firstEntry = { ...chartData[0] };

      // Iterate keys, use as stacks
      const barConfig = Object.keys(firstEntry)
        .filter((key) => !key.includes('date'))
        .map((key, i) => {
          return {
            key,
            title: key,
            color: config[i].color,
          };
        });

      setState({
        ...state,
        chartData,
        barConfig,
        xAxisKey,
        isChartLoaded: true,
      });
    } else {
      console.warn('No data for this site yet.');

      setState({
        ...state,
        chartData: [],
        isChartLoaded: true,
      });
    }
  }

  function handleFormValueChange(formValues) {
    setState({
      ...state,
      formValues,
    });
  }

  async function handleRefresh(site, startDate, endDate) {
    const { jwtToken: token } = await getTokenAndEmailFromSession();
    const returnDataTimeInterval = getTimeIntervalTypeFromDateRange(startDate, endDate);

    setState({
      ...state,
      isChartLoaded: false,
    });

    await getChartData(site, state.selectedTabIndex, startDate, endDate, token, returnDataTimeInterval);

    toast.success('Successfully refreshed');
  }

  async function handleSearchSites(searchTerm) {
    if (searchTerm) {
      const { jwtToken } = await getTokenAndEmailFromSession();
      const { data } = await get('sites', `/site/sites?search_term=${searchTerm}`, jwtToken);
      const result = data.map((site) => ({
        label: `${site.site_name} (ID: ${site.clipsal_solar_id})`,
        value: site.clipsal_solar_id,
      }));

      // Remove any options already in our initial values
      const newItems = result.filter(
        (item) => !state.siteSelectValues.find((stateItem) => item.value === stateItem.value)
      );

      setState({
        ...state,
        siteSelectValues: [...state.siteSelectValues, ...newItems],
      });

      return new Promise((resolve) => {
        resolve(result);
      });
    }
  }

  const commonFormProps = {
    onSubmit: handleSubmit,
    onSearchSites: handleSearchSites,
    siteOptions: state.siteSelectValues,
    onFormValueChange: handleFormValueChange,
    formValues: state.formValues,
    onBackfillEntireHistory: handleBackfillHistory,
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div role="main" className="content-panel">
            <div className="content-section">
              <div className="content-head">
                <div className="border-bottom-gray d-flex">
                  <span className="header-span">Data Correction</span>
                </div>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                  <ol className="breadcrumb ">
                    <li className="breadcrumb-item">
                      <a href="#/sites">Clipsal</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Data Correction
                    </li>
                  </ol>
                </nav>
              </div>

              {state.isAlertVisible && (
                <Alert
                  variant="success"
                  onClose={() => {
                    localStorage.setItem('hasClosedDataCorrectionAlert', 'true');
                    setState({ ...state, isAlertVisible: false });
                  }}
                  dismissible
                >
                  <Alert.Heading>
                    <h3>Welcome to the new data correction page</h3>
                  </Alert.Heading>
                  <p>
                    We&apos;ve simplified this process to improve the experience when restoring data from missing
                    periods.
                  </p>
                  <p>To get started, pick a correction type from the tabs below, choose a site, date period, and go.</p>
                  <p>
                    Once submitted, you can click the &apos;Refresh&apos; button to update the chart data being
                    displayed.
                  </p>
                </Alert>
              )}

              {state.isLoaded ? (
                <>
                  <Tabs
                    id="tabs"
                    activeKey={state.selectedTabIndex}
                    onSelect={async (newIndex) => {
                      setState({
                        ...state,
                        selectedTabIndex: newIndex,
                      });
                    }}
                  >
                    {TAB_CONFIG.map(({ eventKey, tabTitle, title }) => {
                      return (
                        <Tab key={eventKey} eventKey={eventKey} title={tabTitle} data-testid={`${eventKey}-tab-btn`}>
                          <h2>{title}</h2>
                          <DataCorrectionForm
                            {...commonFormProps}
                            eventKey={eventKey}
                            onBackfillEntireHistory={async (siteId) => {
                              await handleBackfillHistory(siteId);
                            }}
                          />
                        </Tab>
                      );
                    })}
                  </Tabs>

                  <div style={{ margin: '2rem 0' }}>
                    <Collapse in={!!state.formValues?.startDate && !!state.formValues?.endDate}>
                      <div>
                        {state.isChartLoaded ? (
                          <AlertWrappedBarChart
                            data={state.chartData}
                            xAxisKey={state.xAxisKey}
                            barConfig={state.barConfig}
                          />
                        ) : (
                          <Loader loaded={false} />
                        )}

                        <Button
                          data-testid="refresh-btn"
                          style={{ margin: '1rem 0', height: '50px', width: '100px' }}
                          size={'lg'}
                          variant="warning"
                          type="button"
                          onClick={() =>
                            handleRefresh(
                              state.formValues.site.value,
                              state.formValues.startDate,
                              state.formValues.endDate
                            )
                          }
                        >
                          Refresh
                        </Button>
                      </div>
                    </Collapse>
                  </div>
                </>
              ) : (
                <Loader loaded={false} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AlertWrappedBarChart(props) {
  if (!props.data.length) {
    return (
      <Alert variant="danger">
        <Alert.Heading>
          <h3>There is no data for this site during this time period.</h3>
        </Alert.Heading>
      </Alert>
    );
  }

  return <BarChart {...props} />;
}

export function getTimeIntervalTypeFromDateRange(startDate, endDate) {
  const numDaysBetweenDates = calculateDifferenceBetweenDates(startDate, endDate);

  // Anything greater than a breakpoint day number will use the associated interval type
  const dayPeriodToIntervalType = {
    0: 'default',
    1: 'day',
    120: 'month',
  };

  const breakpointEntries = Object.entries(dayPeriodToIntervalType);

  const [, type] = breakpointEntries.find(([days], breakpointIndex) => {
    // If we're at the last breakpoint, it's always that value.
    if (breakpointIndex === breakpointEntries.length - 1) {
      return days;
    }

    const [nextDays] = breakpointEntries[breakpointIndex + 1];

    return numDaysBetweenDates < Number(nextDays) && numDaysBetweenDates >= Number(days);
  });

  return type;
}
